

.box1{
   
    background-color: rgb(248, 248, 248);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid rgb(221, 221, 221);
   
   }
   .logoo{
       text-align: center;
       padding-top: 50px;
   }
   
    .p2{
    font-size: 16px;
    }   
   
   .p1{
       text-align: center;
   }
   .lokacioni{
       display: flex;
       margin-top: 50px;
       padding-left: 20px;
       align-items: center;
       border-bottom: 2px solid rgb(213 213 213);
       padding-bottom: 15px;
    
   }
   .numri{
       display: flex;
       margin-top: 20px;
       padding-left: 20px;
       align-items: center;
       border-bottom: 2px solid rgb(213 213 213);
       padding-bottom: 15px;
       
   }
   .email{
       display: flex;
       padding-left: 20px;
       margin-top: 10px;
       align-items: center;
       border-bottom: 2px solid rgb(213 213 213);
       padding-bottom: 15px;
       
     
   }
   .glob{
       display: flex;
       margin-top: 20px;
       padding-left: 20px;
       align-items: center;
       border-bottom: 2px solid rgb(213 213 213);
       padding-bottom: 15px;
       
       
      
   }
   h2{
       text-align: center;
       font-size: 30px;
     
   
   }
   .paragrafi{
       text-align: center;
       margin-top: -25px;
       
   }
   .img2{
       margin-right: 10px;
      
   }
   .img3{
       margin-right: 10px;
      
   }
   .img4{
       margin-right: 10px;
       
   }
   .img5{
       margin-right: 10px;
     
   }
   .rrjetet{
       display: flex;
       justify-content:space-evenly;
       margin-top: 22px;
       
   }
   .footer{
       padding-left: 10px;
       padding-right: 10px;
   }
   .i{
       font-size: 12px!important;
       color: rgb(184, 184, 184);
       text-align: center;
       margin-top: 30px;
   }
   .i a{
       color:  rgb(79 194 112);
       text-decoration: none;
   }
   .butooni{
       border-radius: 100%;
       background: transparent;
     width: 50px;
     height: 50px;
     border: 2px solid  rgb(79 194 112) ;
       float: right;
       margin-top: -153px;
       margin-right: 20px;
   }

   .footer div{
    margin: 30px 0;
   }
   .arbes{
    padding: 20px;
    
   }