@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&family=Ibarra+Real+Nova:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Maven+Pro:400,700);
.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  text-align: center;
  font-weight: 500;
}
body{
  overflow-x: hidden;
  margin: 0 !important;
}
.home{
  background: #52687b;
  height: 100vh;
}
.we{
  display: none;
}
.under{
height: 100vh;
width: 100%;
}
/* --------------------------------BIOHIT */

.data10 {
display: flex;
justify-content: space-around;
align-items: center;
padding: 10px;
text-align: center;

}
.data {
    width: 500px;
    margin-top: 10px;
    box-shadow: 1px 0px 15px 5px rgba(207, 207, 207, 0.5);
    -webkit-box-shadow: 1px 0px 15px 5px rgba(207, 207, 207, 0.5);
    -moz-box-shadow: 1px 0px 15px 5px rgba(207, 207, 207, 0.5);
    border-radius: 9px; 
}
    .main{
        width: 500px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        -webkit-clip-path: polygon(0 1%, 100% 0, 100% 67%, 0 99%);
                clip-path: polygon(0 1%, 100% 0, 100% 67%, 0 99%);
        /* background-color: #384992; */
        background: url('https://scontent.fprn1-1.fna.fbcdn.net/v/t39.30808-6/270748286_872934536814820_6554952458538394587_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=e3f864&_nc_ohc=RSt1tbowjvEAX9Wu30E&_nc_ht=scontent.fprn1-1.fna&oh=00_AT-4F8f0IkogjOLFt6jN2FN751MkfyWP6TElcxCprbiJtQ&oe=62C0C784');
        color: white;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
       h1{
        margin-left: 20px;
        margin-bottom: 40px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
       }
       .bioteks{
           
           margin-left: 20px;
           font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
           font-weight: 500;
       }

    .picture{
        display: flex;
        justify-content: flex-end;
        background: white;
    }
        .bio{
            width: 200px;
            height: 200px;
            border-radius: 100%;
            margin-top: -160px;
            position: absolute;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
        }

    .content{
        width: 420px;
        padding: 15px;
        margin-left: 23px;
        text-align: center;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
    }
       
   .info{ 
       margin-top: -30px;
       margin-left: 30px;
       font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
       font-weight: 500;
   }
    .box-1{
        display: flex;
        align-items: center;
        margin-top: 14px;
        width: 450px;
        padding: 5px;
        height: 20px;
        border-radius: 10px;
    }
        
        button{
            border: none;
            background: none;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
        }
 .bioteks{
   color: rgb(56 73 146);
   font-size: 15px!important;
   margin-left: 5px!important;
   margin-top: 15px!important;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-weight: 500;
 }
 .bioteks a{
  color: rgb(56 73 146);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.nou{
  color: rgb(0 187 222);
  border: none!important;
  font-size: 22px!important;
}
.no{
  color: rgb(56 73 146);
  display: flex;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  margin-left: -10px;
  cursor: pointer;
}
 .box-1{
   box-shadow: none!important;
 }
@media only screen and (max-width: 500px) { 
.we{
  display: block;
  text-align: center;
  font-size: 30px;
  font-family: monospace;
  margin: 0!important;
  padding-top: 50px;
  color: white;
}
.under{
  height: auto!important;
  margin-top: 100px;
}
.data10{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-top: 10px;
    width: 360px;
    margin-left: 25px;
  }
    .data{
       
        width: 360px;
        padding: 0; 
        margin-top: 0;
    }
        .main{
            width: 360px;
            border-radius: 0;
            padding: 0;
        }
        .content{
            width: 330px;
            margin-left: 0;
            padding: 10px;
            margin-top: -20px;
        }
        .picture .bio{
            position: relative;
            top: -5px;
            background: white;
        }
        
        .info{
            margin-left: 10px;
        }
            .box{
                width: 350px;
                margin-top: 40px;
            }
        }
        .save{
          display: flex!important;
        }
        a{
          text-decoration: none;
        }

/* ------------------------------------------------ */
/* --------------------------------DENISGAFURI---------- */
.card {
margin: 10px;
height: auto;
width: 370px;
background-color: white;
box-shadow: 0px 0px 20px 0px rgba(170, 170, 170, 0.75);
}

.card ul {
display: flex;
justify-content: space-around;
align-items: center;
margin-top: 0;
margin-left: -40px;
}

.card ul li {
display: block;
text-align: center;
list-style-type: none;
}

.card ul li i {
color: white;
font-size: 25px;
}

.card ul li a {
font-family: 'Poppins', sans-serif;
color: white;
text-decoration: none;
font-weight: 600;
}

.card .img-div {
display: flex;
justify-content: center;
align-items: center;
margin-top: 40px;
}


.bg-two {
/* background-image: url("./images/dive.png");
background-size: 520px;
background-position: top; */
-webkit-clip-path: polygon(0 0, 0 70%, 100% 50%, 100% 0);
        clip-path: polygon(0 0, 0 70%, 100% 50%, 100% 0);
width: 100%;
height: 380px;
background-color: black;
text-align: center;
}

.text {
text-align: right;
font-family: 'Poppins', sans-serif;
color: #151b3d;
padding: 0;
margin-top: -150px;
margin-bottom: 20px;
margin-right: 20px;
background: transparent;
}

.text h1 {
margin: 0px 0;
}

.loc{
font-weight: 500;
font-family: 'Poppins', sans-serif;
color: black;
font-size: 16px!important;
}
.text .title {
font-size: 40px;
color: #000;
font-weight: 500;
margin-top: 15px;
}

.text .ceo {
font-size: 20px;
color: #787575;
}

.text .slogan {
font-size: 14px;
font-style: italic;
font-family: 'Playfair Display', serif;
color: #202a5a;
font-weight: 700!important;
}

.personal {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
height: auto;
background-color: #ffffff;
}
.p{
margin-bottom: 0!important;
font-size: 17px!important;
}
.personal a {
font-family: 'Poppins', sans-serif;
color: #000;
text-decoration: none;
font-weight: 500;
text-align: left;
margin: 10px 0;
}

.personal .info {
text-align: left;
width: 100%;
}

.personal .info i {
background-color: #d6c05d;
padding: 25px;
color: black;
font-size: 25px!important;
margin: 5px 0;
}

.personal .info a {
padding: 0 10px;
font-size: 15px;
}

.save-btn {
background-color: #000;
color: #d6c05d;
padding: 20px;
border: 0;
font-size: 20px;
width: 100%;
margin-top: 20px;
cursor: pointer;
}
.des{
margin-left: 30px!important;
height: 120px;
}
.desi{
height: 90px!important;
display: flex;
align-items: center;
}
.logoname{
border-radius: 0!important;
width: 180px;
margin-top: -15px;
margin-left: -15px;
}
.slogi{
font-family:'Poppins', sans-serif;
font-style: italic;
color: #202a5a;
margin-left: -10px;
font-weight: 700;
}
.txt-center {
display: flex;
justify-content: center;
align-items: center;
}

.ml {
margin: 0 10px;
}
body{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  font-family: Poppins!important;
}

.card-t img{
  width: 100px;
  border-radius: 100%;
  height: 90px;
  margin-top:-50px;
  margin-bottom: 0;
}
.card-t{
  width: 310px;
  height: 780px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgba(170, 170, 170, 0.75);
  padding: 0px 20px;
  margin-top: -25px;
}

.header-t{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(117, 117, 117);
  color: white;
  width: 90%;
  height: 270px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
font-family: 'Poppins', sans-serif;
position: relative;
background-color: #a9caff;
}

.ceo-t{
  margin: 0;
  font-weight: 300;
}

.title-t{
  margin: 0;
}

.shape-t{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 7px;
  background: rgb(80, 80, 80);
  left: 7px;
  right: 7px;
  bottom: 7px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
}

.text-t{
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 30px;
  border-left: 5px solid #008cc9;
}

.span-t{
  font-weight: 600;
}

.work-t{
  margin-top: 0px;
  margin-bottom: 3px;
  font-size: 23px;
}
.slogan-t{
  margin: 0;
  font-size: 19px;
}

.div-t{
  margin-top: 20px;
  width: 200px;
  margin-left:-40px;
}

.info-t a{
  margin-left: 20px;
  text-decoration: none;
  color: #3b3b3b;
  font-size: 19px;
}
.info-t{
  display: flex;
  align-items: baseline;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.info-t i{
  color: #008cc9;
  font-size: 16px;
}


.shape-two-t{
  width: 70%;
  height: 300px;
  background-color: #008cc9;
}

.shape-three-t{
  width: 100%;
  height: auto;
  background: red;
}

.save-t{
  margin-top: 30px;
  font-size:18px;
  color: #ffffff;
  background-color:#008cc9;
  padding: 10px;
  width: 100%;
  border: 0;
  cursor: pointer;
  margin-bottom: 0;
}
.ml-t{
  margin-left: 10px;
}

.links{
  width: 100%;
  font-size: 25px;
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  align-items: center;
  margin-top: 30px;
}

.links i{
  padding: 10px;
  border-radius: 100%;
  background-color: #008cc9;
}
/* -------------------------------------------------------------------- */

.dollogo{
margin-top: 200px;
}

@media only screen and (max-width: 500px) { 

.dollogo{
  margin-top: 200px;
}
.dollogo img{
  width: 300px;
  margin-right: 30px;
}
}

.dive{
margin-top: -45px;
width: 200px;
}
.useri-halo{
  background-color: #1d9734 !important;
}
.jashar{
  color: #1d9734 ;
  font-weight: 500!important;
}
.ce-jashar{
  color: black!important;
  font-size: 14px!important;
}
.div-jash{
  border-bottom: 2px solid #737373!important;
 
}
.di{
  width: 320px!important;
}
.jashi{
  color:#1d9734 ;
}
html{
  scroll-behavior: smooth;
}
.nfce{
  color: white;
  font-weight: 800;
}
.second-flag{
  margin-left: -30px;
}
#root{
  overflow-x: hidden;
}
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.tw-logo{
  height: 50px;
  margin-right: -30px;
}
.tw-bg{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tw-bg img{
  width: 100%;
  position: relative;
  margin-top: -30px;
  z-index: -999;
  height: 80vh;
}
a:hover{
  color: #0c1e50 !important;
}

/*------------ header -----------------*/

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 15vh;
  background: rgb(0, 0, 0);
}
.nav__brand {
  text-transform: uppercase;
}

.nav__brand > img{
  width: 200px;
}
.flag{
  width: 32px;
  border: 2px solid white;
  height: 30px;
  border-radius: 50%;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  grid-gap: 3rem;
  gap: 3rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

.nav__link{
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 500;
  font-size: 14px!important;
}

@media screen and (max-width: 1168px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }
  .second-flag{
    margin-left: 0;
  }
  .nav__menu {
    display: none;
    position: absolute;
    top: 7vh;
    height: 50vh;
    width: 100vw;
    background: rgb(0, 0, 0);
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 30px;
    align-items: center;
    transform: translateX(100%);
  grid-gap: 2rem;
  gap: 2rem;
    transition: 0.5s ease-in;
  }
}
/* Active Class */
.nav__active {
  display: inherit;
  transform: translateX(0%);
}

/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-9px, 8px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}

.tw-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0px;
  background-color: white;
}
#cfare{
  background-color: #000;
}
.tw-info-img img {
  width: 400px;
}
.tw-info-text {
  font-family: 'Lato', sans-serif;
  width: auto;
}
.two{
  background-color: #20409a;
}
.row{
  display: flex;
  flex-wrap: wrap;
}
.pak{
  margin-top: 20px;
}
.pak h4 a{
  padding: 30px;
}
.tw-info-text h1 {
  font-size: 60px;
  margin: 0;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.white{
  color: white;
}
.blue{
  color: #3367f5;
}
.tw-info-text p {
  line-height: 26px;
  font-size: 16px;
  
  margin-top: 20px;
}
.tw-info-text a {
  text-decoration: none;
  font-size: 14px;
  color: black;
  border-radius: 8px;
  background-color: #2b2b2b;
  padding: 15px 20px;
  letter-spacing: 0.5px;
  color: white;
}
.tw-info-text a svg {
  width: 13px;
  padding-left: 10px;
}
.tw-info-text .tw-link {
  margin-top: 40px;
}
.tw-uses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.tw-uses .tw-text {
  text-align: right;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}
.tw-box-text h1{
  font-size: 40px;
  padding: 0;
  margin: 0;
  color: white;
}
.tw-box-text p{
  font-family: 'Poppins', sans-serif;
  color: rgb(216, 216, 216);
  font-size: 16px;
  margin: 0;
}
.tw-box-img i{
  font-size: 70px;
}
.tw-middle-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  margin-top: 100px;
  text-align: center;
}
.tw-middle-txt h1{
  
  font-size: 40px;
}
.tw-box-one{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  background-color: #20409a;
  border: 5px solid white;
  padding: 40px 50px;
  color: white;
}
.tw-func{
  margin-top: 50px;
}
.tw-func-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tw-func-txt h1{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 60px;
  font-weight: 500;
}
.tw-func-txt p{
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}
.tw-func-imgs img
{
  width: 300px;
  margin: 20px;
}

.tw-func-img{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.border-img{
  border: 1px solid rgb(100, 100, 100);
}
.tw-func-services{
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-top: 50px;
  flex-wrap: wrap;
}
.tw-func-box{
  max-width:400px;
  text-align: center;
}
.tw-func-box h1{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 10px;
}
.tw-func-box p{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin: 0;
}
.tw-benefits-boxes{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
}
.tw-benefits-boxes img{
  width: 120px;
}
.tw-benefits-text{
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.tw-benefits-text h1{
  font-size: 40px;
}
.tw-benefits-boxes .tw-benefit-box{
  width: 300px;
  height: 200px;
  margin: 60px 30px;
  text-align: center;
}
.tw-benefit-box h1{
  font-size: 23px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  margin: 10px 0;
}
.tw-benefit-box p{
  font-family: 'Lato', sans-serif;
  color: #757575;
  font-size: 16px;
}
footer{
  margin-top: 80px;
  background: rgb(0, 0, 0);
  height: auto;
  padding-top: 40px;
  color: #fff;
}



.footer-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.footer-content h3{
  font-size: 2.1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3rem;
}
.footer-content p{
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
  font-family: 'Poppins', sans-serif;
}

.socials{
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.example{
  width: 200px!important;
}

.socials li{
  margin: 0 10px;
  font-size: 20px;
}

.socials a{
  text-decoration: none;
  color: #fff;
}

.socials a i{
  transition: color .4s ease;
}


.footer-menu{
  margin-bottom: 20px;
}
.footer-menu ul{
  display: flex;
}
.footer-menu ul li{
padding-right: 10px;
display: block;
}

.footer-menu ul li a{
  color: #cfd2d6;
  border: 1.3px solid white;
  padding: 6px 15px;
  border-radius: 50px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
.footer-bottom{
  background: #000;
  padding: 20px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}
.footer-bottom p{
  text-align: center;
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
}
.footer-bottom p a{
color:#ffffff;
font-size: 16px;
text-decoration: none;
}
.footer-bottom span{
  text-transform: uppercase;
  opacity: .4;
  font-weight: 200;
}

@media (max-width:759px) {
  .footer-menu ul{
    display: inline;
  }
  .footer-menu ul li{
    margin-bottom: 20px;
  }
  p{
      font-size: 15px!important;
  }
  .tw-middle-txt h1{
      font-size: 30px!important;
      margin-bottom: 0!important;
  }
  .work-w{
      font-size: 22px!important;
      color: #3c3b3b!important;
  }
  }
/*----- Card --------*/
.card-w {
  width: 320px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 20px;
margin: 10px;
}

.card-w img {
  width: 250px;
  height: 90px;
  margin-top: -50px;
  margin-bottom: 0;
}
.header-w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #757575;
  color: white;
  width: 90%;
  height: 270px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
  font-family: 'Poppins', sans-serif;
  position: relative;
  background-color: #000;
}
.ceo-w {
  margin: 0;
  font-weight: 300;
}
.title-w {
  margin: 0;
  margin-top: 20px;
  font-weight: 500;
  font-family: "Poppins";
}
.shape-w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 7px;
  background: #fff;
  left: 7px;
  right: 7px;
  bottom: 7px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
}
.text-w {
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 30px;
  border-left: 5px solid #123949;
}
span {
  font-weight: 600;
}
.work-w {
  margin-top: 0px;
  margin-bottom: 3px;
  font-size: 22px!important;
  color: #3c3b3b!important;
}
.slogan-w {
  margin: 0;
  font-size: 17px;
}
.div-w {
  width: 330px;
  margin-left: 30px;
}
.info-w {
  display: flex;
  align-items: baseline;
  margin-top: 30px;
  font-family: 'Poppins', sans-serif;
}
.info-w a {
  margin-left: 15px;
  text-decoration: none;
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 500;
}
.info-w i {
  color: #363636;
  font-size: 30px;
}
.shape-two-w {
  width: 70%;
  height: 300px;
  background-color: #123949;
}
.shape-three-w {
  width: 100%;
  height: auto;
  background: red;
}
.save-w {
  margin-top: 50px;
  font-size: 20px;
  color: #ffffff;
  background-color: rgb(31, 30, 30);
  padding: 10px;
  width: 100%;
  border: 0;
  cursor: pointer;
  margin-bottom: 0;
}
.ml-w {
  margin-left: 10px;
}
.links-w {
  margin-top: 30px;
  width: 100%;
  font-size: 25px;
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  align-items: center;
}
.links-w i {
  padding: 10px;
  border-radius: 100%;
  background-color: #363636;
}


@media screen and (max-width:1540px){
  .tw-info-img img{
    width: 500px;
  }
}

  @media screen and (max-width:1146px){
    .tw-info-text{
      text-align: center;
    }
    .tw-info-img {
      margin-top: 60px;
    }
    .tw-uses{
      margin-top: 0px;
    }
    .tw-bg img{
      height: auto;
    }
  }

  
  @media screen and (max-width:854px){
    .tw-uses{
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .card{
      margin-top: 10px;
    }
  }
  
  
  @media screen and (max-width:705px){
    .br{
      display: none;
    }
    .tw-paragraph{
      margin: auto;
      width: 80%;
    }
    .tw-func-txt{
      text-align: center;
    }
    .tw-func-txt h1{
      font-size: 40px;
    }
    .tw-func-txt p{
      padding: 0 50px;
    }
  }

  
  @media screen and (max-width:665px){
    .tw-func-services{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .tw-func-box{
      margin-top: 20px;
    }
    .tw-info-img img{
      width: 300px;
    }
    .tw-info-text h1{
      font-size: 40px;
      margin-top: 20px;
    }
    .row{
      display: block;
    }
    .tw-text h1{
      font-size: 40px;
    }
    .tw-benefits-text h1{
      font-size: 40px;
    }
  }

    .imgslack1{
    width:400px;
  }


  
  @media screen and (max-width:540px){
    .tw-func-txt h1{
      font-size: 30px;
    }
    .tw-benefits-text h1{
      font-size: 30px;
    }
    
  .imgslack1{
    width:300px;
  }
  }

  
  @media screen and (max-width:423px){
    .tw-benefits-text h1{
      font-size: 30px;
      padding: 0 40px;
    }
    .tw-info-text h1{
      font-size: 30px;
    }
    .tw-text{
      font-size: 30px;
    }
    .tw-benefits-text{
      text-align: center;
    }
    footer{
      margin-top: 70px;
    }
  }

  /* -------------------------ADNAN--------------------- */
  .card-s {
    max-width: 370px;
    max-height:687px;
    background-color: rgb(255, 255, 255);
    margin: 15px;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.75);
    margin-top: 20px;
  }
  .la{
    width: 280px;
  }
  .rec-one-s {
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
  }
  .titu {
    font-size: 50px;
    margin-left: 60px;
    color: white;
    margin-top: 0 !important;
    padding-top: 10px;
    font-family: "PT Sans", sans-serif;
  }
  
  .left-rec-s {
    width: 350px;
    height: 300px;
    background-color: #152f79;
    -webkit-clip-path: polygon(0 0, 14% 15%, 14% 100%, 0 85%);
            clip-path: polygon(0 0, 14% 15%, 14% 100%, 0 85%);
  
    margin-top: -180px;
  }
  .left-rec-two-s {
    width: 350px;
    height: 300px;
    background-color: #152f79;
    -webkit-clip-path: polygon(0 0, 14% 22%, 14% 100%, 0 85%);
            clip-path: polygon(0 0, 14% 22%, 14% 100%, 0 85%);
  
    margin-top: -70px;
  }
  .left-rec-three-s {
    width: 350px;
    height: 180px;
    background-color: #152f79;
    -webkit-clip-path: polygon(0 0, 14% 22%, 14% 100%, 0 85%);
            clip-path: polygon(0 0, 14% 22%, 14% 100%, 0 85%);
  
    margin-top: -80px;
  }
  
  .rec-name-s {
    width: 290px;
    height: 80px;
    background-color: rgb(242 242 242);
    -webkit-clip-path: polygon(0 0, 91% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 91% 0, 100% 100%, 0 100%);
    position: relative;
  
    top: -490px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 18px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  
  .rec-name-s h1 {
    padding-top: 5px;
    font-size: 22px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 0;
    color: #0c1e50;
    letter-spacing: 0.7px;
  }
  .working {
    color: orange;
    margin-left: 170px;
    font-family: monospace;
    font-weight: 700;
    font-size: 14px!important;
    margin-top: 40px;
  }
  .emriii {
    margin-left: -10px !important;
  }
  .rec-name-s p {
    padding-bottom: 10px;
    margin-top: 2px;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: #626262;
  }
  .exc-s {
    width: 30px;
    height: 85px;
    position: relative;
  
    top: -577px;
    left: 305px;
  }
  
  .triangle-s {
    width: 150px;
    height: 149px;
    background-color: rgb(242 242 242);
    position: relative;
    z-index: 999;
    top: -210px;
    left: 0px;
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 100%);
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
  
  .bottom-s {
    width: 260px;
    height: 80px;
    background-color: #152f79;
    position: relative;
    z-index: 999;
    top: -290px;
    left: 90px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
  }
  .sss {
    text-align: center;
    color: white;
    margin-left: 60px;
  }
  .sss p{
    color: white;
  }
  .rus {
    margin-top: 10px;
  }
  .add {
    color: white;
    height: auto !important;
  }
  .links-s {
    width: 70px;
    background-color: rgb(242 242 242);
    position: relative;
  
    top: -700px;
    margin: 0 30px;
    padding: 10px 5px;
    border: 10px solid white;
  }
  
  .ul-s {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
  }
  
  .ul-s i {
    font-size: 20px;
    padding: 15px;
    border-radius: 100%;
    margin-left: 10px;
    background-color: white;
    color: #152f79;
  }
  
  .ul-s h1 {
    font-size: 15px;
    margin-left: 25px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0px !important;
  }
  
  .second-s {
    margin-top: 10px;
  }
  .blue{
    color: #3367f5;
  }

h1 .blue{
  color: #3367f5;
}

/* -------------------NAIM---------------------------------- */
.tw-uses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.tw-uses .tw-text {
  text-align: right;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}
.tw-box-text h1{
  font-size: 40px;
  padding: 0;
  margin: 0;
}
.tw-box-text p{
  font-family: 'Poppins', sans-serif;
  color: rgb(216, 216, 216);
  font-size: 16px;
  margin: 0;
}
.tw-box-img i{
  font-size: 70px;
}
.tw-middle-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  margin-top: 100px;
  text-align: center;
}
.tw-middle-txt h1{
  
  font-size: 40px;
}
.tw-box-one{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  background-color: #2b2b2b;
  padding: 40px 50px;
  color: white;
}
.tw-func{
  margin-top: 150px;
}
.tw-func-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tw-func-txt h1{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
}
.tw-func-txt p{
  margin-top: 3px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}
.tw-func-imgs img
{
  width: 300px;
  margin: 20px;
}

.tw-func-img{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.border-img{
  border: 1px solid rgb(100, 100, 100);
}
.tw-func-services{
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-top: 50px;
  flex-wrap: wrap;
}
.tw-func-box{
  max-width:400px;
  text-align: center;
}
.tw-func-box h1{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 10px;
}
.tw-func-box p{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin: 0;
}
.tw-benefits-boxes{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
}
.tw-benefits-boxes img{
  width: 120px;
}
.tw-benefits-text{
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.tw-benefits-text h1{
  font-size: 40px;
}
.tw-benefits-boxes .tw-benefit-box{
  width: 300px;
  height: 200px;
  margin: 60px 30px;
  text-align: center;
}
.tw-benefit-box h1{
  font-size: 23px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  margin: 10px 0;
}
.tw-benefit-box p{
  font-family: 'Lato', sans-serif;
  color: #757575;
  font-size: 16px;
}
.card-w {
  width: 320px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 20px;
  border-radius: 20px;
  margin:10px;
}
.card-w img {
  width: 250px;
  height: 90px;
  margin-top: -50px;
  margin-bottom: 0;
}
.header-w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #757575;
  color: white;
  width: 90%;
  height: 270px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
  font-family: 'Poppins', sans-serif;
  position: relative;
  background-color: #000;
}
.ceo-w {
  margin: 0;
  font-weight: 300;
}
.title-w {
  margin: 0;
  margin-top: 20px;
  font-weight: 500;
}
.shape-w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 7px;
  background: #fff;
  left: 7px;
  right: 7px;
  bottom: 7px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
}
.text-w {
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 30px;
  border-left: 5px solid #123949;
}
span {
  font-weight: 600;
}
.work-w {
  margin-top: 0px;
  margin-bottom: 3px;
  font-size: 20px!important;
  color: #3c3b3b!important;
}
.slogan-w {
  margin: 0;
  font-size: 17px;
}
.div-w {
  margin-top: 20px;
  width: 330px;
  margin-left: 30px;
}
.info-w {
  display: flex;
  align-items: baseline;
  margin-top: 40px;
  font-family: 'Poppins', sans-serif;
}
.info-w a {
  margin-left: 15px;
  text-decoration: none;
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 500;
}
.info-w i {
  color: #363636;
  font-size: 30px;
}
.shape-two-w {
  width: 70%;
  height: 300px;
  background-color: #123949;
}
.shape-three-w {
  width: 100%;
  height: auto;
  background: red;
}
.save-w {
  margin-top: 50px;
  font-size: 20px;
  color: #ffffff;
  background-color: rgb(31, 30, 30);
  padding: 10px;
  width: 100%;
  border: 0;
  cursor: pointer;
  margin-bottom: 0;
}
.ml-w {
  margin-left: 10px;
}
.links-w {
  margin-top: 30px;
  width: 100%;
  font-size: 25px;
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  align-items: center;
}
.links-w i {
  padding: 10px;
  border-radius: 100%;
  background-color: #123949;
}


@media screen and (max-width:1540px){
  .tw-info-img img{
    width: 500px;
  }
}

  @media screen and (max-width:1146px){
    .tw-info-text{
      text-align: center;
    }
    .tw-info-img {
      margin-top: 60px;
    }
    .tw-uses{
      margin-top: 0px;
    }
    .tw-bg img{
      height: auto;
    }
  }

  
  @media screen and (max-width:854px){
    .tw-uses{
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .card{
      margin-top: 20px;
    }
  }
  
  
  @media screen and (max-width:705px){
    .br{
      display: none;
    }
    .tw-paragraph{
      margin: auto;
      width: 80%;
    }
    .tw-func-txt{
      text-align: center;
    }
    .tw-func-txt h1{
      font-size: 40px;
    }
    .tw-func-txt p{
      padding: 0 50px;
    }
  }

  
  @media screen and (max-width:665px){
    .tw-func-services{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .tw-func-box{
      margin-top: 20px;
    }
    .tw-info-img img{
      width: 300px;
    }
    .tw-info-text h1{
      font-size: 40px;
    }
    .tw-text h1{
      font-size: 40px;
    }
    .tw-benefits-text h1{
      font-size: 40px;
    }
  }

    .imgslack1{
    width:400px;
  }


  
  @media screen and (max-width:540px){
    .tw-func-txt h1{
      font-size: 30px;
    }
    .tw-benefits-text h1{
      font-size: 30px;
    }
    
  .imgslack1{
    width:300px;
  }
  }

  
  @media screen and (max-width:423px){
    .tw-benefits-text h1{
      font-size: 30px;
      padding: 0 40px;
    }
    .tw-info-text h1{
      font-size: 30px;
    }
    .tw-text{
      font-size: 30px;
    }
    .tw-benefits-text{
      text-align: center;
    }
    footer{
      margin-top: 70px;
    }
  }
/* -------------------Fitim Radoniqi---------------------------------- */

.header-a{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 230px;
  font-family: 'Poppins', sans-serif;
  position: relative;
  text-align: center;
}
.alt img{
  width: 250px;
  height: 70px;
}
.fit{
  margin-top: 0!important;
}
.fita{
  font-size: 35px;
}
.top{
  color: #a12378;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.text-a{
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 10px;
}
.div-a{
  margin-top: -25px;
}
.as i{
  background: #939598;
  padding: 10px;
  color: white;
  font-size: 25px;
}
.as{
  margin-top: 10px;
  align-items: center;
}
.aw{
  background: #85246e;
  margin-top: 10px;
}
.as a {
  font-size: 16px;
}
.as img{
  width: 25px;
  height: 25px;
}
.fasi{
  background: #939598;
  padding-left: 10px;
  padding-top: 18px;
  padding-right: 10px;
}
.code img {
  height: 150px;
  width: 150px;
}
.qrc{
  margin-top: 80px;
  text-align: center;
}

a{
  cursor: pointer!important;
}
.div-a a:hover{
  color:#85246e!important
}



/* ---------------------------------------------------Armend Alidemaj----------------------- */
.alti img {
  height: 120px;
  width: 150px;
margin-top: 20px;
}
.div-b{
  margin-top: 0px;
}
.zahnlogo{
  margin-right: 20px;
}
.asi i{
  background: #e63312;
  padding: 10px;
  color: white;
  font-size: 25px;
}
.asi{
  align-items: center;
}
.asi a {
  font-size: 16px;
}
.awi{
  margin-top: 30px;
  background: black;
}

/* ----------------------besimmm-------------------------- */

.altb{
  margin-top: 30px;
}
.altb img{
  width: 300px;
  height: 80px;
}
.asb i{
  background: #a70000;
  padding: 10px;
  color: white;
  font-size: 25px;
}
.rrjete{
  display: flex;
}
.what .ss{
  width: 30px;
  height: 30px;
  margin-top: 30px;
}
.fg{
  margin-right: 50px;
}
.asb{
  align-items: center;
  margin-top: 15px;
}

.topi{
  color: #9b9b9b;
}
.awb{
  background-color: #a70000;
  margin-top: 10px;
}
.asb a{
  font-size: 16px;
}

.llogot{
  display: flex;
}

/* --------------------------------Astriti mobile shop */


.socia i{
  padding: 10px 15px;
    color: #fd0000;
    font-size: 25px;
    border-radius: 8px;
}
.how{
  margin-top: 30px;
  border-bottom: 2px solid #dfdfdf;
}
.astritii{
  background: url(/static/media/ren.d0c5b27f.png);
  background-size: cover;
  margin-top: 50px;
}
.nan{
  background: transparent;
  box-shadow: none!important;
}
.div-v{
  width: 80%;
}
.how a {
  font-size: 14px;
}
.alta img {
  margin-top: 20px;
}
.alta {
  text-align: center;
}
.how i{
  background-color: white;
  color: #fd0000;
  border-radius: 8px;
}
.mob{
  margin-top: 30px;
  background-color: #dfdfdf;
  color: #3b3b3b;
  width: 80%;
  border-radius: 20px;
}
.mobile{
  font-size: 20px!important;
  font-weight: 600;
}
/* --------------------------------example */
.personal .ex i{
  background-color: #000;
    padding: 15px;
    color: white;
    font-size: 25px!important;
    margin: 5px 0;
}
.alli{
  color: white;
}
.namest{
  font-size: 35px!important;
}
.ex a {
  font-weight: 500;
}
.ex .loc {
  font-weight: 500;
}
.ex{
  height: 95px;
}
.socialin{
  display: flex;
  justify-content: space-around;
}
.socialin img{
  height: 40px;
}
.juaj{
  margin-top: -20px;
}

/* -------------------------------dolphins */
.sociallink{
  display: flex;
    justify-content: space-around;
    padding-top: 10px;
    padding-left: 50px;
}
.sociallink i {
  background: #f2f2f2;
    padding: 15px;
    color: #152f79;
    border-radius: 100%;
}
.ul-s h1{
  line-height: 30px;
}
.socialet{
margin-top: -100px;
  display: flex;
  font-size: 20px;
}
.sasa{
  z-index: 999999;
  color: #d6c05d;
 font-size: 18px;
}

/* -----------------------dolphins */
.card-d{
  padding: 0!important;
  border-radius: 20px;
  width: 360px;
  border: 1px solid rgb(221, 221, 221);
  margin-top: 20px;
}
.card-d img{
margin-top: 0;
width: 100px;
height: auto!important;
border-radius: 50%;
}
.header-b{
  width: 100%;
  background-color: #193278;
  border-top-right-radius:20px;
  border-top-left-radius:20px;
}
.delfi{
  color: white;
  font-size: 14px;
}
.besimz{
  color: white;
  margin-top: 0;
  font-size: 25px;
}
.div-bes{
  width: 320px;
  border-bottom: 2px solid #e4e4e4;
  margin-bottom: 20px;
}
.dolpsoc{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.ico{
  width: 24px!important;
  border-radius: 0!important;
}
.socico{
  background: white;
    padding: 10px 10px 5px 10px;
    border-radius: 100%;
    margin-top: -7%;
    z-index: 999;
    box-shadow: 0px 0px 20px 0px rgb(170 170 170 / 75%);
}
.first{
  margin-top: 10px;
  margin-bottom: 10px;
}
.fund{
  border-bottom: 0;
  display: flex;
}
.inf{
  align-items: center;
  display: flex;
  line-height: 2;
}
.icons{
  width: 18px!important;
  margin-right: 5px;
  border-radius: 0!important;
}
.info-b p{
font-size: 16px;
font-weight: 500;
color: #193278;
}
.inf a {
  font-size: 15px!important;
}
.fund a {
  font-size: 14px!important;
}
.useri{
  background-color: #193278;
  width: 50px;
  border-radius: 100%;
  height: 50px;
  margin-top: -15%;
}
.userluc{
  background-color: #000;
  width: 50px;
  border-radius: 100%;
  height: 50px;
  margin-top: -15%;
}
.useri img {
  padding: 5px 12px;
  width: 25px!important;
}

/* ------------------------------denis */
.denislogo{
  width: 300px;
  border: none;
  margin-top: 40px;
}
.logo2{
  margin-top: 20px;
  margin-left: 20px;
}
.dnie{
  margin-top: -100px;
  width: 100%;
  margin-right: 30px;

}
.dnie .namest{
  color: white;
}

.deno{
  margin-right: 15px!important;
  margin-top: 0!important;
}
.dnie .ceo{
  color: #c3c3c3;
  margin-right: 22%!important;
}
.denisit{
  background-color: #031428;
}
.denisit a {
  color: white!important;
}
.denisit i {
  background-color: transparent!important;
  border: 1px solid white;
  border-radius: 3px;
}
.denisit button{
  border-top: 1px solid white;
}
.bg{
  clip-path: polygon(0 0, 0 70%, 100% 50%, 100% 0);
  -webkit-clip-path:polygon(0 0, 0 70%, 100% 50%, 100% 0);
    height: 300px;
    background-color: white;
    text-align: center;
}
/* .denisit .socialin img{
  height: 30px;
} */
.socialet img {
  height: 30px;
}
.socialet a {
  z-index: 999;
  margin-left: 20px;
}
.flexi{
  display: flex;
  justify-content: space-between;
}
.dgco{
  width: 150px;

}
.card-sa{
  border: none;
  width: 340px;
}

.alija{
  margin-top: 20px;
}
/* ---------------------elviongashi---------------------- */
.person{
  line-height: 5;
}
.iconperson{
  width:40px!important;
  margin-right: 15px;
  border-radius: 0!important;
}
.person a {
  font-size: 17px!important;
  font-weight: 500;
  color: black;
}
.funus{
  justify-content: space-between;
}
.useripers{
  margin-right: 20px;
  width: 60px;
  height: 60px;
}
.header-person{
  -webkit-clip-path: circle(60.8% at 49% 6%);
          clip-path: circle(60.8% at 49% 6%);
}
.title-person{
  color: white;
  margin-top: -20px;
  font-size: 35px;
}
.useripers img{
 width: 35px!important;
}
.emajl{
  line-height: 2;
}
/* ---------------------------havaja-------------------------- */
.sochava{
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.havaja{
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e4e4e4;
  width: 320px!important;
  margin-left: -10px!important;
  margin-bottom: 30px;
  height: 65px;
}
.juaji{
  margin-top: -30px;
  border-radius: 100%;
}
.fff{
  margin-bottom: 0!important;
}
@keyframes animate-figure3 {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-10px) translateX(-40px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}
@keyframes animate-figure4 {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-10px) translateX(40px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}
@keyframes animate-figure1 {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-20px) translateX(10px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}
@keyframes animate-figure2 {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-20px) translateX(-10px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}
.dress1{
  animation: animate-figure3 infinite 5s;
  margin-top: 10px;
}
.dress2{
  animation: animate-figure4 infinite 5s;
  margin-top: 10px;
}
.dress3{
  animation: animate-figure1 infinite 5s;
  margin-top: -40px;
}

.dress4{
  animation: animate-figure2 infinite 5s;
  margin-top: -80px;
  margin-left: 200px;
}
.hava{
  -webkit-clip-path: none;
          clip-path: none;
  background-color: white;
}
.perH{
  text-align: center;
}

/* -------------------------------------------xheneta-------------- */
.header-girl{
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  -webkit-clip-path: none;
          clip-path: none;
  background:linear-gradient(266deg, rgb(9 46 78) 20%, rgb(13 210 255) 80%)!important;
}
.usegir{
  background:linear-gradient(266deg, rgb(9 46 78) 20%, rgb(13 210 255) 80%)!important;
}
.persone{
  line-height: 7;
}

/* ---------------------------butiku viona------------------ */
.butiku{
  border-radius: 100%;
  text-align: center;
}
.butiku p{
  color: white;
  font-weight: 700;
  font-size: 30px!important;
  padding: 10px;
}
.viona{
  border-radius: 100%;
}
/* ---------------------------liridoni constr--------------------------------- */


.b-liri{

  background-color: transparent;
  width: 100%;
  height: auto;
 -webkit-clip-path: none;
         clip-path: none;
}
.b-liri img {
  margin-top: 0;
  width: 230px;
  margin-right: 25px;
}
.div-liri{
  margin-left: 20px;
  width: 300px;
}
.liris{
  margin-bottom: 20px;
}
.useri-lir{
  background-color: #ab1f26;
}
.ceo-arch{
  color:#ab1f26;
  font-size: 18px!important;
  font-weight: 400;
}
.perArch{
  margin-top: 30px;
  margin-left: 20px;
  text-align: center;
}
.titles{
  font-size: 25px;
  font-family:Poppins;
  font-weight: 400;
}
.info-l p{
  color:#000;
}
.inf-l{
  line-height: 4;
}
.inf-l a {
  color: black;
}
.inf-l a:hover{
  color: #ab1f26!important;
}
.fundi{
  display: flex;
  justify-content: space-between;
}

.liri-soca{
  margin-top: 40px;
  padding-bottom: 10px;
}
.dolpsoci{
  display: flex;
  justify-content: center;
  width: 100%;
}
.dolpsoci a {
  box-shadow: none;
}
.inf-l .icons{
  width: 25px!important;
}
.icolit{
  width: 28px;
}

/* --------------------------------kuoni------------- */
.header-c{
  background:url(/static/media/kuoni.ce416ab9.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  border-bottom: 2px solid #dce3e8;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.header-d{
  background:url("/static/media/luxora .33c7cba2.png");
  background-size: cover;
  background-position: center;
  width: 100%;

  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.card-l{
  background-color:#d4e7d8 ;
}
.luxor{
  padding-top: 150px;
}
.candy{
  color: black;
}
.kuon{
  padding-top: 100px;
}
.perkuon{
  margin-top: 5px;
}
.copy{
  border-top: 1px solid #dce3e8;
}
.copyright{
  text-align: center;
  font-size: 10px!important;
}
.copyright a{
  font-size: 11px!important;
  color: #152f79;
}
.copyrighti{
  padding: 5px;
}
/* -------------------------------------------nila amini------------------------------ */

.inf-amin{
  line-height: 2.5;
}
.b-amin img{
  margin-right: 0;
  width: 200px;
  margin-top: -10px;
}
.useri-amin{
  background-color: #212121;
}
.nila{
  font-weight: 500;
  letter-spacing: 2px;
}
.amina{
  margin-top: 0;
}
.info-l{
  color: black;
  margin-bottom: 15px;

}
.div-lux{
  border-bottom: 2px solid #fff;
  line-height: 3;
}
.div-f{
  margin-top: 30px;
}
.info-l .inf img{
  width: 25px;
  margin-right: 5px;
  
}
.alo{
  
  margin-bottom: 5px;
}
.info-l a:hover{
  color: black!important;
}
.info-l a{
  color: black!important;
  margin-left: 5px;
}

/*

Tooplate 2115 Marvel

https://www.tooplate.com/view/2115-marvel

*/


.full-screen {
  padding: 8rem 0;
}

.small-text {
  color: #5b5b5b;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 50px;
  letter-spacing: 0.2px;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

a {
  font-weight: normal;
  text-decoration: none !important;
  transition: all 0.4s ease;
}
a:hover {
  color: #3367f5 !important;
}



p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: #5b5b5b;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  letter-spacing: -1px;
}

h1 {
  color: #212121;
  font-size: 2.8em;
  margin: 24px 0;
}

h2 {
  color: #353535;
  font-size: 2.4em;
  font-weight: bold;
}

h3 {
  color: #484848;
}

h3,
b, strong {
  font-weight: bold;
}

.custom-btn {
  background: #eee;
  color: #5b5b5b;
  font-weight: bold;
  border-radius: 50px;
  padding: 13px 29px;
  font-size: 14px;
  line-height: normal;
  overflow: hidden;
  transition: all 0.4s ease;
}
.custom-btn:hover {
  color: #3367f5;
}
.custom-btn.custom-btn-bg {
  background: #474559;
  color: #ffffff;
}
.custom-btn.custom-btn-bg:hover {
  background: #3367f5;
  color: #ffffff !important;
}

.animated {
  position: relative;
}

.animated-info {
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
  min-width: 400px;
  position: relative;
}

.animated-item {
  color: #3367f5;
}

.animated-item {
  font-size: 38px;
  line-height: inherit;
  display: block;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  animation: BottomTotop 6s linear infinite 0s;
}
.animated-item:nth-child(2n+2) {
  animation-delay: 2s;
}
.animated-item:nth-child(3n+3) {
  animation-delay: 4s;
}

@keyframes BottomTotop {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(5px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(5px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}



.copyright-text {
  font-size: 16px;
  font-weight: normal;
  display: block;
}

.color-mode {
  font-weight: bold;
  cursor: pointer;
}

.color-mode-icon {
  position: relative;
  right: 6px;
}
.color-mode-icon:after {
  font-family: 'unicons';
  content: '\ea9f';
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
}
.color-mode-icon.active:after {
  font-family: 'unicons';
  content: '\eb65';
  font-size: 30px;
  color: #ffffff;
}

.dark-mode {
  background: #0c0c0d;
}
.dark-mode .navbar-light .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.dark-mode .navbar-light .navbar-nav .nav-link:hover {
  color: #3367f5;
}
.dark-mode .navbar[class*="headroom--not-top"] {
  background: #0c0c0d;
  border-bottom: 1px solid #1f1f1f;
}
.dark-mode .small-text {
  background: #0d0c15;
  color: #ffffff;
}
.dark-mode .feature-card .uil,
.dark-mode .navbar-light .navbar-brand,
.dark-mode h1, .dark-mode h2, .dark-mode h3, .dark-mode h4, .dark-mode h5, .dark-mode h6,
.dark-mode .color-mode {
  color: #ffffff;
}
.dark-mode .owl-carousel .owl-nav button.owl-next,
.dark-mode .owl-carousel .owl-nav button.owl-prev,
.dark-mode .owl-carousel button.owl-dot {
  color: #ffffff;
}

.timeline-wrapper {
  position: relative;
  padding: 22px 0;
}
.timeline-wrapper:last-child:before {
  height: 0;
}
.timeline-wrapper:before {
  content: "";
  background: #474559;
  width: 3px;
  height: 100%;
  position: absolute;
  left: 38px;
}

.timeline-yr {
  background: #474559;
  border-radius: 100%;
  position: absolute;
  width: 75px;
  height: 75px;
  line-height: 75px;
  text-align: center;
}
.timeline-yr span {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  display: block;
  line-height: 75px;
}

.timeline-info {
  display: inline-block;
  vertical-align: top;
  max-width: 432px;
  margin-left: 6em;
}
.timeline-info small {
  color: #474559;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}


.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #5b5b5b;
}

.google-map iframe {
  width: 100%;
}

.contact-form {
  position: relative;
}
.contact-form .form-control {
  background: transparent;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  font-weight: bold;
  margin: 16px 0;
}
.contact-form .form-control:not(textarea) {
  height: 48px;
}
.contact-form .form-control:hover, .contact-form .form-control:focus {
  border-color: #ffc200;
}
.contact-form .submit-btn {
  background: #ffc200;
  border-radius: 50px;
  color: #ffffff;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  transition: all 0.4s ease;
}
.contact-form .submit-btn:hover {
  background: #474559;
}

.contact-info {
  background: #474559;
  border-radius: 0 0 3px 3px;
  position: relative;
  bottom: 8px;
}
.contact-info p, .contact-info a {
  color: #f7f3f3;
}

.social-links .uil {
  color: #f7f3f3;
  font-size: 20px;
  display: block;
  margin: 5px 0;
}
.social-links .uil:hover {
  color: #ffc200;
}

@media (min-width: 1270px) {
  .owl-theme .owl-dots {
    position: relative;
    bottom: 50px;
  }
}
@media (max-width: 991px) {
  .full-screen {
    padding-bottom: 4rem;
  }

  .color-mode {
    display: none;
  }

  .about-image {
    margin-top: 4em;
  }

  .mobile-mt-2,
  .contact-form {
    margin-top: 2em;
  }

  .contact-info {
    padding: 0 2rem;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 2.4em;
  }

  h2 {
    font-size: 2em;
  }

  .animated-item {
    font-size: 28px;
  }
}
@media (max-width: 580px) {
	.animated-info {
		min-width: 200px;
	}
  .animated-item {
    font-size: 30px;
  }

  .custom-btn-group {
    text-align: center;
  }

  .custom-btn {
    display: block;
    margin: 10px 0;
  }
  .timeline-info small {
    display: block;
    margin: 10px 0 0 0;
  }
}
@media (max-width: 320px) {
  .animated-text {
    margin-top: 0;
  }

  .about-text {
    text-align: center;
  }

  .full-screen {
    padding: 4rem 0;
  }

  .mobile-block {
    display: block;
  }

  .contact-info {
    flex-direction: column;
  }

  .social-links li {
    display: inline-block;
    vertical-align: top;
  }
}
.bg-opacity-9 {
	background-color: rgba(0, 0, 0, 0.9);
}
.sec-title-container {
	width:100%;
	float:left;
	padding-bottom:40px;
}

.about-us {
  padding-top: 30px;
  position: relative;
  background-color: #0c0c0d;
}

.about-us .section-heading {
  margin-bottom: 45px;
}

.about-us .section-heading,
.about-us .box-item,
.about-us p,
.about-us .box-item .gradient-button,
.about-us .box-item span {
  position: relative;
  z-index: 1;
  color: white;
}


.about-us .box-item {
  box-shadow: 0px 0px 15px rgba(0,0,0,0.07);
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 40px;
  margin-bottom: 30px;
}

.about-us .box-item h4 a {
  font-size: 20px;
  font-weight: 700;
  margin-top: 8px;
  color: black;
  transition: all .3s;
}

.about-us .box-item p {
  margin-bottom: 0px;
  color: black;
}

.about-us .box-item:hover h2 a {
  color: #4b8ef1;
}
.nfctech{
  color: #3367f5;
}

.about-us .gradient-button {
  margin-top: 30px;
  margin-bottom: 10px;
}

.about-us span {
  font-size: 14px;
  color: #7a7a7a;
}

.about-us .right-image {
  position: relative;
  z-index: 1;
}

.about-us:after {
  width: 777px;
  height: 1132px;
  content: '';
  position: absolute;
  background-repeat: no-repeat;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

/*
Template: Brite
Author: Codelayers
Version: 1.0
Design and Developed by: Codelayers


*/

/*
======================================
[ CSS TABLE CONTENT ]
======================================
    [Table of contents]

	1. General
		+ Text Colors
		+ Divider lines
		+ Paddings
		+ Opacity styles
		+ fonts
		+ Icon Boxes
		+ Text Boxes
		+ Buttons
		+ Opacity styles
		+ buttons

	2. Image boxes
		+ section-side-image
		+ background-imgholder
		+ image-left
		+ image-holder
		+ nodisplay-image
	
	3. Lists
		+ iconlist
		+ clientlogo-list
		+ side-nav-list
		+ category-links
		+ tags 
	
	4. Menu Backgrounds
		+ Headers
	
	5. Topbar Styles
		+ topbar
		+ toplist
	
	6. Logo
		+ logo
	
	7. Section Titles
		+ section titles
	
	8. Page Section Styles
		+ Section Styles
	
	9. Footer Styles
		+ footer logo
		+ lists
	
	
	10. Parallax Sections
	
	11. Overlay Styles
		+ Section Overlay
		+ video overlay
	
	12. Social Links
		+ social-icons-1
		+ social-icons-2
		+ social-icons-3
	
	13. Feature boxs
	
	
======================================
[ END CSS TABLE CONTENT ]
======================================
*/


/* =========== General ============= */


body {
	font: 14px 'Open Sans', sans-serif;
	font-weight: normal;
	font-style: normal;
	line-height: 23px;
	color: #727272;
}

/* a link Styles
 ------------------------------ */
 .logo{
	width: 200px;
 }
a, .btn {
	transition: all 0.3s ease-out 0s;
}
a:hover {
	text-decoration: none;
}
a:focus, a:hover {
	color: #101010;
}
a {
	outline: medium none !important;
	color: #727272;
}
.uppercase {
	text-transform: uppercase;
}

/* Headungs
 ------------------------------ */
 
h1, h2, h3, h4, h5, h6 {
	font-family: 'Montserrat', sans-serif;
	/*font-family: 'Roboto', sans-serif;*/
	font-weight: normal;
	color: #272727;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
	color: inherit;
}
h1 {
	font-size: 40px;
	line-height: 40px;
	margin-bottom: 20px;
	font-style: normal;
	font-weight: 300;
}
h1.less-mar-1 {
	margin-bottom: 10px;
}
h1.less-mar-2 {
	margin-bottom: 5px;
}
h2 {
	font-size: 35px;
	line-height: 35px;
	margin-bottom: 20px;
	font-style: normal;
	font-weight: 300;
}
h2.nomargin {
	margin-bottom: 0px;
}
h2.less-mar-1 {
	margin-bottom: 3px;
}
h2.less-mar-2 {
	margin-bottom: 5px;
}
h2.less-mar-3 {
	margin-bottom: 7px;
}
h2.less-mar-4 {
	margin-bottom: 9px;
}
h3 {
	font-size: 27px;
	line-height: 30px;
	margin-bottom: 18px;
	font-style: normal;
}
h3.nomargin {
	margin-bottom: 0px;
}
h3.less-mar-1 {
	margin-bottom: 3px;
}
h3.less-mar-2 {
	margin-bottom: 5px;
}
h3.less-mar-3 {
	margin-bottom: 7px;
}
h3.less-mar-4 {
	margin-bottom: 9px;
}
h4 {
	font-size: 22px;
	line-height: 25px;
	margin-bottom: 18px;
	font-style: normal;
}
h4.nomargin {
	margin-bottom: 0px;
}
h4.less-mar-1 {
	margin-bottom: 3px;
}
h4.less-mar-2 {
	margin-bottom: 5px;
}
h4.less-mar-3 {
	margin-bottom: 7px;
}
h4.less-mar-4 {
	margin-bottom: 9px;
}
h5 {
	font-size: 18px;
	line-height: 23px;
	margin-bottom: 16px;
	font-style: normal;
}
h5.nomargin {
	margin-bottom: 0px;
}
h5.less-mar-1 {
	margin-bottom: 3px;
}
h5.less-mar-2 {
	margin-bottom: 5px;
}
h5.less-mar-3 {
	margin-bottom: 7px;
}
h5.less-mar-4 {
	margin-bottom: 9px;
}
h6 {
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 14px;
	font-style: normal;
}
h6.nomargin {
	margin-bottom: 0px;
}
h6.less-mar-1 {
	margin-bottom: 3px;
}
h6.less-mar-2 {
	margin-bottom: 5px;
}
h6.less-mar-3 {
	margin-bottom: 7px;
}
h6.less-mar-4 {
	margin-bottom: 9px;
}
.nopadding {
	padding: 0 !important;
	margin: 0 !important;
}
.h1, .h2, .h3, h1, h2, h3, h4 {
	margin-top: 0;
}
ul {
	list-style-type: none;
}

/*=============== Text Colors ============= */

.text-white {
	color: #fff;
}
.text-dark {
	color: #242424;
}
.text-light {
	color: #acacac;
}
.text-primary {
	color: #fce206;
}
.text-gray {
	color: #727272;
}
.text-gray-2 {
	color: #a1a1a1;
}
a:hover.text-hover-primary {
	color: #fce206;
}
/*=============== Margins ============= */

.margin-bottom {
	margin-bottom: 0px;
}
.margin-bottom-2 {
    margin-bottom: 20px;
}
.margin-bottom-3 {
    margin-bottom: 30px;
}
.margin-bottom-4 {
    margin-bottom: 40px;
}
.margin-bottom-5 {
    margin-bottom: 50px;
}
.margin-top {
	margin-top: 0px;
}
.margin-top-1 {
    float: left;
    margin-top: 10px;
    width: 100%;
}
.margin-top-2 {
    float: left;
    margin-top: 20px;
    width: 100%;
}
.margin-top-3 {
    float: left;
    margin-top: 30px;
    width: 100%;
}
.margin-top-4 {
    float: left;
    margin-top: 40px;
    width: 100%;
}
.margin-top-5 {
    float: left;
    margin-top: 50px;
    width: 100%;
}
.margin-top-6 {
    float: left;
    margin-top: 60px;
    width: 100%;
}
.margin-top-7 {
    float: left;
    margin-top: 70px;
    width: 100%;
}
.col-divider-margin {
	width: 100%;
	float: left;
	margin-top: 60px;
}
.col-divider-margin-1 {
	width: 100%;
	float: left;
	margin-top: 10px;
}
.col-divider-margin-2 {
	width: 100%;
	float: left;
	margin-top: 20px;
}
.col-divider-margin-3 {
	width: 100%;
	float: left;
	margin-top: 30px;
}
.col-divider-margin-4 {
	width: 100%;
	float: left;
	margin-top: 40px;
}
.col-divider-margin-5 {
	width: 100%;
	float: left;
	margin-top: 50px;
}
.col-divider-margin-6 {
	width: 100%;
	float: left;
	margin-top: 60px;
}
.nobottom-margin {
	margin-bottom: 0px;
}

.margin-left-1 {
    margin-left: 10px;
}
.margin-left-2 {
    margin-left: 20px;
}
.margin-left-3 {
    margin-left: 30px;
}
.margin-left-4 {
    margin-left: 40px;
}
.margin-left-5 {
    margin-left: 50px;
}
.margin-left-6 {
    margin-left: 60px;
}
.margin-left-7 {
    margin-left: 70px;
}
.colmargin {
	margin: 0 0 0 0;
}
.col-centered {
	float: none;
	margin: 0 auto;
}

/* font weight
 ------------------------------ */
.f-weight-1 {
	margin-bottom: 0px;
}

/*=============== Divider lines ============= */
 .divider-line {
	float: left;
	width: 100%;
}
.divider-line.dashed {
	border-bottom: 1px dashed;
}
 .divider-line.solid {
	border-bottom: 1px solid;
}
.divider-line.dark {
	border-bottom-color:#444444;
}
.divider-line.dark-2 {
	border-bottom-color:#343333;
}
.divider-line.light {
	border-bottom-color:#f1f1f1;
}
.divider-line.light-2 {
	border-bottom-color:#ebebeb;
}
 .divider-line.top-padding {
	padding-top: 20px;
}
 .divider-line.bottom-margin {
	margin-bottom: 20px;
}
.divider-line.margin {
	margin: 20px 0;
}
.divider-line.margin-2 {
	margin: 40px 0;
}
/*=============== Paddings ============= */
 
.no-top-padding {
	padding-top: 0;
}

/* padding top
 ------------------------------ */
.padding-top-1 {
	padding-top: 10px;
}
.padding-top-2 {
	padding-top: 20px;
}
.padding-top-3 {
	padding-top: 30px;
}
.padding-top-4 {
	padding-top: 40px;
}
.padding-top-5 {
	padding-top: 50px;
}
.padding-top-6 {
	padding-top: 60px;
}

/* left padding
 ------------------------------ */
 
.padding-left-1 {
	padding-left: 10px;
}
.padding-left-2 {
	padding-left: 20px;
}
.padding-left-3 {
	padding-left: 30px;
}
.padding-left-4 {
	padding-left: 40px;
}
.padding-left-5 {
	padding-left: 50px;
}
.padding-left-6 {
	padding-left: 60px;
}
.padding-left-7 {
	padding-left: 70px;
}
.padding-left-8 {
	padding-left: 80px;
}

/* right padding
 ------------------------------ */
 
.padding-right-1 {
	padding-right: 10px;
}
.padding-right-2 {
	padding-right: 20px;
}
.padding-right-3 {
	padding-right: 30px;
}
.padding-right-4 {
	padding-right: 40px;
}
.padding-right-5 {
	padding-right: 50px;
}
.padding-right-6 {
	padding-right: 60px;
}
.padding-right-7 {
	padding-right: 70px;
}
.padding-right-8 {
	padding-right: 80px;
}
.no-padding-top {
	padding-top: 0px;
}

/*=============== Opacity styles ============= */
.opacity-1 {
	opacity:0.1;
}
.opacity-2 {
	opacity:0.1;
}
.opacity-3 {
	opacity:0.3;
}
.opacity-4 {
	opacity:0.4;
}
.opacity-5 {
	opacity:0.5;
}
.opacity-6 {
	opacity:0.6;
}
.opacity-7 {
	opacity:0.7;
}
.opacity-8 {
	opacity:0.8;
}

/*=============== fonts ============= */

.droid-serif {
	font-family: 'Droid Serif', serif;
}
.ubuntu {
	font-family: 'Ubuntu', sans-serif;
}
.oswald {
	font-family: 'Oswald', sans-serif;
}
.muli {
	font-family: 'Muli', sans-serif;
}
.great-vibes {
	font-family: 'Great Vibes', cursive;
}
.old-standardtt {
	font-family: 'Old Standard TT', serif;
}
.dosis {
	font-family: 'Dosis', sans-serif;
}
.roboto-slab {
	font-family: 'Roboto Slab', serif;
}
.gfs-didot {
	font-family: 'GFS Didot', serif;
}
.raleway {
	font-family: "Raleway", sans-serif;
}
.gloria-hallelujah {
	font-family: 'Gloria Hallelujah', cursive;
}
.love-ya-like-a-sister {
	font-family: 'Love Ya Like A Sister', cursive;
}
.michroma {
	font-family: 'Michroma', sans-serif;
}
.sacramento {
	font-family: 'Sacramento', cursive;
}
.yesteryear {
	font-family: 'Yesteryear', cursive;
}
.playfair {
	font-family: 'Playfair Display', serif;
}
.jockey-one {
	font-family: 'Jockey One', sans-serif;
}
.pompiere {
	font-family: 'Pompiere', cursive;
}
.raleway {
	font-family: 'Raleway', sans-serif;
}
.six-caps {
	font-family: 'Six Caps', sans-serif;
}
.cinzel-decorative {
	font-family: 'Cinzel Decorative', cursive;
}

.montserrat {
	font-family: 'Montserrat', sans-serif;
}
.libre-baskerville {
	font-family: 'Libre Baskerville', serif;
}



/* line height
 ------------------------------ */
 .line-height-1{
	 line-height:18px;
	}
 .line-height-2{
	 line-height:20px;
	}
 .line-height-3{
	 line-height:30px;
	}
	
 .line-height-4{
	 line-height:40px;
	}
	
 .line-height-5{
	 line-height:50px;
	}
	
 .line-height-6{
	 line-height:60px;
	}


/* letter spacing
 ------------------------------ */
 .lspace-sm{
	letter-spacing:5px;
	}
.lspace-1{
	letter-spacing:10px;
	}
.lspace-2{
	letter-spacing:20px;
	}
.lspace-3{
	letter-spacing:30px;
	}
.lspace-4{
	letter-spacing:40px;
	}
.lspace-5{
	letter-spacing:50px;
	}
	
	
/* font weight styles
 ------------------------------ */
 
.font-weight-1 {
	font-weight: 100;
}
.font-weight-2 {
	font-weight: 200;
}
.font-weight-3 {
	font-weight: 300;
}
.font-weight-4 {
	font-weight: 400;
}
.font-weight-5 {
	font-weight: 500;
}
.font-weight-6 {
	font-weight: 600;
}
.font-weight-7 {
	font-weight: 700;
}
.font-weight-8 {
	font-weight: 800;
}
.font-weight-b {
	font-weight: bold;
}
/*=============== Columns without padding ============= */
.no-gutter > [class*='col-'] {
padding-right:0;
padding-left:0;
}

/*=============== site main structure ============= */

.site-wrapper {
    width: 100%;
    margin: 0px auto;
    background-color: #FFF;

}
.wrapper-boxed {
    margin: auto;
    background-color: #FFF;
}

/*=============== image Boxes ============= */

.imgbox-dxlarge, .imgbox-xlarge, .imgbox-large, .imgbox-xmedium, .imgbox-medium, .imgbox-smedium, .imgbox-small, .imgbox-tiny, .imgbox-xtiny {
	padding: 0px;
	margin: 0 auto;
	text-align: center;
	transition: all 0.3s ease-out 0s;
}
.imgbox-dxlarge {
	width: 300px;
	height: 300px;

}
.imgbox-dxlarge-2 {
	width: 200px;
	height: 200px;

}
.imgbox-xlarge {
	width: 135px;
	height: 135px;
}
.imgbox-large {
	width: 120px;
	height: 120px;
}
.imgbox-xmedium {
	width: 110px;
	height: 110px;
}
.imgbox-medium {
	width: 100px;
	height: 100px;
}
.imgbox-smedium {
	width: 90px;
	height: 90px;
}
.imgbox-small {
	width: 80px;
	height: 80px;
}
.imgbox-tiny {
	width: 70px;
	height: 70px;
}
.imgbox-xtiny {
	width: 60px;
	height: 60px;
}

/* icon rounded
 ------------------------------ */
 
.imgbox-dxlarge.round, .imgbox-dxlarge-2.round, .imgbox-xlarge.round, .imgbox-large.round, .imgbox-xmedium.round, .imgbox-medium.round, .imgbox-smedium.round, .imgbox-small.round, .imgbox-tiny.round, .imgbox-xtiny.round {
	border-radius: 100%;
}
/* imgboxes alignments
 ------------------------------ */
 
.imgbox-dxlarge.center, .imgbox-dxlarge-2.center, .imgbox-xlarge.center, .imgbox-large.center, .imgbox-xmedium.center, .imgbox-medium.center, .imgbox-smedium.center, .imgbox-small.center, .imgbox-tiny.center, .imgbox-xtiny.center {
	margin: 0 auto;
}
.imgbox-dxlarge.left, .imgbox-dxlarge-2.left, .imgbox-xlarge.left, .imgbox-large.left, .imgbox-xmedium.left, .imgbox-medium.left, .imgbox-smedium.left, .imgbox-small.left, .imgbox-tiny.left, .imgbox-xtiny.left {
	float: left;
	margin: 0px 20px 0px 0px;
}
.imgbox-dxlarge.right, .imgbox-dxlarge-2.right, .imgbox-xlarge.right, .imgbox-large.right, .imgbox-xmedium.right, .imgbox-medium.right, .imgbox-smedium.right, .imgbox-small.right, .imgbox-tiny.right, .imgbox-xtiny.right {
	float: right;
	margin: 0px 0px 0px 20px;
}

/* imgboxe background colors
 ------------------------------ */	
.imgbox-dxlarge.dark, .imgbox-dxlarge-2.dark, .imgbox-xlarge.dark, .imgbox-large.dark, .imgbox-xmedium.dark, .imgbox-medium.dark, .imgbox-smedium.dark, .imgbox-small.dark, .imgbox-tiny.dark, .imgbox-xtiny.dark {
	color: #fff;
	background-color: #101010;
}
.imgbox-dxlarge.white, .imgbox-dxlarge-2.white, .imgbox-xlarge.white, .imgbox-large.white, .imgbox-xmedium.white, .imgbox-medium.white, .imgbox-smedium.white, .imgbox-small.white, .imgbox-tiny.white, .imgbox-xtiny.white {
	color: #242424;
	background-color: #fff;
}
.imgbox-dxlarge.gray, .imgbox-dxlarge-2.gray, .imgbox-xlarge.gray, .imgbox-large.gray, .imgbox-xmedium.gray, .imgbox-medium.gray, .imgbox-smedium.gray, .imgbox-small.gray, .imgbox-tiny.gray, .imgbox-xtiny.gray {
	color: #fff;
	background-color: #474747;
}

/* imgboxs with stroke
 ------------------------------ */	
.imgbox-dxlarge.outline-gray, .imgbox-dxlarge-2.outline-gray, .imgbox-xlarge.outline-gray, .imgbox-large.outline-gray, .imgbox-xmedium.outline-gray, .imgbox-medium.outline-gray, .imgbox-smedium.outline-gray, .imgbox-small.outline-gray, .imgbox-tiny.outline-gray, .imgbox-xtiny.outline-gray {
	color: #fff;
	background-color: none;
	border: 1px solid #727272;
}
.imgbox-dxlarge.outline-gray-2, .imgbox-dxlarge-2.outline-gray-2, .imgbox-xlarge.outline-gray-2, .imgbox-large.outline-gray-2, .imgbox-xmedium.outline-gray-2, .imgbox-medium.outline-gray-2, .imgbox-smedium.outline-gray-2, .imgbox-small.outline-gray-2, .imgbox-tiny.outline-gray-2, .imgbox-xtiny.outline-gray-2 {
	color: #242424;
	background-color: none;
	border: 1px solid #e4e4e4;
}
.imgbox-dxlarge.outline-white, .imgbox-dxlarge-2.outline-white, .imgbox-xlarge.outline-white, .imgbox-large.outline-white, .imgbox-xmedium.outline-white, .imgbox-medium.outline-white, .imgbox-smedium.outline-white, .imgbox-small.outline-white, .imgbox-tiny.outline-white, .imgbox-xtiny.outline-white {
	color: #fff;
	background-color: none;
	border: 1px solid #fff;
}
.imgbox-dxlarge.outline-dark, .imgbox-dxlarge-2.outline-dark, .imgbox-xlarge.outline-dark, .imgbox-large.outline-dark, .imgbox-xmedium.outline-dark, .imgbox-medium.outline-dark, .imgbox-smedium.outline-dark, .imgbox-small.outline-dark, .imgbox-tiny.outline-dark, .imgbox-xtiny.outline-dark {
	color: #161616;
	background-color: none;
	border: 1px solid #161616;
}
/*=============== Icon Boxes ============= */

.iconbox-dxlarge, .iconbox-xlarge, .iconbox-large, .iconbox-xmedium, .iconbox-medium, .iconbox-smedium, .iconbox-small, .iconbox-tiny, .iconbox-xtiny {
	padding: 0px;
	margin: 0 auto;
	text-align: center;
	transition: all 0.3s ease-out 0s;
}
.iconbox-dxlarge {
	width: 200px;
	height: 200px;
	font-size: 46px;
	color: #242424;
	border: none;
	line-height: 135px;
}
.iconbox-xlarge {
	width: 135px;
	height: 135px;
	font-size: 46px;
	color: #242424;
	line-height: 135px;
}
.iconbox-large {
	width: 120px;
	height: 120px;
	font-size: 46px;
	color: #242424;
	line-height: 114px;
}
.iconbox-xmedium {
	width: 110px;
	height: 110px;
	font-size: 38px;
	color: #242424;
	line-height: 106px;
}
.iconbox-medium {
	width: 100px;
	height: 100px;
	font-size: 32px;
	color: #242424;
	line-height: 100px;
}
.iconbox-smedium {
	width: 90px;
	height: 90px;
	font-size: 26px;
	color: #242424;
	line-height: 90px;
}
.iconbox-small {
	width: 80px;
	height: 80px;
	font-size: 26px;
	color: #242424;
	line-height: 80px;
}
.iconbox-tiny {
	width: 70px;
	height: 70px;
	font-size: 26px;
	color: #242424;
	line-height: 74px;
}
.iconbox-xtiny {
	width: 60px;
	height: 60px;
	font-size: 18px;
	color: #242424;
	line-height: 60px;
}
.digit {
	width: 32px;
	height: 32px;
	text-align: center;
	font-size: 14px;
	color: #fff;
	left: 115px;
	top: 115px;
	line-height: 36px;
	border-radius: 100%;
	background-color: #fd602c;
	transition: all 0.3s ease-out 0s;
}
.iconbox-xlarge.no-lineheight {
	line-height: 0;
}

/* icon rounded
 ------------------------------ */
 
.iconbox-dxlarge.round, .iconbox-xlarge.round, .iconbox-large.round, .iconbox-xmedium.round, .iconbox-medium.round, .iconbox-smedium.round, .iconbox-small.round, .iconbox-tiny.round, .iconbox-xtiny.round {
	border-radius: 100%;
}
/* iconboxes alignments
 ------------------------------ */
 
.iconbox-dxlarge.center, .iconbox-xlarge.center, .iconbox-large.center, .iconbox-xmedium.center, .iconbox-medium.center, .iconbox-smedium.center, .iconbox-small.center, .iconbox-tiny.center, .iconbox-xtiny.center {
	margin: 0 auto;
}
.iconbox-dxlarge.left, .iconbox-xlarge.left, .iconbox-large.left, .iconbox-xmedium.left, .iconbox-medium.left, .iconbox-smedium.left, .iconbox-small.left, .iconbox-tiny.left, .iconbox-xtiny.left {
	float: left;
	margin: 0px 20px 0px 0px;
}
.iconbox-dxlarge.right, .iconbox-xlarge.right, .iconbox-large.right, .iconbox-xmedium.right, .iconbox-medium.right, .iconbox-smedium.right, .iconbox-small.right, .iconbox-tiny.right, .iconbox-xtiny.right {
	float: right;
	margin: 0px 0px 0px 20px;
}

/* iconboxe background colors
 ------------------------------ */	
.iconbox-dxlarge.dark, .iconbox-xlarge.dark, .iconbox-large.dark, .iconbox-xmedium.dark, .iconbox-medium.dark, .iconbox-smedium.dark, .iconbox-small.dark, .iconbox-tiny.dark, .iconbox-xtiny.dark {
	color: #fff;
	background-color: #101010;
}
.iconbox-dxlarge.white, .iconbox-xlarge.white, .iconbox-large.white, .iconbox-xmedium.white, .iconbox-medium.white, .iconbox-smedium.white, .iconbox-small.white, .iconbox-tiny.white, .iconbox-xtiny.white {
	color: #242424;
	background-color: #fff;
}
.iconbox-dxlarge.gray, .iconbox-xlarge.gray, .iconbox-large.gray, .iconbox-xmedium.gray, .iconbox-medium.gray, .iconbox-smedium.gray, .iconbox-small.gray, .iconbox-tiny.gray, .iconbox-xtiny.gray {
	color: #fff;
	background-color: #474747;
}

/* iconboxs with stroke
 ------------------------------ */	
.iconbox-dxlarge.outline-gray, .iconbox-xlarge.outline-gray, .iconbox-large.outline-gray, .iconbox-xmedium.outline-gray, .iconbox-medium.outline-gray, .iconbox-smedium.outline-gray, .iconbox-small.outline-gray, .iconbox-tiny.outline-gray, .iconbox-xtiny.outline-gray {
	color: #fff;
	background-color: none;
	border: 1px solid #727272;
}
.iconbox-dxlarge.outline-gray-2, .iconbox-xlarge.outline-gray-2, .iconbox-large.outline-gray-2, .iconbox-xmedium.outline-gray-2, .iconbox-medium.outline-gray-2, .iconbox-smedium.outline-gray-2, .iconbox-small.outline-gray-2, .iconbox-tiny.outline-gray-2, .iconbox-xtiny.outline-gray-2 {
	color: #242424;
	background-color: none;
	border: 1px solid #e4e4e4;
}
.iconbox-dxlarge.outline-white, .iconbox-xlarge.outline-white, .iconbox-large.outline-white, .iconbox-xmedium.outline-white, .iconbox-medium.outline-white, .iconbox-smedium.outline-white, .iconbox-small.outline-white, .iconbox-tiny.outline-white, .iconbox-xtiny.outline-white {
	color: #fff;
	background-color: none;
	border: 1px solid #fff;
}
.iconbox-dxlarge.outline-dark, .iconbox-xlarge.outline-dark, .iconbox-large.outline-dark, .iconbox-xmedium.outline-dark, .iconbox-medium.outline-dark, .iconbox-smedium.outline-dark, .iconbox-small.outline-dark, .iconbox-tiny.outline-dark, .iconbox-xtiny.outline-dark {
	color: #161616;
	background-color: none;
	border: 1px solid #161616;
}

/* Icons Plain
 ------------------------------ */	
 
.icon-plain-large {
	font-size: 80px;
	color: #242424;
	transition: all 0.3s ease-out 0s;
}
.icon-plain-medium {
	font-size: 60px;
	color: #242424;
	transition: all 0.3s ease-out 0s;
}
.icon-plain-small {
	font-size: 40px;
	color: #242424;
	transition: all 0.3s ease-out 0s;
}
.icon-plain-msmall {
	font-size: 32px;
	color: #242424;
	transition: all 0.3s ease-out 0s;
}
.icon-plain-tiny {
	font-size: 20px;
	color: #242424;
	transition: all 0.3s ease-out 0s;
}
.icon-plain-small.rightline {
	border-right: 1px solid #3F3F3F;
}
.icon-plain-medium.rightline {
	margin: 0px 20px 50px 0px;
	border-right: 1px solid #FFC153;
}
.icon-plain-small.dark {
	color: #242424;
}

/* plain icon alignments
 ------------------------------ */
 
.icon-plain-large.center, .icon-plain-medium.center, .icon-plain-small.center, .icon-plain-msmall.center, .icon-plain-tiny.center {
	margin: 0 auto;
}
.icon-plain-large.left, .icon-plain-medium.left, .icon-plain-small.left, .icon-plain-msmall.left, .icon-plain-tiny.left {
	float: left;
	margin: 0px 20px 0px 0px;
}
.icon-plain-large.right, .icon-plain-medium.right, .icon-plain-small.right, .icon-plain-msmall.right, .icon-plain-tiny.right {
	float: right;
	margin: 0px 0px 0px 20px;
}

/* plain icon colors
 ------------------------------ */
 
.icon-plain-large.dark, .icon-plain-medium.dark, .icon-plain-small.dark, .icon-plain-msmall.dark, .icon-plain-tiny.dark {
	color: #242424;
}
.icon-plain-large.white, .icon-plain-medium.white, .icon-plain-small.white, .icon-plain-msmall.white, .icon-plain-tiny.white {
	color: #fff;
}
.icon-plain-large.green, .icon-plain-medium.green, .icon-plain-small.green, .icon-plain-msmall.green, .icon-plain-tiny.green {
	color: #fd602c;
}
.icon-plain-large.gray, .icon-plain-medium.gray, .icon-plain-small.gray, .icon-plain-msmall.gray, .icon-plain-tiny.gray {
	color: #727272;
}

/*============ Text Boxes ============ */
	
.text-box-right {
	display: block;
	padding: 0px 0px 0px 99px;
	margin: 0px;
	text-align: left;
}
.text-box-right.less-padding {
	padding: 0px 0px 0px 65px;
}
.text-box-left {
	display: block;
	padding: 0px 90px 0px 0;
	margin: 0px;
	text-align: right;
}
.item-holder {
	width: 100%;
	padding: 0px;
	margin: 0px;
}
.text-box {
	width: 100%;
	float: left;
}
.text-box.white {
	background-color: #fff;
}
.text-box.primary {
	background-color: #fce206;
}
.text-box.white-transparent-1 {
	background-color:rgba(255, 255, 255, 0.9);
}
.text-box.light {
	background-color: #f5f5f5;
}
.text-box.dark {
	background-color: #242424;
}
.text-box.xdark {
	background-color: #000;
}
.text-box.border-light{
	border: 1px solid #ececec;
}
/* text boxes with borders
 ------------------------------ */
.text-box-right.border, .text-box-left.border, .item-holder.border, .text-box-inner.border, .text-box.border {
	border: 1px solid #f1f1f1;
}
.text-box-right.border-top, .text-box-left.border-top, .item-holder.border-top, .text-box-inner.border-top, .text-box.border-top {
	border-top: 1px solid #f1f1f1;
}
.text-box-right.border-bottom, .text-box-left.border-bottom, .item-holder.border-bottom, .text-box-inner.border-bottom, .text-box.border-bottom {
	border-bottom: 1px solid #f1f1f1;
}
.text-box-right.border-left, .text-box-left.border-left, .item-holder.border-left, .text-box-inner.border-left, .text-box.border-left {
	border-left: 1px solid #f1f1f1;
}
.text-box-right.border-right, .text-box-left.border-right, .item-holder.border-right, .text-box-inner.border-right, .text-box.border-right {
	border-right: 1px solid #f1f1f1;
}

/* text boxe padding
 ------------------------------ */
 
 .text-box.sbox-padd-left {
	padding-left:0px;
}

.text-box.padding-1 {
	padding: 10px;
}
.text-box.padding-2 {
	padding: 20px;
}
.text-box.padding-3 {
	padding: 30px;
}
.text-box.padding-4 {
	padding: 40px;
}
.text-box.padding-5 {
	padding: 50px;
}
.text-box.padding-6 {
	padding: 60px;
}
.text-box.padding-7 {
	padding: 70px;
}
.text-box.padding-8 {
	padding: 80px;
}
.text-box.padding-9 {
	padding: 90px;
}

/* text boxes margin and paddings
 ------------------------------ */
 
 /* left box
 ------------------------------ */
 
.item-holder.marginbottom {
	margin: 0 0 40px 0;
}
.text-box-left.more-padding-1 {
	padding: 0px 120px 0px 0px;
}
.text-box-left.more-padding-2 {
	padding: 0px 150px 0px 0px;
}
.text-box-left.less-padding-1 {
	padding: 0px 70px 0px 0;
}
.text-box-left.less-padding-2 {
	padding: 0px 50px 0px 0;
}
/* right box
 ------------------------------ */
 
.text-box-right.more-padding-1 {
	padding: 0px 0px 0px 120px;
}
.text-box-right.more-padding-2 {
	padding: 0px 0px 0px 140px;
}
.text-box-right.more-padding-3 {
	padding: 0px 0px 0px 160px;
}
.text-box-right.more-padding-4 {
	padding: 0px 0px 0px 170px;
}
.text-box-right.less-padding-1 {
	padding: 0px 0px 0px 70px;
}
.text-box-right.less-padding-2 {
	padding: 0px 0px 0px 50px;
}
.text-box-right.less-padding-3 {
	padding: 0px 0px 0px 40px;
}
.text-box-right.less-padding-4 {
	padding: 0px 0px 0px 80px;
}
/*============ Buttons ============ */

a.read-more {
	color: #fce206;
}
a.read-more:hover {
	color: #101010;
}
a.read-more.white {
	color: #fff;
}
a.read-more.white:hover {
	color: #101010;
}
a.read-more.dark {
	color: #101010;
}
a.read-more.dark:hover {
	color: #fce206;
}
a.read-more.dark-2 {
	color: #101010;
}
a.read-more.dark-2:hover {
	color: #727272;
}
a.read-more.primary {
	color: #fce206;
}
a.read-more.primary:hover {
	color: #101010;
}

/* button styles
 ------------------------------ */
 
.btn {
	padding: 10px 36px;
	margin: 0px;
	box-shadow: none;
	border-radius: 0;
}
.btn.btn-large {
	padding: 15px 60px;
}
.btn.btn-medium {
	padding: 10px 30px;
}
.btn.btn-small {
	padding: 8px 18px;
}
.btn.btn-small-2 {
	padding: 4px 18px;
}
.btn.btn-fullwidth {
	width:100%;
}
.btn.btn-half-fullwidth {
	width:50%;
}
/* button radius
 ------------------------------ */ 
.btn.btn-xround {
	border-radius: 20px;
}
.btn.btn-xround-2 {
	border-radius: 30px;
}
.btn.btn-round {
	border-radius: 5px;
}
.btn.top-margin {
	margin-top: 20px;
}

/* button right margins
 ------------------------------ */ 
.btn.btn-mar-right-1 {
	margin-right:10px
}
.btn.btn-mar-right-2 {
	margin-right:20px
}
.btn.btn-mar-right-3 {
	margin-right:30px
}
.btn.btn-mar-right-4 {
	margin-right:40px
}
.btn.btn-mar-right-5 {
	margin-right:50px
}
/* button left margins
 ------------------------------ */ 
.btn.btn-mar-left-1 {
	margin-left:10px
}
.btn.btn-mar-left-2 {
	margin-left:20px
}
.btn.btn-mar-left-3 {
	margin-left:30px
}
.btn.btn-mar-left-4 {
	margin-left:40px
}
.btn.btn-mar-left-5 {
	margin-left:50px
}


/* button background colors
 ------------------------------ */

/* button white
 ------------------------------ */
.btn.btn-white {
	color: #101010;
	background-color: #fff;
}
.btn.btn-white:hover {
	color: #fff;
	background-color: #101010;
}
/* button white transparent
 ------------------------------ */
.btn.btn-white-tr {
	color: #101010;
	background-color: rgba(255, 255, 255, 0.6);
}
.btn.btn-white-tr:hover {
	color: #101010;
	background-color: #fff;
}

/* button dark
 ------------------------------ */
.btn.btn-dark {
	color: #fff;
	background-color: #101010;
}
.btn.btn-dark:hover {
	color: #fff;
	background-color: #3e3e3e;
}
/* button dark-2
 ------------------------------ */
.btn.btn-dark-2 {
	color: #fff;
	background-color: #161616;
}
.btn.btn-dark-2:hover {
	color: #161616;
	background-color: #fff;
}
/* button dark-3
 ------------------------------ */
.btn.btn-dark-3 {
	color: #727272;
	background-color: #101010;
}
.btn.btn-dark-3:hover {
	color: #727272;
	background-color: #242424;
}
/* button light
 ------------------------------ */
.btn.btn-light {
	color: #101010;
	background-color: #f5f5f5;
}
.btn.btn-light:hover {
	color: #727272;
	background-color: #101010;
}
/* button golden yellow
 ------------------------------ */
.btn.btn-prim {
	color: #fff;
	background-color: #fce206;
}
.btn.btn-prim:hover {
	color: #fff;
	background-color: #101010;
}

.btn.text-dark {
	color: #101010;
}
/* button with border
 ------------------------------ */
.btn.btn-border {
	color: #242424;
	border: 2px solid;
}
.btn.btn-border.less-bor {
	border: 1px solid;
}
.btn.btn-border.border-1x {
	border: 1px solid;
}
.btn.btn-border.border-2x {
	border: 2px solid;
}
.btn.btn-border.border-3x {
	border: 3px solid;
}
.btn.btn-border.border-4x {
	border: 4px solid;
}



/* button border white
 ------------------------------ */
.btn.btn-border.white {
	color: #fff;
	border-color:#fff;
}
.btn.btn-border:hover.white {
	color:#242424;
	background-color:#fff;
	border-color:#fff;
}
/* button border dark
 ------------------------------ */
.btn.btn-border.dark {
	border-color:#101010;
}
.btn.btn-border:hover.dark {
	color:#fff;
	background-color:#101010;
	border-color:#101010;
}

/* button border dark
 ------------------------------ */
.btn.btn-border.dark-2 {
	color:#fff;
	border-color:#545454;
}
.btn.btn-border:hover.dark-2 {
	color:#101010;
	background-color:#fff;
	border-color:#fff;
}

/* button border light
 ------------------------------ */
.btn.btn-border.light {
	border-color:#e4e4e4;
}
.btn.btn-border:hover.light {
	color:#fff;
	background-color:#101010;
	border-color:#101010;
}

/* button border extra light
 ------------------------------ */
.btn.btn-border.xlight {
	border-color:#f5f5f5;
}
.btn.btn-border:hover.xlight {
	color:#727272;
	background-color:#101010;
	border-color:#101010;
}

/* button border orange-2
 ------------------------------ */
.btn.btn-border.prim {
	color:#fce206;
	border-color:#fce206;
}
.btn.btn-border:hover.prim {
	color:#fff;
	background-color:#fce206;
	border-color:#fce206;
}

/* button border gray
 ------------------------------ */
.btn.btn-border.gray {
	color:#fff;
	border-color:#727272;
}
.btn.btn-border:hover.gray {
	color:#fff;
	background-color:#5c5b5b;
	border-color:#5c5b5b;
}

/* button border gray-2
 ------------------------------ */
.btn.btn-border.gray-2 {
	color:#101010;
	border-color:#727272;
}
.btn.btn-border:hover.gray-2 {
	color:#fff;
	background-color:#5c5b5b;
	border-color:#5c5b5b;
}

/* button border xdark
 ------------------------------ */
.btn.btn-border.xdark {
	color:#161616;
	border-color:#161616;
}
.btn.btn-border:hover.xdark {
	color:#fff;
	background-color:#161616;
	border-color:#161616;
}

/* button border white on hover golden yellow
 ------------------------------ */
.btn.btn-border.white.gyellow {
	color:#fff;
	border-color:#fff;
}
.btn.btn-border:hover.white.gyellow {
	color:#fff;
	background-color:#fce206;
	border-color:#fce206;
}

/* Appstore buttons
 ------------------------------ */ 

.btn.appstore {
	width: 200px;
	padding: 10px 25px;
	margin: 0px;
	text-align: left;
}
.btn.appstore.right-margin {
	margin-right: 10px;
}
.btn.appstore.gyellow {
	background-color: #fce206;
}
.btn.appstore span {
	margin: 0px;
	float: left;
	padding: 0;
	text-align: left;
}
.btn.appstore span i {
	font-size: 40px;
	color: #fff;
	padding: 0 20px 0 0;
}
.btn.appstore p {
	font-size: 12px;
	display: block;
	margin: 0;
	padding: 0 25px 0 20px;
	text-align: left;
}
.btn.appstore .big {
	margin: 0px;
	font-size: 18px;
	padding: 0;
}
.btn.appstore.gyellow:hover {
	background-color: #fff;
}
.btn.appstore.gyellow:hover p, .btn.appstore.gyellow:hover .big, .btn.appstore.gyellow:hover span i {
	color: #101010;
}
/* Appstore button white
 ------------------------------ */
.btn.appstore.white {
	background-color: #fff;
}
.btn.appstore.white span i {
	font-size: 40px;
	color: #101010;
	padding: 0 20px 0 0;
}
.btn.appstore.white:hover {
	background-color: #fce206;
}
.btn.appstore.white:hover p, .btn.appstore.white:hover .big, .btn.appstore.white:hover span i {
	color: #fff;
}


/*============ Image Boxes ============ */

.section-side-image {
	position: relative;
	padding: 0px;
	margin: 0px;
}
.section-side-image .img-holder {
	overflow: hidden;
	height: 100%;
	padding: 0px;
	top: 0px;
	position: absolute;
}
.background-imgholder.top-padd {
	padding-top: 80px;
}
.section-side-image .text-inner {
	padding: 120px 120px 120px 0;
}
.section-side-image .text-inner-2 {
	padding: 80px;
}
.section-side-image .text-inner-3 {
	padding: 80px 80px 0px 80px;
}
.section-side-image .text-inner.two {
	padding: 0px 80px 0px 0;
}
.section-side-image .text-inner-4 {
	padding: 0 0 0 0;
}
.section-side-image .text-inner-5 {
	padding: 120px 120px 120px 0;
}
.section-side-image .text-inner-6 {
	padding: 110px;
}
.background-imgholder {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 0;
	background-size: cover !important;
	background-position: 50% 50% !important;
}

/* side image 2
 ------------------------------ */
.section-side-image-2 {
	position: relative;
	padding: 0px;
	margin: 0px;
}
.section-side-image-2 .img-holder {
	overflow: hidden;
	height: 100%;
	padding: 0px;
	top: 0px;
	position: absolute;
}
.section-side-image-2 .background-imgholder-2.top-padd {
	padding-top: 80px;
}
.section-side-image-2 .text-inner.two {
	padding: 0px 80px 0px 0;
}
.section-side-image-2 .background-imgholder-2 {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 0;
	background-size: cover !important;
	background-position: 50% 50% !important;
}
.image-holder {
	width: 100%;
	float: left;
}
.image-holder.less-width-1 {
    width: 80%;
}
.image-holder.less-width-2 {
    width: 75%;
}
.image-holder.less-width-3 {
    width: 70%;
}
.image-left {
    color: #fff;
    float: left;
    margin: 0;
    padding: 0 20px 0 0;
    transition: all 0.3s ease-out 0s;
}
.img-align-left {
	float: left;
	margin-right: 20px;
	margin-bottom: 20px;
}
.nodisplay-image {
	display: none;
}
/* image overflow hidden
 ------------------------------ */
.overflow-hidden {
	overflow: hidden;
}

/*============ Lists ============ */

.iconlist {
	width: 100%;
	padding: 0px;
	margin: 0px;
}
.iconlist li {
	padding: 0px;
	margin: 0 0 15px 0;
	display: block;
	line-height: 23px;
}
.iconlist li i {
	color: #fce206;
	padding: 0 15px 0 0;
}
.iconlist.gyellow li i {
	color: #fce206;
	padding: 0 15px 0 0;
}
.iconlist.dark li i {
	color: #101010;
}
.iconlist.less-margin li {
	margin: 0 0 13px 0;
}
.iconlist.white li i {
	color: #fff;
}
.iconlist.white-2 li {
	color: #fff;
}
.iconlist.medium li i {
	color: #727272;
}


/* icon list style 2
 ------------------------------ */
.iconlist-2 {
	width: 100%;
	padding: 0px;
	margin: 0px 0px 10px 0px;
	float: left;
}
.iconlist-2 .icon {
	width: 18px;
	height: 18px;
	padding: 0px;
	margin: 3px 0px 0px 0px;
	float: left;
	font-size: 14px;
	text-align: center;
	line-height: 15px;
	color: #cc0000;
	border-radius: 100%;
}
.iconlist-2 .text {
	padding: 0px 0 0 28px;
	margin: 0px;
	display: block;
}

.iconlist-2 .icon.dark {
	color: #101010;
}
.iconlist-2 .icon.light {
	color: #f5f5f5;
}
.iconlist-2 .icon.white {
	color: #fff;
}
.iconlist-2 .icon.gyellow {
	color: #fce206;
}
/* =========== client logos ============= */

.clients-list.grid-cols-2 li {
    width: 50%;
}
.clients-list.grid-cols-3 li {
    width: 33.33%;
}
.clients-list.grid-cols-4 li {
    width: 25%;
}
.clients-list.grid-cols-5 li {
    width: 20%;
}
.clients-list.grid-cols-6 li {
    width: 16.66%;
}

.clients-list {
	padding-left:0px;
    overflow: hidden;
}
.clients-list li {
	position: relative;
	width: 20%;
    float: left;
    padding: 25px 0;
	transition: all 0.3s ease-out 0s;   
}
.clients-list li a img {
    width: 100%;
}
.clients-list li a, .clients-list li img {
    display: block;
    margin:0 auto;
    width: 75%;
}
.clients-list li::before, .clients-list li::after {
    content: "";
    position: absolute;
}
.clients-list li::before {
    height: 100%;
	top: 0;
    left: -1px;
	border-left: 1px solid #e9e9e9;
}
.clients-list li::after{
    width: 100%;
	height: 0;    
    left: 0;
    top: auto;
    bottom: -1px;
	border-bottom: 1px solid #e9e9e9;
}
.clients-list.border-dark li::before {
	border-left: 1px solid #333333;
}
.clients-list.border-dark li::after{
	border-bottom: 1px solid #333333;
}
.clients-list.noborder li::after{
	border-bottom: none;
}
.clients-list.noborder li::before{
	border-left: none;
}
/* logo hover styles
 ------------------------------ */
 
.clients-list.hover-1 li:hover {
	background-color:#f5f5f5;
}
.clients-list.hover-2 li:hover {
	background-color:#f5f5f5;
}
.clients-list.hover-3 li:hover {
	background-color:#f5f5f5;
}
.clients-list.hover-4 li a {
	opacity:0.4;
}
.clients-list.hover-4 li a:hover {
	opacity:1;
}
.clients-list.hover-5 li a {
	opacity:0.8;
}
.clients-list.hover-5 li a:hover {
	opacity:1;
}
.clients-list.hover-6 li {
	opacity:0.8;
}
.clients-list.hover-6 li:hover {
	background-color:#f5f5f5;
}
/* side navigation list
 ------------------------------ */
.side-nav-list {
	width: 100%;
	padding: 0px;
	margin: 0px;
	float: left;
}
.side-nav-list li {
	width: 100%;
	padding: 0px;
	margin: 0 0 2px 0;
	float: left;
}
.side-nav-list li a {
	width: 100%;
	padding: 15px 25px;
	margin: 0px;
	font-weight: normal;
	float: left;
	background-color: #f6f6f6;
}
.side-nav-list li a:hover, .side-nav-list li a.active {
	color: #fff;
	background-color: #fce206;
}
/* side navigation list
 ------------------------------ */
.category-links {
	width: 100%;
	padding: 0px;
	margin: 0px;
	float: left;
}
.category-links li {
	width: 100%;
	padding: 10px 0;
	margin: 0 0 0 0;
	float: left;
	border-bottom: 1px solid #eeeeee;
}
.category-links li a:hover, .category-links li a.active {
	color: #fce206;
}
.category-links li:last-child {
	border-bottom: none;
}

/* blog1 tags
 ------------------------------ */	
.tags {
	padding: 0px;
	margin: 0px;
}
.tags li {
	padding: 0px;
	margin: 0;
}
.tags li a {
	padding: 5px 15px 5px 15px;
	margin: 0 10px 10px 0;
	float: left;
	color: #727272;
	background-color: #e7e7e7;
}
.tags li a:hover, .tags li a.active {
	color: #fff;
	background-color: #dbdc33;
}

.tags.two li a {
	color: #242424;
	background-color: #fff;
	border:1px solid #e4e4e4;
}
.tags.two li a:hover, .tags.two li a.active {
	color: #fff;
	background-color: #42d1aa;
	border:1px solid #42d1aa;
}

/*tags 2
 ------------------------------ */	
.tags-2 {
	padding: 0px;
	margin: 0px;
}
.tags-2 li {
	padding: 0px;
	margin: 0;
}
.tags-2 li a {
	padding: 5px 15px 5px 15px;
	margin: 0 10px 10px 0;
	float: left;
	color: #727272;
	border:1px solid #e4e4e4;
	background-color: #fff;
}
.tags-2 li a:hover, .tags-2 li a.active {
	color: #fff;
	border:1px solid #fce206;
	background-color: #fce206;
}


/* sidebar works list
 ------------------------------ */	
.sidebar-works {
	width: 100%;
	padding: 0px;
	margin: 0px;
}
.sidebar-works li {
	width:32%;
	padding: 0px;
	margin: 4px 4px 0 0;
	float: left;
}
.sidebar-works li a img {
	width:100%;
}
.sidebar-works li:last-child {
	margin: 4px 0 0 0;
}

/*============ Menu Backgrounds ============ */

/* Sticky bg
 ------------------------------ */
#header {
	position: relative;
	background: #101010;
	padding: 0;
	width: 100%;
	z-index: 999;
}


/*============ Topbar Styles ============ */

/* Topbar
 ------------------------------ */
 
.topbar {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0px;
}
.topbar.white {
	background-color: #fff;
	border-bottom: 1px solid #efefef;
}
.topbar.light {
	background-color: #f6f6f6;
}
.topbar.dark {
	background-color: #101010;
}
.topbar.fulldark {
	background-color: #000;
	border-bottom: 1px solid #262626;
}
.topbar.gyellow {
	background-color: #fce206;
}
.topbar.more-padding {
	padding: 20px 0 80px 0;
}

.topbar-transparent {
	position:relative;
	float: left;
	width: 100%;
	padding: 0;
	margin: 0px;
	z-index:99;
}
.topbar-transparent.min-height {
	min-height:120px;
}
.topbar-transparent.dark {
	background-color:#101010;
}
.topbar-transparent.bborder {
	border-bottom:1px solid rgba(255, 255, 255, 0.2);
}
.topbar-transparent.light {
	background-color:#f5f5f5;
}
.topbar-transparent.dark .toplist li a:hover {
    color: #fff;
}
.topbar-transparent.text-white .toplist li, .topbar-transparent.text-white .toplist li a {
    color: #fff;
}

.topbar.no-boder {
	border-bottom:none;
}
.topbar.more-padd-1 {
	padding: 25px 0;
}

.topbar .border-r {
	border-right:1px solid #e5e5e5;
}
.topbar .padd-left {
	padding-left:30px;
}
/* Topbar list
 ------------------------------ */
.toplist {
	float: right;
	padding: 0px;
	margin: 0px;
}
.toplist li {
	float: left;
	color: #8d8c8c;
	text-align: right;
	font-size: 14px;
	margin: 0px;
	padding: 0 10px 0 10px;
}
.toplist li.lineright {
	border-right: 1px solid #e4e4e4;
}
.toplist li a {
	color: #8d8c8c;
}
.toplist li a:hover {
	color: #101010;
}
.toplist.toppadding {
	padding-top: 10px;
}
a.topbar-btn {
	float: left;
	color: #101010;
	text-align: center;
	font-size: 14px;
	margin: 0px;
	border-radius: 2px;
	padding: 5px 15px;
	background-color: #fff;
}
.topbar-padding {
	padding: 7px 0 15px 0;
}
.topbar-left-items {
	float: left;
	margin: 0;
	padding: 0;
	width: 37%;
}
.topbar-middle-logo {
	float: left;
	margin: 0;
	padding: 10px 0;
	width: 26%;
	text-align: center;
	background-color: #fce206;
}
.topbar-middle-logo.no-bgcolor {
	background:none;
}
.topbar-right-items {
	float: left;
	margin: 0;
	padding: 0;
	width: 37%;
}

/* topbar dark list color
 ------------------------------ */
.topbar.dark .toplist li a:hover {
	color: #fff;
}
.topbar.fulldark .toplist li a:hover {
	color: #fff;
}
.topbar.dark .toplist li.lineright {
	border-right: 1px solid #727272;
}
.topbar.fulldark .toplist li.lineright {
	border-right: 1px solid #3a3a3a;
}
.topbar.white .toplist li a:hover {
	color: #101010;
}
/* topbar logo middle
 ------------------------------ */
.topbar-middle-logo.nobg {
	background: none;
}
/* topbar btn
 ------------------------------ */
a.topbar-btn:hover, a.topbar-btn.active {
	color: #FFF !important;
	background-color: #fce206;
}

/* topbar white top list color
 ------------------------------ */
.topbar.white .toplist li a {
	color: #727272;
}
.topbar.white .toplist li {
	color: #727272;
}
/* topbar orange toplist color
 ------------------------------ */
.topbar.gyellow .toplist li {
	color: #fce206;
}
.topbar.gyellow .toplist li a {
	color: #fce206;
}
/* topbar right items two
 ------------------------------ */
.topbar-right-items.two {
	width: 50%;
}

/*============ Section Titles ============ */

/* title container
 ------------------------------ */
 
.title{
	transition: all 0.3s ease-out 0s;
}

 /* title container
 ------------------------------ */

.sec-title-container {
	width:100%;
	float:left;
	padding-bottom:120px;
}
.sec-title-container.less-padding-1 {
	padding-bottom:60px;
}
.sec-title-container.less-padding-2 {
	padding-bottom:50px;
}
.sec-title-container.less-padding-3 {
	padding-bottom:40px;
}
.sec-title-container.less-padding-4 {
	padding-bottom:30px;
}
.sec-title-container.less-padding-5 {
	padding-bottom:20px;
}
.sec-title-container.less-padding-6 {
	padding-bottom:15px;
}
/* page title
 ------------------------------ */
.section-title {
	font-weight: 400;
}
/* subtitle
 ------------------------------ */
.sub-title {
	width: 48%;
	font-size: 16px;
	margin: 0 auto 70px auto;
}
/* title line
 ------------------------------ */
.title-line-1 {
	width: 125px;
	height:4px;
	margin:12px auto 0 auto;
	border-bottom:1px solid #e7e7e7;
	border-top:1px solid #e7e7e7;
}
.title-line-1.align-left {
	width: 125px;
	margin:12px auto 0 0;
}
.title-line-1.transp {
	border-bottom:1px solid rgba(255, 255, 255, 0.3);
	border-top:1px solid rgba(255, 255, 255, 0.3);
}
.title-line-2 {
	width:260px;
	height:1px;
	margin:12px auto 0 auto;
	background-color:#e7e7e7;
}
.title-line-2.align-left {
	width: 260px;
	margin:12px auto 0 0;
}
/* featurebox title line
 ------------------------------ */
.feabox-title-line {
	width:60px;
	height:2px;
	margin:0 auto 0 0;
	background-color:#101010;
}
.feabox-title-line.center {
	width:60px;
	height:2px;
	margin:0 auto;
	background-color:#101010;
}

/*============ Footer Styles Sections ============ */

 /* footer section
 ------------------------------ */ 
 .fo-map {
	width: 100%;
	float:left;
}
 .fo-map {
	background-size:100%;
}
.fo-sec-1 {
	position:relative;
	width: 100%;
	padding: 110px 0 0 0;
	margin:0;
	float: left;
}
.fo-sec-1 .newsletter-box{
	position: absolute;
	width:100%;
	float:left;
	left:0px;
	top:-238px;
	padding:70px;
	background-color:#fce206;
}
.fo-sec-1 .newsletter-box input{
	width:80%;
	height:60px;
	float:left;
	padding:15px;
	border:1px solid #fff;
	background-color:#fff;
}
.fo-sec-1 .newsletter-box .submit-btn{
	width:20%;
	height:60px;
	float:left;
	padding:0px;
	border:1px solid #101010;
	background-color:#101010;
}

/* footer section 2
 ------------------------------ */ 
.fo-sec-2 {
	position:relative;
	width: 100%;
	padding: 30px 0 0 0;
	margin:0;
	float: left;
}
.fo-sec-2 .social-iconbox {
	position: absolute;
	width:265px;
	float:left;
	left:40%;
	top:-107px;
	padding: 0px;
	margin: 0px;
	z-index:2;
	background-color:#fff;
}
.fo-sec-2 .social-iconbox .side-shape1 {
	position: absolute;
	width:13px;
	height:28px;
	float:left;
	left: -13px;
	top: -1px;
	padding: 0px;
	margin: 0px;
}
.fo-sec-2 .social-iconbox .side-shape1.right-icon {
	left: 265px;
	top: -1px;
}
.fo-sec-2 ul.sc-icons{
	width:100%;
	margin:0px;
	padding:0px;
	text-align:center;
}
.fo-sec-2 .sc-icons li{
	display:inline-block;
}
.fo-sec-2 .sc-icons li a{
	padding:15px 10px;
	display:inline-block;
}

 /* footer section 3
 ------------------------------ */ 
.fo-sec-3 {
	position:relative;
	width: 100%;
	padding: 110px 0 0 0;
	margin:0;
	float: left;
}
.fo-sec-3 .primary-box{
	position: absolute;
	width:100%;
	float:left;
	left:0px;
	top:-150px;
	padding:50px;
	background-color:#fce206;
}


 /* footer latest posts
 ------------------------------ */ 
.fo-posts {
	width: 100%;
	padding: 0px;
	margin: 0 0 25px 0;
	float: left;
}
.fo-posts .post-info {
	font-size: 12px;
	color: #727272;
}
.fo-posts-info {
	font-size: 12px;
	color: #727272;
}
.fo-posts a:hover, .fo-posts a.active {
	color: #fce206;
}
.fo-posts .post-info.text-light {
	color: #d0d0d0;
}
 /* footer title bottom line
 ------------------------------ */ 
.fo-title-bottom-line {
    width: 34px;
    height: 2px;
    color: #fff;
    float: left;
    display: block;
    margin-bottom: 40px;
    background-color: #fff;
} 
.fo-title-bottom-line.dark {
    background-color: #101010;
}
.fo-title-bottom-line.white {
    background-color: #fff;
}
.fo-title-bottom-line.gyellow {
    background-color: #fce206;
} 

 /* footer newsletter
 ------------------------------ */ 
.fo-newsletter-1 {
	border: 1px solid #3b3b3b;
	border-width: 1px 0 1px 1px;
	color: #727272;
	float: left;
	font-size: 13px;
	font-weight: normal;
	height: 50px;
	line-height: 39px;
	padding: 0 7px;
	width: 70%;
	background-color:transparent;
}
.fo-newsletter-submit-1 {
	background-color: #3b3b3b;
	border: 1px solid #3b3b3b;
	float: left;
	height: 50px;
	margin: 0;
	padding: 5px 10px;
}
.fo-newsletter-submit-1.gyellow {
	color:#fff;
	background-color: #fce206;
	border: 1px solid #fce206;
}
 /* footer newsletter 2
 ------------------------------ */ 
.fo-newsletter-2 {
	border: 1px solid #101010;
	border-width: 1px 0 1px 1px;
	color: #727272;
	float: left;
	font-size: 13px;
	font-weight: normal;
	height: 50px;
	line-height: 39px;
	padding: 0 7px;
	width: 66%;
	background-color:transparent;
}
.fo-newsletter-submit-2 {
	background-color: #101010;
	border: 1px solid #101010;
	float: left;
	height: 50px;
	margin: 0;
	padding: 5px 20px;
}

/* footer appointment form
 ------------------------------ */ 
.fo-appointment-form {
	padding: 0px;
	width: 100%;
	float:left;
}
.fo-appointment-form input {
	border: 1px solid #212121;
	color: #727272;
	float: left;
	font-size: 13px;
	font-weight: normal;
	height: 35px;
	line-height: 39px;
	padding: 0 7px;
	width: 100%;
	margin:0 0 15px 0;
	background-color:transparent;
}
.fo-appointment-form textarea {
	border: 1px solid #212121;
	color: #727272;
	float: left;
	font-size: 13px;
	font-weight: normal;
	height: 70px;
	min-height:70px;
	resize: none;
	line-height: 39px;
	padding: 0 7px;
	width: 100%;
	margin:0 0 15px 0;
	background-color:transparent;
}
.fo-appointment-form .submit-btn {
    background-color: #fce206;
    border: 1px solid #fce206;
    float: left;
    height: 40px;
    margin: 0;
	color:#fff;
    padding: 0 10px;
}
/* Footer title
 ------------------------------ */
 
.footer-title {
	color: #fff;
	float: left;
}
.footer-title-bottomstrip {
	width: 10%;
	height: 3px;
	margin-right: 95%;
	color: #fff;
	float: left;
	display: block;
	margin-bottom: 40px;
	background-color: #fff;
}
.footer-title-bottomstrip.gyellow {
	background-color: #fce206;
}
.footer-title-bottomstrip-2 {
	width: 34px;
	height: 2px;
	color: #fff;
	float: left;
	display: block;
	margin-bottom: 40px;
	background-color: #fff;
}
.footer-title-bottomstrip-3 {
	width: 34px;
	height: 2px;
	color: #fff;
	float: left;
	display: block;
	margin-bottom: 30px;
	background-color: #101010;
}
.footer-title-bottomstrip.dark {
	background-color: #101010;
}
.footer-title-bottomstrip-2.dark {
	background-color: #101010;
}
.footer-title-bottomstrip-2.gyellow {
	background-color: #fce206;
}
/* ---------- Footer quick links ---------- */

.footer-quick-links {
	width: 100%;
	padding: 0 0 0 0;
	margin: 0px;
}
.footer-quick-links li {
	padding: 25px 15px;
	margin: 40px 0 10px 0;
	display: inline-block;
}
.footer-quick-links li a {
	padding:3px 0;
	margin:0;
	color: #727272;
	float: left;
	display: inline-block;
}
.footer-quick-links li a:hover {
	color: #fff;
}
.footer-quick-links li a i {
	padding:0 10px 0 0;
}
/* ---------- Footer quick links ---------- */

.footer-quick-links-2 {
	width: 100%;
	padding: 0 0 0 0;
	margin: 0px;
}
.footer-quick-links-2 li {
	padding: 0px;
	margin: 0px;
	display: inline-block;
}
.footer-quick-links-2 li a {
	padding: 0 35px 20px 0;
	margin:0;
	color: #727272;
	float: left;
	display: inline-block;
}
.footer-quick-links-2 li a:hover {
	color: #fff;
}
.footer-quick-links-2 li a i {
	padding:0 10px 0 0;
}
.footer-quick-links-2.top-padding {
    padding: 60px 0 0 0;
}
/* ---------- Footer quick links 3 ---------- */

.footer-quick-links-3 {
	width: 100%;
	padding: 0 0 0 0;
	margin: 0px;
	display:block;
}
.footer-quick-links-3 li {
	width: 100%;
	padding: 0px;
	margin: 0px;
	float:left;
	display:block;
}
.footer-quick-links-3 li a {
	padding:3px 0;
	margin:0;
	color: #727272;
	float: left;
	display:block;
}
.footer-quick-links-3 li a:hover {
	color: #101010;
}
.footer-quick-links-3 li a i {
	padding:0 10px 0 0;
}
.footer-quick-links-3.top-padding {
    padding: 60px 0 0 0;
}

/* ---------- Footer quick links 4---------- */

.footer-quick-links-4 {
	width: 100%;
	padding: 0 0 0 0;
	margin: 0px;
}
.footer-quick-links-4 li {
	width:100%;
	padding:0px;
	margin:0;
	display: inline-block;
}
.footer-quick-links-4 li a {
	padding:3px 0;
	margin:0;
	color: #727272;
	float: left;
	display: inline-block;
}
.footer-quick-links-4 li a:hover {
	color: #fff;
}
.footer-quick-links-4 li a i {
	padding:0 10px 0 0;
}
.footer-quick-links-4.dark-hover li a:hover {
	color: #101010;
}

.footer-quick-links-4.hover-dark li a:hover {
	color: #101010;
}

.footer-quick-links-5 {
	padding:0px;
	margin:0px;
}
.footer-quick-links-5 li {
	padding:5px 0;
}
.footer-quick-links-5 li a {
	color: #000;
}
.footer-quick-links-5 li a:hover {
	color: #727272;
}


/* ---------- Footer social links ---------- */
.footer-social-icons {
    width: 100%;
    padding: 0px;
	margin:15px 0 15px 0;
}
.footer-social-icons li {
    padding: 0px;
	margin: 0px;
	display: inline-block;
}
.footer-social-icons li a {
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    padding: 0px;
    color: #fff;
    background-color: #101010;
}
.footer-social-icons.round li a {
	border-radius:100%;
}
.footer-social-icons li a:hover, .footer-social-icons li a.active {
    color: #fce206;
}
.footer-social-icons.icons-plain li a {
    background: none;
}

.footer-social-icons.dark li a {
    color: #101010;
	border-radius:100%;
    background-color: #fff;
}
.footer-social-icons.dark li a:hover, .footer-social-icons.dark li a.active {
    color: #fce206;
}


.footer-social-icons.primary li a {
    color: #101010;
	border-radius:100%;
	margin-top:20px;
    background-color: #fce206;
}
.footer-social-icons.primary.left-align {
	text-align:left;	
}
.footer-social-icons.left-align {
	text-align:left;	
}
/* ---------- Footer social links 2 ---------- */
.footer-social-icons-2 {
    width: 100%;
    padding: 0px;
	text-align: center;
	margin:20px 0 20px 0;
}
.footer-social-icons-2 li {
    padding: 0px;
	margin: 0px;
	display: inline-block;
}
.footer-social-icons-2 li a {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    padding: 0px;
	font-size:16px;
    color: #101010;
	border:1px solid #e4e4e4;
    background-color: #fff;
}
.footer-social-icons-2 li a:hover {
    color: #fff;
	border:1px solid #fce206;
    background-color: #fce206;
}
.footer-social-icons-2 li a:hover, .footer-social-icons-2 li a.active {
    color: #fff;
	border:1px solid #fce206;
    background-color: #fce206;
}
.footer-social-icons-2.dark-hover li a:hover, .footer-social-icons-2.dark-hover li a.active {
    color: #fff;
	border:1px solid #101010;
    background-color: #101010;
}

/* ---------- Footer flickr gallery ---------- */

ul.footer-flickr {
	width: 100%;
	padding:0;
	margin: 0px;
}
.footer-flickr li {
	width:80px;
	padding:0;
	margin:0 0 11px 0;
	float:left;
}
.footer-flickr li.mar-r {
	margin-right:11px;
}

/* ---------- Footer opening list ---------- */
.fo-opening-list {
	float: left;
	padding: 0;
	width: 100%;
}
.fo-opening-list li {
	color: #727272;
	float: left;
	padding: 8px 0;
	width: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.fo-opening-list li:first-child {
	padding-top: 0px;
}
.fo-opening-list li:last-child {
	border-bottom: none;
}

/*============ css arrows ============ */

/*.arrow-down {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #fd602c;
}
.arrow-up {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid black;
}
.arrow-right {
	width: 0;
	height: 0;
	border-top: 60px solid transparent;
	border-bottom: 60px solid transparent;
	border-left: 60px solid green;
}
.arrow-left {
	width: 0;
	height: 0;
	border-top: 20px solid transparent;
	border-bottom: 20px solid transparent;
	border-right: 20px solid #fff;
}*/
/*============ section background images ============ */
.sec-bgimg-1 {
	width: 100%;
	float: left;
	padding: 0;
	margin: 0px;
	text-align: center;
	background-position: 100% 0;
	background-position: center;
	z-index: 1;
}

/*============ Page Section Styles ============ */

/* section background dark
 ------------------------------ */
.section-dark {
	background-color: #000;
}
.section-medium-dark {
	background-color: #161616;
}
.section-fulldark {
	background-color: #000;
}
.section-medium-dark-2 {
	background-color: #1c1c1c;
}
.section-medium-dark-3 {
	background-color: #101010;
}

/* section background light
 ------------------------------ */
.section-light {
	background-color: #f5f5f5;
}

.section-light-2 {
	background-color: #f9f8f7;
}

/* section background white
 ------------------------------ */
.section-white {
	background-color: #fff;
}

/* section background golden yellow
 ------------------------------ */
.section-primary {
	background-color: #fce206;
}

/* section copyright
 ------------------------------ */
.section-copyrights {
	margin: 0;
	text-align: center;
	background-color: #1a1a1a;
}

.sec-padding {
	padding: 150px 0 150px 0;
}
.sec-padding-2 {
	padding: 110px 0 110px 0;
}
.sec-padding-3 {
	padding: 100px 0 100px 0;
}
.sec-padding-4 {
	padding: 70px 0 70px 0;
}
.sec-padding-5 {
	padding: 50px 0 50px 0;
}
.sec-padding-6 {
	padding: 30px 0 30px 0;
}




.sec-bpadding-2 {
	padding-bottom: 150px;
}
.sec-tpadding-2 {
	padding-top: 150px;
}
.sec-tpadding-2.less-padding {
	padding-top: 20px;
}
.sec-tpadding-3 {
	padding-top: 100px;
}
.sec-bpadding-3 {
	padding-bottom: 100px;
}
.sec-tpadding-4 {
	padding-top: 80px;
}
.sec-bpadding-4 {
	padding-bottom: 80px;
}
.sec-bpadding.less-padding {
	padding-bottom: 30px;
}


.sec-dummy-top-padding {
	padding-top: 0px;
}
/* Section minus margins
 ------------------------------ */
.sec-m-margin-1 {
	margin-top:0px;
}
/*============ Page Section pattrens ============ */


/*============ Footer Styles ============ */

.footer-logo {
	margin-bottom: 35px;
}

/* Footer Useful Links
 ------------------------------ */
	
.fo-usefull-links {
	padding: 0px;
	margin: 0px;
}
.fo-usefull-links li {
	padding: 0px;
	margin: 0 0 10px 0;
	display: block;
	border-bottom: 1px solid #2e2e2e;
}
.fo-usefull-links li a {
	padding: 0px;
	margin: 0 0 10px 0;
	display: block;
	color: #727272;
	line-height: 23px;
}
.fo-usefull-links li a:hover {
	color: #fff;
}
.fo-usefull-links li i {
	color: #727272;
	padding: 0 10px 0 0;
}
.fo-usefull-links li:last-child {
	border-bottom:none;
}

/* useful links 2
 ------------------------------ */
.fo-usefull-links-2 {
	padding: 0px;
	margin: 0px;
}
.fo-usefull-links-2 li {
	padding: 0px;
	margin: 0 0 10px 0;
	display: block;
	border-bottom: 1px solid #e4e4e4;
}
.fo-usefull-links-2 li a {
	padding: 0px;
	margin: 0 0 10px 0;
	display: block;
	color: #727272;
	line-height: 23px;
}
.fo-usefull-links-2 li a:hover {
	color: #242424;
}
.fo-usefull-links-2 li i {
	color: #727272;
	padding: 0 10px 0 0;
}
.fo-usefull-links-2 li:last-child {
	border-bottom:none;
}
.fo-usefull-links-2.no-border li {
	border-bottom:none;
}
/* useful links 3
 ------------------------------ */
.fo-usefull-links-3 {
	padding: 0px;
	margin: 0px;
}
.fo-usefull-links-3 li {
	padding: 0px;
	margin: 0 0 10px 0;
	display: block;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.fo-usefull-links-3 li a {
	padding: 0px;
	margin: 0 0 10px 0;
	display: block;
	color: #fff;
	line-height: 23px;
}
.fo-usefull-links-3 li a:hover {
	color: #242424;
}
.fo-usefull-links-3 li i {
	color: #fff;
	padding: 0 10px 0 0;
}
.fo-usefull-links-3 li:last-child {
	border-bottom:none;
}
.fo-usefull-links-3.no-border li {
	border-bottom:none;
}
/* Footer Address
 ------------------------------ */
	
.fo-address-info {
	padding: 0px;
	margin: 0px;
}
.fo-address-info li {
	width:100%;
	padding: 0px 0px 10px 0px;
	margin: 0 0 10px 0;
	display: block;
	color: #727272;
	line-height: 23px;
	border-bottom:1px solid #282828;
}
.fo-address-info.border-light li {
	border-bottom:1px solid #2f2d2d;
}
.fo-address-info li i {
	color: #727272;
	padding: 0 10px 0 0;
}
.fo-address-info li:last-child {
	border-bottom:none;
}
.fo-address-info.no-border li {
	border-bottom:none;
}
/* Footer Address 2
 ------------------------------ */
	
.fo-address-info-2 {
	padding: 0px;
	margin: 0px;
}
.fo-address-info-2 li {
	padding: 0px 0px 10px 0px;
	margin: 0 0 10px 0;
	display: block;
	color: #727272;
	line-height: 23px;
	border-bottom: 1px solid #e4e4e4;
}
.fo-address-info-2 li i {
	color: #727272;
	padding: 0 10px 0 0;
}
.fo-address-info-2 li:last-child {
	border-bottom:none;
}
.fo-address-info-2.no-border li {
	border-bottom:none;
}

/* Footer Address 3
 ------------------------------ */
	
.fo-address-info-3 {
	padding: 0px;
	margin: 0px;
}
.fo-address-info-3 li {
	padding: 0px 0px 10px 0px;
	margin: 0 0 10px 0;
	display: block;
	color: #fff;
	line-height: 23px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.fo-address-info-3 li i {
	color: #fff;
	padding: 0 10px 0 0;
}
.fo-address-info-3 li:last-child {
	border-bottom:none;
}
.fo-address-info-3.no-border li {
	border-bottom:none;
}


/* Footer Tags
 ------------------------------ */	
 
.footer-tags {
	padding: 0px;
	margin: 0px;
}
.footer-tags li {
	padding: 0px;
	margin: 0;
}
.footer-tags li a {
	padding: 5px 15px 5px 15px;
	margin: 0 10px 10px 0;
	float: left;
	color: #727272;
	border: 1px solid #2e2e2e;
}
.footer-tags li a:hover, .footer-tags li a.active {
	border: 1px solid #fce206;
	color: #fce206;
}
.footer-tags.border-light li a {
	border: 1px solid #ebebeb;
}

/* Footer Posts
 ------------------------------ */
.footer-post-info {
	padding: 0px;
}
.footer-post-info span {
	font-size: 13px;
	color: #494747;
	margin-right: 5px;
}
.fo-postimg-inner {
	width: 80px;
	height: 80px;
	padding: 0px;
	margin: 0px;
	float: left;
	border-radius: 100%;
}
.opening-list {
	float: left;
	padding: 0;
	width: 100%;
}
.opening-list li {
	color: #727272;
	float: left;
	padding: 8px 0;
	width: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.opening-list li:first-child {
	padding-top: 0px;
}
.opening-list li:last-child {
	border-bottom: none;
}
.opening-list.dark-border li {
	border-bottom: 1px solid #f1f1f1;
}
/* Footer copyright box
 ------------------------------ */	
 
.fo-copyright-holder {
	position:relative;
	width:100%;
	float:left;
	padding: 0px;
	margin: 0px;
}
.fo-copyright-holder .social-iconbox {
	position: absolute;
	width:265px;
	float:left;
	left:40%;
	top:-57px;
	padding: 0px;
	margin: 0px;
	background-color:#fff;
}
.fo-copyright-holder .social-iconbox.bg-dark {
	background-color:#101010;
}
.fo-copyright-holder .social-iconbox .side-shape1 {
	position: absolute;
	width:13px;
	height:28px;
	float:left;
	left: -13px;
	top: -1px;
	padding: 0px;
	margin: 0px;
}
.fo-copyright-holder .social-iconbox .side-shape1.right-icon {
	left: 265px;
	top: -1px;
}
.fo-copyright-holder .sc-icons{
	width:100%;
	float:left;
	padding: 0px;
	margin: 0px;
}
.fo-copyright-holder .sc-icons li{
	padding: 0px;
	margin: 0px;
	display:inline-block;
}
.fo-copyright-holder .sc-icons li a{
	float:left;
	font-size:15px;
	padding: 15px 10px;
	margin: 0px;
	display:inline-block;
}






/* Footer top social box
 ------------------------------ */	
 
.fo-top-holder {
	position:relative;
	width:100%;
	float:left;
	padding: 60px 0 0 0;
	margin: 0px;
}
.fo-top-holder .social-iconbox {
	position: absolute;
	width:265px;
	float:left;
	left:38%;
	top:26px;
	padding: 0px;
	margin: 0px;
	background-color:#fce206;
}
.fo-top-holder .social-iconbox.bg-dark {
	background-color:#101010;
}
.fo-top-holder .social-iconbox .side-shape1 {
	position: absolute;
	width:13px;
	height:28px;
	float:left;
	left: -18px;
	top: -1px;
	padding: 0px;
	margin: 0px;
}
.fo-top-holder .social-iconbox .side-shape1.right-icon {
	left: 265px;
	top: -1px;
}
.fo-top-holder .sc-icons{
	width:100%;
	float:left;
	padding: 0px;
	margin: 0px;
}
.fo-top-holder .sc-icons li{
	padding: 0px;
	margin: 0px;
	display:inline-block;
}
.fo-top-holder .sc-icons li a{
	float:left;
	font-size:15px;
	color:#fff;
	padding: 15px 10px;
	margin: 0px;
	display:inline-block;
}








/* Footer contact info with big icons
 ------------------------------ */	
 
.contact-info-holder {
	position: relative;
	width: 100%;
	padding: 0px;
}
.contact-info-holder .iconbox-xmedium {
	color: #fce206;
}
.contact-info-holder:hover .iconbox-xmedium, .contact-info-holder.active .iconbox-xmedium {
	color: #fff;
	background-color: #fce206;
}


/*============ Overlay Styles ============ */
.section-overlay {
	position: relative;
	width: 100%;
	padding: 0px;
	margin: 0px;
	left: 0px;
	top: 0px;
	float: left;
	z-index: 2;
}
.video-overlay {
	position: relative;
	width: 100%;
	padding: 0px;
	margin: 0px;
	left: 0px;
	top: 0px;
	float: left;
	z-index: 2;
}
.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 0px;
	margin: 0px;
	float: left;
	z-index: 10;
}

/*video padding
 ------------------------------ */
.video-tpadd {
	padding-top: 150px;
}
.video-bpadd {
	padding-bottom: 150px;
}

/* Overlay opacity styles
 ------------------------------ */
 
.bg-opacity-1 {
	background-color: rgba(0, 0, 0, 0.1);
}
.bg-opacity-2 {
	background-color: rgba(0, 0, 0, 0.2);
}
.bg-opacity-3 {
	background-color: rgba(0, 0, 0, 0.3);
}
.bg-opacity-4 {
	background-color: rgba(0, 0, 0, 0.4);
}
.bg-opacity-5 {
	background-color: rgba(0, 0, 0, 0.5);
}
.bg-opacity-6 {
	background-color: rgba(0, 0, 0, 0.6);
}
.bg-opacity-7 {
	background-color: rgba(0, 0, 0, 0.7);
}
.bg-opacity-8 {
	background-color: rgba(0, 0, 0, 0.8);
}
.bg-opacity-9 {
	background-color: rgba(0, 0, 0, 0.9);
}
/* Overlay Background colors
 ------------------------------ */
 /* Background light
 ------------------------------ */
.section-overlay.light {
	background-color: rgba(246, 246, 246, 0.9);
}
.section-overlay.light-2 {
	background-color: rgba(246, 246, 246, 0.8);
}
.section-overlay.light-3 {
	background-color: rgba(246, 246, 246, 0.7);
}
.section-overlay.light-4 {
	background-color: rgba(246, 246, 246, 0.6);
}
.section-overlay.light-5 {
	background-color: rgba(246, 246, 246, 0.5);
}
 /* Overlay Background dark
 ------------------------------ */
.section-overlay.dark {
	background-color: rgba(0, 0, 0, 0.7);
}
.section-overlay.dark-2 {
	background-color: rgba(0, 0, 0, 0.3);
}
.section-overlay.dark-3 {
	background-color: rgba(0, 0, 0, 0.9);
}
.section-overlay.dark-4 {
	background-color: rgba(0, 0, 0, 0.4);
}
 /* Overlay Background golden yellow
 ------------------------------ */
.section-overlay.primary {
	background-color: rgba(242, 76, 52, 0.9);
}
.parallax-overlay.primary {
	background-color: rgba(252, 226, 6, 0.9);
}
.parallax-overlay.light {
	background-color: rgba(246, 246, 246, 0.9);
}
.parallax-overlay.light-2 {
	background-color: rgba(246, 246, 246, 0.8);
}
.parallax-overlay.light-3 {
	background-color: rgba(246, 246, 246, 0.7);
}
.parallax-overlay.light-4 {
	background-color: rgba(246, 246, 246, 0.6);
}
.parallax-overlay.light-5 {
	background-color: rgba(246, 246, 246, 0.5);
}
/*============ Social Links ============ */	

.social-icons-1 {
	width: 100%;
	margin: 30px 0 0 0;
	list-style-type: none;
}
.social-icons-1 li {
	display:inline-block;
}
.social-icons-1 li a {
	font-size: 16px;
	padding: 0 20px 0 0;
	color: #fff;
	display:inline-block;
}
.social-icons-1 li a:hover, .social-icons-1 li a.active {
	color: #fce206;
}

/* social icons 2
 -----------------------------------------------*/

.social-icons-2 {
	width: 100%;
	margin: 30px 0 0 0;
	list-style-type: none;
}
.social-icons-2 li {
	display:inline-block;
}
.social-icons-2 li a {
	width: 36px;
    height: 36px;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    padding: 0px;
    color: #101010;
    background-color: #fff;
}
.social-icons-2 li a:hover, .social-icons-2 li a.active {
	color: #fce206;
}

/* social icons 3
 -----------------------------------------------*/

.social-icons-3 {
	width: 100%;
	margin: 30px 0 0 0;
	list-style-type: none;
}
.social-icons-3.margin-left {
	margin: 10px 0 0 -40px;
}
.social-icons-3 li {
	display:inline-block;
}
.social-icons-3 li a {
	width: 36px;
    height: 36px;
    text-align: center;
    line-height: 37px;
    display: inline-block;
    padding: 0px;
    color: #101010;
	border:1px solid #e4e4e4;
    background-color: #fff;
}
.social-icons-3 li a:hover, .social-icons-3 li a.active {
	color: #fff;
	border:1px solid #101010;
    background-color: #101010;
}



/*============ Blog Posts ============ */

/* post image holder
 -----------------------------------------------*/
 
.blog-img-holder {
	width: 100%;
	padding: 0px;
	margin: 0px;
}
/* post date boxes
 -----------------------------------------------*/

.post-date-box {
	position: absolute;
	width: 90px;
	height: 90px;
	float: left;
	font-size: 25px;
	color: #fff;
	padding: 22px 0 0 0;
	margin: 0;
	z-index: 2;
	text-align: center;
	background-color: #fce206;
	transition: all 0.3s ease-out 0s;
}
.post-date-box span {
	font-size: 13px;
	color: #fff;
	display: block;
}
.post-date-box .arrow-down {
	position: absolute;
	left: 25px;
	top: 81px;
	float: left;
	z-index: 3;
	border-top: 20px solid #fce206;
}
/* Date box colors
 -----------------------------------------------*/	
.post-date-box.gyellow {
	background-color: #fce206;
}
/* post author image holder
 -----------------------------------------------*/	
 
.post-author-image {
	position: absolute;
	width: 80px;
	height: 80px;
	right: 35px;
	top: 247px;
	float: left;
}
.post-author-image.round {
	border-radius: 100%;
	border: 5px solid #fff;
	text-align: center;
}
/* post info icons
 -----------------------------------------------*/
 
.post-infoicon {
	padding: 3px 12px 3px 12px;
	margin: 0 5px 0 0;
	color: #fff;
	float: left;
	font-size: 12px;
	border-radius: 20px;
	background-color: #fce206;
}
.post-infoicon.dark {
	background-color: #161616;
}


/*============ Footer Newsletter ============ */

.newsletter .email-input {
	background-color: #FFF;
	border: 0px none;
	color: #999;
	float: left;
	font-size: 13px;
	font-weight: normal;
	height: 39px;
	line-height: 39px;
	padding: 0px 7px;
	width: 75%;
}
.newsletter .email-input.dark {
	background-color: #2d2d2d;
	color: #727272;
}
.newsletter .input-submit {
	background-color: #fd602c;
	border: 0px none;
	color: #FFF;
	cursor: pointer;
	float: left;
	height: 39px;
	padding: 0px 20px;
}
.newsletter .input-submit.gyellow {
	background-color: #fce206;
}
.newsletter .input-submit.white {
	color: #161616;
	background-color: #fff;
}
.newsletter .input-submit.dark {
	color: #727272;
	background-color: #2d2d2d;
}

/*============ Video wrapers ============ */

.video-wrapper {
	overflow: hidden;
	width: 100%;
	padding:0px;
	height: auto;
}
.video-wrapper video {
	width: 100%;
}

/*video padding
 ------------------------------ */
.video-padding-top {
	padding-top: 150px;
}
.video-padding-bottom {
	padding-bottom: 150px;
}

/*============ Progress bar ============ */

/*style 1
 ------------------------------ */
.progressbar-style1 {
	margin-top: 10px;
	margin-bottom: 38px;
	font-weight: 400;
	letter-spacing: 1px;
	overflow: visible;
	height: 10px;
	color: #fff;
	background: #fff;
	border-radius: 0px;
	box-shadow: none;
	text-transform: uppercase;
}
.progressbar-style1 .progress-bar {
	position: relative;
	overflow: visible;
	background-color: #101010;
	font-size: 13px;
	color: #fce206;
	line-height:44px;
	padding: 0px 0 0 0px;
	text-align: left;
	border:2px solid #fff;
}
.progressbar-style1 .progress-bar.yellow-3 {
	background-color: #f7c800;
}

/*style 2
 ------------------------------ */
.progressbar-style2 {
	margin-top: 10px;
	margin-bottom: 45px;
	font-weight: 400;
	letter-spacing: 1px;
	overflow: visible;
	height: 3px;
	color: #161616;
	background: #ccc;
	border-radius: 0px;
	box-shadow: none;
	text-transform: uppercase;
}
.progressbar-style2 .progress-bar {
	position: relative;
	overflow: visible;
	background-color: #161616;
	font-size: 13px;
	color: #161616;
	padding: 3px 0 0 0;
	text-align: left;
}

/*style 3
 ------------------------------ */
.progressbar-style3 {
	margin-top: 10px;
	margin-bottom: 45px;
	font-weight: 400;
	letter-spacing: 1px;
	overflow: visible;
	height: 8px;
	color: #161616;
	background: #fff;
	border-radius: 0px;
	box-shadow: none;
	text-transform: uppercase;
}
.progressbar-style3 .progress-bar {
	position: relative;
	overflow: visible;
	background-color: #e9b444;
	font-size: 13px;
	color: #fff;
	padding: 8px 0 0 0;
	text-align: left;
}
/*style 4
 ------------------------------ */
.progressbar-style4 {
	margin-top: 10px;
	margin-bottom: 45px;
	font-weight: 400;
	letter-spacing: 1px;
	overflow: visible;
	height: 8px;
	color: #161616;
	background: #e7e7e7;
	border-radius: 0px;
	box-shadow: none;
	text-transform: uppercase;
}
.progressbar-style4 .progress-bar {
	position: relative;
	overflow: visible;
	background-color: #ff9320;
	font-size: 13px;
	color: #727272;
	padding: 8px 0 0 0;
	text-align: left;
}
/*style 5
 ------------------------------ */
.progressbar-style5 {
	margin-top: 10px;
	margin-bottom: 45px;
	font-weight: 400;
	letter-spacing: 1px;
	overflow: visible;
	height: 8px;
	color: #161616;
	background: #f5f5f5;
	border-radius: 0px;
	box-shadow: none;
	text-transform: uppercase;
}
.progressbar-style5 .progress-bar {
	position: relative;
	overflow: visible;
	background-color: #101010;
	font-size: 13px;
	color: #101010;
	padding: 8px 0 0 0;
	text-align: left;
}

/*style 6
 ------------------------------ */
.progressbar-style6 {
	margin-top: 10px;
	margin-bottom: 45px;
	font-weight: 400;
	letter-spacing: 1px;
	overflow: visible;
	height: 8px;
	color: #161616;
	background: #fce206;
	border-radius: 0px;
	box-shadow: none;
	text-transform: uppercase;
}
.progressbar-style6 .progress-bar {
	position: relative;
	overflow: visible;
	background-color: #101010;
	font-size: 13px;
	color: #101010;
	padding: 8px 0 0 0;
	text-align: left;
}


/*============ Newsletter ============ */

.email-input {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: 1px solid rgba(255, 255, 255, 0.4);
	color: #fff;
	float: left;
	font-size: 14px;
	font-weight: normal;
	height: 65px;
	line-height: 39px;
	padding: 0 15px;
	width: 87%;
}
.email-submit {
	background-color: #fff;
	border: 1px solid #fff;
	color: #242424;
	float: left;
	margin: 0px;
	padding: 20px 30px;
	text-transform: uppercase;
	transition: all 0.3s ease-out 0s;
}
.email-submit:hover {
	background-color: #242424;
	border: 1px solid #242424;
	color: #fff;
}

/*============ css progress circles ============ */

.knob {
	display: none;
}
div.demo {
	position: relative;
	text-align: center;
	width: 280px;
	float: left
}
div.demo > p {
	font-size: 20px
}
div.demo .title {
	position: absolute;
	text-align: center;
	width: 100%;
	font-size: 40px;
	float: left;
	top: 60px;
}
div.demo .title span {
	text-align: center;
	width: 100%;
	font-size: 20px;
	float: left;
	margin-top: 10px;
}

/*============ Page Scroll to Top ============ */



/*============ google maps ============ */
.map{
    width: 100%;
    height: 300px;
    border: 1px solid #e4e4e4;
}
.map p{
	margin: 10px;
	color: #333;
}

/*============ blog ============ */

.blog-post-info {
	width: 100%;
}
.blog-post-info span {
	font-size: 13px;
	color: #b9b9b9;
	margin-right: 15px;
}

/*============ onepage sidebar content holder ============ */
.wrapper-main {
padding-left: 300px;
background-color: #fff;
}


/*============ header inner ============ */

.header-inner-tmargin {
    width: 100%;
    float: left;
    margin-top: -145px;
}
.header-inner-tmargin.less-mar {
    margin-top: -41px;
}
.header-inner {
	position:relative;
    width: 100%;
    height: 500px;
    float: left;
	overflow:hidden;
}
.header-inner.less-height {
    height: 430px;
}
.header-inner .overlay {
	position:absolute;
    width: 100%;
    height: 100%;
    float: left;
	overflow:hidden;
	z-index:2;
	background-color:rgba(0, 0, 0, 0.7);
}
.header-inner .text {
    position: absolute;
    width: 100%;
	height:1000px;
    padding:16% 0 0 0;
	top:0px;
    margin: 0px;
    float: left;
    z-index: 3;
}
.header-inner .text .title {
	font-size:30px;
	font-weight:600;
}
.header-inner .text .sub-title {
	width:100%;
	font-size:18px;
}

/* less height
 ------------------------------ */
.header-inner.less-height {
    height: 335px;
}
.header-inner.less-height .text {
    height: 300px;
    padding:9% 0 0 0;
}

/*============ pagenation ============ */
.pagenation-holder {
	border-bottom: 1px solid #f0f0f0;
	float: left;
	margin: 0;
	padding: 25px 0 10px;
	width: 100%;
}
.pagenation-holder .breadcrumb {
	float:right;
	margin-bottom:0px;
	background-color:#fff;
}
.pagenation-holder .breadcrumb li {
    display: inline;
}
.pagenation-holder .breadcrumb li.current a {
	color:#fce206;
}
.pagenation-holder .breadcrumb li+li:before {
    content:"» ";
}


/*============ 5 and 7 custom cols ============ */

.seven-cols .col-md-1, .seven-cols .col-sm-1, .seven-cols .col-lg-1 {
    width: 14.2857%;
}
.five-cols .col-md-1, .five-cols .col-sm-1, .five-cols .col-lg-1 {
    width: 20%;
}


/* footer newsletter
 ------------------------------ */
.bb-newsletter {
	width: 25%;
	height: 50px;
	float: left;
	padding: 10px;
	color:#fff;
	margin-right:20px;
	border-radius:40px;
	text-align:center;
	border: 1px solid rgba(255, 255, 255, 0.4);
	background:none;
	}
.bb-newsletter-email {
	width: 55%;
	height: 50px;
	float: left;
	padding: 10px;
	color:#fff;
	margin-right:20px;
	border-radius:40px;
	text-align:center;
	border: 1px solid rgba(255, 255, 255, 0.4);
	background:none;
	}
.bb-newsletter-btn {
    width: 15%;
    height: 50px;
    float: left;
    padding: 0px;
	margin-top:5px;
	color:#101010;
	text-transform:uppercase;
    border-radius:40px;
	border: 1px solid #fce206;
	background-color: #fce206;
}


/* footer newsletter 2
 ------------------------------ */
.co-newsletter {
	width: 25%;
	height: 50px;
	float: left;
	padding: 10px;
	color:#101010;
	margin-right:20px;
	border-radius:40px;
	text-align:center;
	border: 1px solid rgba(0, 0, 0, 0.4);
	background:none;
	}
.co-newsletter-email {
	width: 55%;
	height: 50px;
	float: left;
	padding: 10px;
	color:#101010;
	margin-right:20px;
	border-radius:40px;
	text-align:center;
	border: 1px solid rgba(0, 0, 0, 0.4);
	background:none;
	}
.co-newsletter-btn {
    width: 15%;
    height: 50px;
    float: left;
    padding: 0px;
	margin-top:5px;
	color:#727272;
	text-transform:uppercase;
    border-radius:40px;
	border: 1px solid #101010;
	background-color: #101010;
}


/*----------------------------RESPONSIVE STYLES------------------------------- */
/* ---------- MAX 1024PX ---------- */
@media screen and (max-width: 1024px) {	
.col-divider-margin {
    margin-top: 30px;
}
.text-box-right {
    padding: 0px 0px 0px 100px;
}
.wrapper-main {
width:100%;
padding-left:230px;
background-color: #fff;
}
.wrapper-main .container {
width:100%;
}
.header-inner.less-height .text {
    padding: 13% 0 0;
}
.header-inner .text {
    padding: 25% 0 0;
}





}
	
@media screen and (max-width: 1000px) {

.col-divider-margin {
    margin-top: 20px;
}
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}
.sec-m-margin-1 {
    margin-top: 0px;
}
.topbar-transparent {
	background-color:#101010;
}
.wrapper-main {
padding-left:0px;
}
.header-inner-tmargin {
    margin-top: 0px;
}







}	

	
	
/* ---------- MAX 991PX ---------- */
@media only screen and (max-width: 991px) {
.margin-bottom {
	margin-bottom: 30px;
}
.col-divider-margin {
    margin-top: 20px;
}
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}
.margin-left-1, .margin-left-2, .margin-left-3, .margin-left-4, .margin-left-5, .margin-left-6, .margin-left-7 {
    margin-left:0px;
}
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 40px;
}
.padding-left-1, .padding-left-2, .padding-left-3, .padding-left-4, .padding-left-5, .padding-left-6, .padding-left-7, .padding-left-8 {
	padding-left: 40px;
}
.padding-right-1, .padding-right-2, .padding-right-3, .padding-right-4, .padding-right-5, .padding-right-6, .padding-right-7, .padding-right-8 {
	padding-right: 40px;
}
.text-box.padding-1, .text-box.padding-2, .text-box.padding-3, .text-box.padding-4, .text-box.padding-5, .text-box.padding-6, .text-box.padding-7, .text-box.padding-8, .text-box.padding-9 {
	padding: 40px;
}
.btn.btn-mar-left-1, .btn.btn-mar-left-2, .btn.btn-mar-left-3, .btn.btn-mar-left-4, .btn.btn-mar-left-5 {
	margin-left:0px
}
.sec-title-container.less-padding-1, .sec-title-container.less-padding-2, .sec-title-container.less-padding-3, .sec-title-container.less-padding-4,
.sec-title-container.less-padding-5, .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
}
.header-inner {
    height: 385px;
}
.header-inner.less-height {
    height: 385px;
}
.header-inner.less-height .text {
    padding: 16% 0 0;
}
.header-inner .text {
    padding: 16% 0 0;
}

.sec-padding {
	padding: 100px 0 100px 0;
}















}



/* ---------- MAX 800PX ---------- */
@media only screen and (max-width: 800px) {
	
.margin-bottom {
	margin-bottom: 30px;
}	
.margin-bottom-4, .margin-bottom-5 {
    margin-bottom: 20px;
}	
.margin-top-3, .margin-top-4, .margin-top-5, .margin-top-6, .margin-top-7 {
    margin-top: 20px;
}	
.col-divider-margin {
    margin-top: 20px;
}	
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}	
.margin-left-1, .margin-left-2, .margin-left-3, .margin-left-4, .margin-left-5, .margin-left-6, .margin-left-7 {
    margin-left:0px;
}	
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 40px;
}	
.padding-left-1, .padding-left-2, .padding-left-3, .padding-left-4, .padding-left-5, .padding-left-6, .padding-left-7, .padding-left-8 {
	padding-left: 40px;
}	
.padding-right-1, .padding-right-2, .padding-right-3, .padding-right-4, .padding-right-5, .padding-right-6, .padding-right-7, .padding-right-8 {
	padding-right: 40px;
}	
.text-box.padding-1, .text-box.padding-2, .text-box.padding-3, .text-box.padding-4, .text-box.padding-5, .text-box.padding-6, .text-box.padding-7, .text-box.padding-8, .text-box.padding-9 {
	padding: 40px;
}	
.btn.btn-mar-left-1, .btn.btn-mar-left-2, .btn.btn-mar-left-3, .btn.btn-mar-left-4, .btn.btn-mar-left-5 {
	margin-left:0px
}
.sec-title-container.less-padding-1, .sec-title-container.less-padding-2, .sec-title-container.less-padding-3, .sec-title-container.less-padding-4,
.sec-title-container.less-padding-5, .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
}
.sec-padding {
	padding: 60px 0 60px 0;
}
.sec-more-padding-1 {
	padding: 60px 0 60px 0;
}
.sec-bpadding-2, .sec-bpadding-3, .sec-bpadding-4 {
	padding-bottom: 60px;
}
.sec-tpadding-2, .sec-tpadding-3, .sec-tpadding-4 {
	padding-top: 60px;
}
.section-xmore-padding, .section-less-padding, .section-less-padding-2 {
	padding: 60px 0 60px 0;
}
.sec-moreless-padding {
	padding: 60px 0 60px 0;
}
.thumbs li {
    width: 10%;
}
.fo-copyright-holder .social-iconbox {
    left: 33%;
	top: -87px;
}
.fo-sec-3 {
    padding: 244px 0 0 0;
}
.header-inner {
    height: 310px;
}
.header-inner.less-height {
    height: 310px;
}
.section-side-image .text-inner-6 {
    padding: 40px;
}




 
}



/* ---------- MAX 768PX ---------- */
@media only screen and (max-width: 768px) {

.margin-bottom {
	margin-bottom: 30px;
}
.margin-bottom-4, .margin-bottom-5 {
    margin-bottom: 20px;
}	
.margin-top-3, .margin-top-4, .margin-top-5, .margin-top-6, .margin-top-7 {
    margin-top: 20px;
}
.col-divider-margin {
    margin-top: 20px;
}
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}
.margin-left-1, .margin-left-2, .margin-left-3, .margin-left-4, .margin-left-5, .margin-left-6, .margin-left-7 {
    margin-left:0px;
}
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 40px;
}
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 40px;
}
.padding-left-1, .padding-left-2, .padding-left-3, .padding-left-4, .padding-left-5, .padding-left-6, .padding-left-7, .padding-left-8 {
	padding-left: 40px;
}
.padding-right-1, .padding-right-2, .padding-right-3, .padding-right-4, .padding-right-5, .padding-right-6, .padding-right-7, .padding-right-8 {
	padding-right: 40px;
}
.text-box.padding-1, .text-box.padding-2, .text-box.padding-3, .text-box.padding-4, .text-box.padding-5, .text-box.padding-6, .text-box.padding-7, .text-box.padding-8, .text-box.padding-9 {
	padding: 40px;
}
.btn.btn-mar-left-1, .btn.btn-mar-left-2, .btn.btn-mar-left-3, .btn.btn-mar-left-4, .btn.btn-mar-left-5 {
	margin-left:0px
}
.sec-title-container.less-padding-1, .sec-title-container.less-padding-2, .sec-title-container.less-padding-3, .sec-title-container.less-padding-4,
.sec-title-container.less-padding-5, .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
}
.sec-padding {
	padding: 60px 0 60px 0;
}
.sec-more-padding-1 {
	padding: 60px 0 60px 0;
}
.sec-bpadding-2, .sec-bpadding-3, .sec-bpadding-4 {
	padding-bottom: 60px;
}
.sec-tpadding-2, .sec-tpadding-3, .sec-tpadding-4 {
	padding-top: 60px;
}
.section-xmore-padding, .section-less-padding, .section-less-padding-2 {
	padding: 60px 0 60px 0;
}
.sec-moreless-padding {
	padding: 60px 0 60px 0;
}
.fo-copyright-holder .social-iconbox {
    left: 33%;
	top: -87px;
}
.header-inner {
    height: 325px;
}
.header-inner.less-height {
    height: 325px;
}
.five-cols .col-md-1, .five-cols .col-sm-1, .five-cols .col-lg-1 {
    width: 100%;
}














}



/* ---------- MAX 767PX ---------- */
@media only screen and (max-width: 767px) {
	
.margin-bottom {
	margin-bottom: 30px;
}	
.margin-bottom-4, .margin-bottom-5 {
    margin-bottom: 20px;
}	
.margin-top-3, .margin-top-4, .margin-top-5, .margin-top-6, .margin-top-7 {
    margin-top: 20px;
}	
.col-divider-margin {
    margin-top: 20px;
}	
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}		
.margin-left-1, .margin-left-2, .margin-left-3, .margin-left-4, .margin-left-5, .margin-left-6, .margin-left-7 {
    margin-left:0px;
}
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 40px;
}
.padding-left-1, .padding-left-2, .padding-left-3, .padding-left-4, .padding-left-5, .padding-left-6, .padding-left-7, .padding-left-8 {
	padding-left: 40px;
}
.padding-right-1, .padding-right-2, .padding-right-3, .padding-right-4, .padding-right-5, .padding-right-6, .padding-right-7, .padding-right-8 {
	padding-right: 40px;
}
.text-box.padding-1, .text-box.padding-2, .text-box.padding-3, .text-box.padding-4, .text-box.padding-5, .text-box.padding-6, .text-box.padding-7, .text-box.padding-8, .text-box.padding-9 {
	padding: 40px;
}
.btn.btn-mar-left-1, .btn.btn-mar-left-2, .btn.btn-mar-left-3, .btn.btn-mar-left-4, .btn.btn-mar-left-5 {
	margin-left:0px
}
.sec-title-container.less-padding-1, .sec-title-container.less-padding-2, .sec-title-container.less-padding-3, .sec-title-container.less-padding-4,
.sec-title-container.less-padding-5, .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
}



























}


@media screen and (max-width: 640px) {
.margin-bottom {
	margin-bottom: 30px;
}	
.margin-bottom-4, .margin-bottom-5 {
    margin-bottom: 20px;
}	
.margin-top-3, .margin-top-4, .margin-top-5, .margin-top-6, .margin-top-7 {
    margin-top: 20px;
}
.col-divider-margin {
    margin-top: 20px;
}
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}
.margin-left-1, .margin-left-2, .margin-left-3, .margin-left-4, .margin-left-5, .margin-left-6, .margin-left-7 {
    margin-left:0px;
}
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 40px;
}
.padding-left-1, .padding-left-2, .padding-left-3, .padding-left-4, .padding-left-5, .padding-left-6, .padding-left-7, .padding-left-8 {
	padding-left: 40px;
}
.padding-right-1, .padding-right-2, .padding-right-3, .padding-right-4, .padding-right-5, .padding-right-6, .padding-right-7, .padding-right-8 {
	padding-right: 40px;
}
.lspace-sm, .lspace-1, .lspace-2, .lspace-3, .lspace-4, .lspace-5{
	letter-spacing:0px;
}
.text-box.padding-1, .text-box.padding-2, .text-box.padding-3, .text-box.padding-4, .text-box.padding-5, .text-box.padding-6, .text-box.padding-7, .text-box.padding-8, .text-box.padding-9 {
	padding: 40px;
}
.btn.btn-mar-left-1, .btn.btn-mar-left-2, .btn.btn-mar-left-3, .btn.btn-mar-left-4, .btn.btn-mar-left-5 {
	margin-left:0px
}
.sec-title-container.less-padding-1, .sec-title-container.less-padding-2, .sec-title-container.less-padding-3, .sec-title-container.less-padding-4,
.sec-title-container.less-padding-5, .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
}
.sec-padding {
	padding: 40px 0 40px 0;
}
.sec-more-padding-1 {
	padding: 40px 0 40px 0;
}
.sec-bpadding-2, .sec-bpadding-3, .sec-bpadding-4 {
	padding-bottom: 40px;
}
.sec-tpadding-2, .sec-tpadding-3, .sec-tpadding-4 {
	padding-top: 40px;
}
.section-xmore-padding, .section-less-padding, .section-less-padding-2 {
	padding: 40px 0 40px 0;
}
.sec-moreless-padding {
	padding: 40px 0 40px 0;
}
#style-customizer{ 
display:none;
}
 .text-box.sbox-padd-left {
	padding-left:80px;
}
.fo-sec-1 .newsletter-box {
    top: 0;
}
.fo-sec-1 {
   top:0px;
   padding: 315px 0 0 0;
}
.fo-copyright-holder .social-iconbox {
    left: 29%;
    top: -67px;
}
.section-side-image .text-inner-5 {
    padding: 40px 40px 40px 40px;
}
.header-inner {
    height: 270px;
}
.header-inner.less-height {
    height: 270px;
}












}



@media screen and (max-width: 480px) {
.margin-bottom {
	margin-bottom: 30px;
}
.margin-bottom-4, .margin-bottom-5 {
    margin-bottom: 20px;
}	
.margin-top-3, .margin-top-4, .margin-top-5, .margin-top-6, .margin-top-7 {
    margin-top: 20px;
}
.col-divider-margin {
    margin-top: 20px;
}
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}
.margin-left-1, .margin-left-2, .margin-left-3, .margin-left-4, .margin-left-5, .margin-left-6, .margin-left-7 {
    margin-left:0px;
}
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 30px;
}
.padding-left-1, .padding-left-2, .padding-left-3, .padding-left-4, .padding-left-5, .padding-left-6, .padding-left-7, .padding-left-8 {
	padding-left: 30px;
}
.padding-right-1, .padding-right-2, .padding-right-3, .padding-right-4, .padding-right-5, .padding-right-6, .padding-right-7, .padding-right-8 {
	padding-right: 30px;
}
.lspace-sm, .lspace-1, .lspace-2, .lspace-3, .lspace-4, .lspace-5{
	letter-spacing:0px;
}
.text-box.padding-1, .text-box.padding-2, .text-box.padding-3, .text-box.padding-4, .text-box.padding-5, .text-box.padding-6, .text-box.padding-7, .text-box.padding-8, .text-box.padding-9 {
	padding: 30px;
}
.btn.btn-mar-left-1, .btn.btn-mar-left-2, .btn.btn-mar-left-3, .btn.btn-mar-left-4, .btn.btn-mar-left-5 {
	margin-left:0px
}
.topbar .border-r {
    border-right: none;
}
.topbar .padd-left {
    padding-left: 0px;
}
.sec-title-container.less-padding-1, .sec-title-container.less-padding-2, .sec-title-container.less-padding-3, .sec-title-container.less-padding-4,
.sec-title-container.less-padding-5, .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
}
.sec-padding {
	padding: 40px 0 40px 0;
}
.sec-more-padding-1 {
	padding: 40px 0 40px 0;
}
.sec-bpadding-2, .sec-bpadding-3, .sec-bpadding-4 {
	padding-bottom: 40px;
}
.sec-tpadding-2, .sec-tpadding-3, .sec-tpadding-4 {
	padding-top: 40px;
}
.section-xmore-padding, .section-less-padding, .section-less-padding-2 {
	padding: 40px 0 40px 0;
}
.sec-moreless-padding {
	padding: 40px 0 40px 0;
}
#style-customizer{ 
display:none;
}
.fo-copyright-holder .social-iconbox {
    left: 12%;
	top: -67px;
}
.fo-sec-3 {
    padding: 287px 0 0 0;
}
.section-side-image .text-inner-5 {
    padding: 40px 40px 40px 40px;
}
.header-inner .text .title {
    font-size: 20px;
}
.header-inner .text .sub-title {
    font-size: 14px;
}
.header-inner {
    height: 225px;
}
.header-inner.less-height {
    height: 225px;
}
.clients-list.grid-cols-6 li {
    width: 50%;
}


















}



/* ---------- MAX 360PX ---------- */
@media screen and (max-width: 360px) {
.margin-bottom {
	margin-bottom: 30px;
}
.margin-bottom-4, .margin-bottom-5 {
    margin-bottom: 20px;
}	
.margin-top-3, .margin-top-4, .margin-top-5, .margin-top-6, .margin-top-7 {
    margin-top: 20px;
}
.col-divider-margin {
    margin-top: 20px;
}
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}
.margin-left-1, .margin-left-2, .margin-left-3, .margin-left-4, .margin-left-5, .margin-left-6, .margin-left-7 {
    margin-left:0px;
}
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 25px;
}
.padding-left-1, .padding-left-2, .padding-left-3, .padding-left-4, .padding-left-5, .padding-left-6, .padding-left-7, .padding-left-8 {
	padding-left: 25px;
}
.padding-right-1, .padding-right-2, .padding-right-3, .padding-right-4, .padding-right-5, .padding-right-6, .padding-right-7, .padding-right-8 {
	padding-right: 25px;
}
.lspace-sm, .lspace-1, .lspace-2, .lspace-3, .lspace-4, .lspace-5{
	letter-spacing:0px;
}
.iconbox-dxlarge.left, .iconbox-xlarge.left, .iconbox-large.left, .iconbox-xmedium.left, .iconbox-medium.left, .iconbox-smedium.left, .iconbox-small.left, .iconbox-tiny.left, .iconbox-xtiny.left {
    margin: 0px 0px 20px 0px;
}
.imgbox-dxlarge.left, .imgbox-dxlarge-2.left, .imgbox-xlarge.left, .imgbox-large.left, .imgbox-xmedium.left, .imgbox-medium.left, .imgbox-smedium.left, .imgbox-small.left, .imgbox-tiny.left, .imgbox-xtiny.left {
    float: left;
    margin: 0px 0 20px 0px;
}
.text-box-right {
    width:100%;
    padding: 0 0 0 0;
}
.text-box-left {
	width:100%;
    padding: 0 0 0 0;
}
.text-box.padding-1, .text-box.padding-2, .text-box.padding-3, .text-box.padding-4, .text-box.padding-5, .text-box.padding-6, .text-box.padding-7, .text-box.padding-8, .text-box.padding-9 {
	padding: 25px;
}
.text-box-left.more-padding-1, .text-box-left.more-padding-2, .text-box-left.less-padding-1, .text-box-left.less-padding-2 {
	padding: 0px 0px 0px 0px;
}
.text-box-right.more-padding-1, .text-box-right.more-padding-2, .text-box-right.more-padding-3, .text-box-right.more-padding-4, .text-box-right.less-padding-1, .text-box-right.less-padding-2, .text-box-right.less-padding-3 {
	padding: 0px 0px 0px 0px;
}
.btn.btn-mar-left-1, .btn.btn-mar-left-2, .btn.btn-mar-left-3, .btn.btn-mar-left-4, .btn.btn-mar-left-5 {
	margin-left:0px
}
.btn.btn-large {
    padding: 15px 30px;
}
.section-side-image .text-inner-2 {
    padding: 40px;
}
.section-side-image .text-inner {
	padding: 40px 40px 40px 0;
}
.section-side-image .text-inner.two {
	padding: 0px 40px 0px 0;
}
.section-side-image-2 .background-imgholder-2.top-padd {
	padding-top: 40px;
}
.section-side-image-2 .text-inner.two {
	padding: 0px 40px 0px 0;
}
.section-side-image .text-inner-5 {
    padding: 40px 40px 40px 40px;
}
.sec-title-container.less-padding-1, .sec-title-container.less-padding-2, .sec-title-container.less-padding-3, .sec-title-container.less-padding-4,
.sec-title-container.less-padding-5, .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
}
.sec-padding {
	padding: 40px 0 40px 0;
}
.sec-more-padding-1 {
	padding: 40px 0 40px 0;
}
.sec-bpadding-2, .sec-bpadding-3, .sec-bpadding-4 {
	padding-bottom: 40px;
}
.sec-tpadding-2, .sec-tpadding-3, .sec-tpadding-4 {
	padding-top: 40px;
}
.section-xmore-padding, .section-less-padding, .section-less-padding-2 {
	padding: 40px 0 40px 0;
}
.sec-moreless-padding {
	padding: 40px 0 40px 0;
}
.fo-copyright-holder .social-iconbox {
    left: 5%;
}
.fo-sec-1 .newsletter-box {
    padding: 35px;
}
.fo-sec-2 .social-iconbox {
    left: 14%;
    top: -67px;
}
.section-side-image .text-inner-3 {
    padding: 30px 30px 0px 30px;
}
.fo-sec-3 {
    padding: 328px 0 0 0;
}
.text-box-right {
    width: 100%;
    padding: 0 0 0 0;
    float: left;
}
.text-box-right.less-padding-4 {
    padding: 0px 0px 0px 0px;
}
.header-inner {
    height: 195px;
}
.header-inner.less-height {
    height: 195px;
}
.sec-title-container {
    padding-bottom: 40px;
}









}


/* ---------- MAX 320PX ---------- */
@media only screen and (max-width: 320px) {
.margin-bottom {
	margin-bottom: 30px;
}
.margin-bottom-4, .margin-bottom-5 {
    margin-bottom: 20px;
}	
.margin-top-3, .margin-top-4, .margin-top-5, .margin-top-6, .margin-top-7 {
    margin-top: 20px;
}
.col-divider-margin {
    margin-top: 20px;
}
.col-divider-margin-1, .col-divider-margin-2, .col-divider-margin-3, .col-divider-margin-4, .col-divider-margin-5, .col-divider-margin-6 {
	margin-top: 20px;
}
.margin-left-1, .margin-left-2, .margin-left-3, .margin-left-4, .margin-left-5, .margin-left-6, .margin-left-7 {
    margin-left:0px;
}
.padding-top-1, .padding-top-2, .padding-top-3, .padding-top-4, .padding-top-5, .padding-top-6 {
	padding-top: 20px;
}
.padding-left-1, .padding-left-2, .padding-left-3, .padding-left-4, .padding-left-5, .padding-left-6, .padding-left-7, .padding-left-8 {
	padding-left: 20px;
}
.padding-right-1, .padding-right-2, .padding-right-3, .padding-right-4, .padding-right-5, .padding-right-6, .padding-right-7, .padding-right-8 {
	padding-right: 20px;
}
.lspace-sm, .lspace-1, .lspace-2, .lspace-3, .lspace-4, .lspace-5{
	letter-spacing:0px;
}
.iconbox-dxlarge.left, .iconbox-xlarge.left, .iconbox-large.left, .iconbox-xmedium.left, .iconbox-medium.left, .iconbox-smedium.left, .iconbox-small.left, .iconbox-tiny.left, .iconbox-xtiny.left {
    margin: 0px 0px 20px 0px;
}
.imgbox-dxlarge.left, .imgbox-dxlarge-2.left, .imgbox-xlarge.left, .imgbox-large.left, .imgbox-xmedium.left, .imgbox-medium.left, .imgbox-smedium.left, .imgbox-small.left, .imgbox-tiny.left, .imgbox-xtiny.left {
    float: left;
    margin: 0px 0 20px 0px;
}
.text-box-right {
    width:100%;
    padding: 0 0 0 0;
}
.text-box-left {
	width:100%;
    padding: 0 0 0 0;
}
.text-box.padding-1, .text-box.padding-2, .text-box.padding-3, .text-box.padding-4, .text-box.padding-5, .text-box.padding-6, .text-box.padding-7, .text-box.padding-8, .text-box.padding-9 {
	padding: 20px;
}
.text-box-right.more-padding-1, .text-box-right.more-padding-2, .text-box-right.more-padding-3, .text-box-right.more-padding-4, .text-box-right.less-padding-1, .text-box-right.less-padding-2, .text-box-right.less-padding-3 {
	padding: 0px 0px 0px 0px;
}
.btn.btn-mar-left-1, .btn.btn-mar-left-2, .btn.btn-mar-left-3, .btn.btn-mar-left-4, .btn.btn-mar-left-5 {
	margin-left:0px
}
.btn.btn-large {
    padding: 15px 30px;
}
.section-side-image .text-inner {
	padding: 40px 40px 40px 0;
}
.section-side-image .text-inner.two {
	padding: 0px 40px 0px 0;
}
.section-side-image-2 .background-imgholder-2.top-padd {
	padding-top: 40px;
}
.section-side-image-2 .text-inner.two {
	padding: 0px 40px 0px 0;
}
.sec-title-container.less-padding-1, .sec-title-container.less-padding-2, .sec-title-container.less-padding-3, .sec-title-container.less-padding-4,
.sec-title-container.less-padding-5, .sec-title-container.less-padding-6 {
    padding-bottom: 30px;
}
.sec-padding {
	padding: 30px 0 30px 0;
}
.sec-more-padding-1 {
	padding: 30px 0 30px 0;
}
.sec-bpadding-2, .sec-bpadding-3, .sec-bpadding-4 {
	padding-bottom: 30px;
}
.sec-tpadding-2, .sec-tpadding-3, .sec-tpadding-4 {
	padding-top: 30px;
}
.section-xmore-padding, .section-less-padding, .section-less-padding-2 {
	padding: 30px 0 30px 0;
}
.sec-moreless-padding {
	padding: 30px 0 30px 0;
}
.fo-copyright-holder .social-iconbox {
    left: 16%;
    top: -59px;
}

.fo-copyright-holder .social-iconbox {
    width: 71%;
}
.fo-copyright-holder .social-iconbox .side-shape1.right-icon {
    left: 213px;
}
.fo-sec-2 .social-iconbox {
    left: 9%;
    top: -57px;
}
.fo-sec-3 {
    padding: 409px 0 0 0;
}
.fo-copyright-holder .social-iconbox .side-shape1 {
    left: -13px;
    top: 1px;
}
.fo-copyright-holder .social-iconbox .side-shape1.right-icon {
    left: 205px;
	top: 1px;
}
.header-inner {
    height: 170px;
}
.header-inner.less-height {
    height: 170px;
}
.sec-title-container {
    padding-bottom: 35px;
}







}


.box1{
   
    background-color: rgb(248, 248, 248);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 1px solid rgb(221, 221, 221);
   
   }
   .logoo{
       text-align: center;
       padding-top: 50px;
   }
   
    .p2{
    font-size: 16px;
    }   
   
   .p1{
       text-align: center;
   }
   .lokacioni{
       display: flex;
       margin-top: 50px;
       padding-left: 20px;
       align-items: center;
       border-bottom: 2px solid rgb(213 213 213);
       padding-bottom: 15px;
    
   }
   .numri{
       display: flex;
       margin-top: 20px;
       padding-left: 20px;
       align-items: center;
       border-bottom: 2px solid rgb(213 213 213);
       padding-bottom: 15px;
       
   }
   .email{
       display: flex;
       padding-left: 20px;
       margin-top: 10px;
       align-items: center;
       border-bottom: 2px solid rgb(213 213 213);
       padding-bottom: 15px;
       
     
   }
   .glob{
       display: flex;
       margin-top: 20px;
       padding-left: 20px;
       align-items: center;
       border-bottom: 2px solid rgb(213 213 213);
       padding-bottom: 15px;
       
       
      
   }
   h2{
       text-align: center;
       font-size: 30px;
     
   
   }
   .paragrafi{
       text-align: center;
       margin-top: -25px;
       
   }
   .img2{
       margin-right: 10px;
      
   }
   .img3{
       margin-right: 10px;
      
   }
   .img4{
       margin-right: 10px;
       
   }
   .img5{
       margin-right: 10px;
     
   }
   .rrjetet{
       display: flex;
       justify-content:space-evenly;
       margin-top: 22px;
       
   }
   .footer{
       padding-left: 10px;
       padding-right: 10px;
   }
   .i{
       font-size: 12px!important;
       color: rgb(184, 184, 184);
       text-align: center;
       margin-top: 30px;
   }
   .i a{
       color:  rgb(79 194 112);
       text-decoration: none;
   }
   .butooni{
       border-radius: 100%;
       background: transparent;
     width: 50px;
     height: 50px;
     border: 2px solid  rgb(79 194 112) ;
       float: right;
       margin-top: -153px;
       margin-right: 20px;
   }

   .footer div{
    margin: 30px 0;
   }
   .arbes{
    padding: 20px;
    
   }


.data8{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
      border: 1px solid rgb(221, 221, 221);
      margin-top: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 10px;
      height: auto;
      border-radius: 9px;
      display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
   
        
         
}
.arbeni{
  padding: 20px;
  width: 350px;

}
.title{
  color: #D11E3E;
}

.boxx{
  color: #D11E3E;
  display: flex;
  align-items: center;
  border-bottom: #d1d1d1  solid 2px;
  line-height: 2;
  padding: 20px 0;

}
.boxx svg{
  margin-right: 15px;
  font-size: 25px;
}

h6{
  text-align: center;
  margin-top: -25px;
 
}

h1{
  text-align: center;
}

.fa-brands {

  text-align: center;
  font-size: 30px;
  margin-right: 35px;
  margin-top: 25px;
  

}
.social-media{
  text-align: center;
 display: flex;
 justify-content: space-evenly;
 margin-top: 20px;
  
  
}

.downBtn{
  text-align: center;
  font-size: large;
  
}


#btn-five{
  color: #fff;
  background: #D11E3E;
  float: right;
  margin-top: -90px;
  margin-right: -5px;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  border: 2px solid;
}

.h5{
  font-size: 10px;
  text-align: center;
  color: #808080;
  letter-spacing: 1px;
  font-weight: 600;
  margin-top: 20px;
}

.faaa{
  font-size: 20px;
}
.tname{
  color: #C21F26;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
}

.p-ps{
 color: #959595;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing:1px;
}
