.App {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  text-align: center;
  font-weight: 500;
}
body{
  overflow-x: hidden;
  margin: 0 !important;
}
.home{
  background: #52687b;
  height: 100vh;
}
.we{
  display: none;
}
.under{
height: 100vh;
width: 100%;
}
/* --------------------------------BIOHIT */

.data10 {
display: flex;
justify-content: space-around;
align-items: center;
padding: 10px;
text-align: center;

}
.data {
    width: 500px;
    margin-top: 10px;
    box-shadow: 1px 0px 15px 5px rgba(207, 207, 207, 0.5);
    -webkit-box-shadow: 1px 0px 15px 5px rgba(207, 207, 207, 0.5);
    -moz-box-shadow: 1px 0px 15px 5px rgba(207, 207, 207, 0.5);
    border-radius: 9px; 
}
    .main{
        width: 500px;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        clip-path: polygon(0 1%, 100% 0, 100% 67%, 0 99%);
        /* background-color: #384992; */
        background: url('https://scontent.fprn1-1.fna.fbcdn.net/v/t39.30808-6/270748286_872934536814820_6554952458538394587_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=e3f864&_nc_ohc=RSt1tbowjvEAX9Wu30E&_nc_ht=scontent.fprn1-1.fna&oh=00_AT-4F8f0IkogjOLFt6jN2FN751MkfyWP6TElcxCprbiJtQ&oe=62C0C784');
        color: white;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
       h1{
        margin-left: 20px;
        margin-bottom: 40px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
       }
       .bioteks{
           
           margin-left: 20px;
           font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
           font-weight: 500;
       }

    .picture{
        display: flex;
        justify-content: flex-end;
        background: white;
    }
        .bio{
            width: 200px;
            height: 200px;
            border-radius: 100%;
            margin-top: -160px;
            position: absolute;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
        }

    .content{
        width: 420px;
        padding: 15px;
        margin-left: 23px;
        text-align: center;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
    }
       
   .info{ 
       margin-top: -30px;
       margin-left: 30px;
       font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
       font-weight: 500;
   }
    .box-1{
        display: flex;
        align-items: center;
        margin-top: 14px;
        width: 450px;
        padding: 5px;
        height: 20px;
        border-radius: 10px;
    }
        
        button{
            border: none;
            background: none;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
        }
 .bioteks{
   color: rgb(56 73 146);
   font-size: 15px!important;
   margin-left: 5px!important;
   margin-top: 15px!important;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   font-weight: 500;
 }
 .bioteks a{
  color: rgb(56 73 146);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.nou{
  color: rgb(0 187 222);
  border: none!important;
  font-size: 22px!important;
}
.no{
  color: rgb(56 73 146);
  display: flex;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  margin-left: -10px;
  cursor: pointer;
}
 .box-1{
   box-shadow: none!important;
 }
@media only screen and (max-width: 500px) { 
.we{
  display: block;
  text-align: center;
  font-size: 30px;
  font-family: monospace;
  margin: 0!important;
  padding-top: 50px;
  color: white;
}
.under{
  height: auto!important;
  margin-top: 100px;
}
.data10{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-top: 10px;
    width: 360px;
    margin-left: 25px;
  }
    .data{
       
        width: 360px;
        padding: 0; 
        margin-top: 0;
    }
        .main{
            width: 360px;
            border-radius: 0;
            padding: 0;
        }
        .content{
            width: 330px;
            margin-left: 0;
            padding: 10px;
            margin-top: -20px;
        }
        .picture .bio{
            position: relative;
            top: -5px;
            background: white;
        }
        
        .info{
            margin-left: 10px;
        }
            .box{
                width: 350px;
                margin-top: 40px;
            }
        }
        .save{
          display: flex!important;
        }
        a{
          text-decoration: none;
        }

/* ------------------------------------------------ */
/* --------------------------------DENISGAFURI---------- */
.card {
margin: 10px;
height: auto;
width: 370px;
background-color: white;
-webkit-box-shadow: 0px 0px 20px 0px rgba(170, 170, 170, 0.75);
        box-shadow: 0px 0px 20px 0px rgba(170, 170, 170, 0.75);
}

.card ul {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-pack: distribute;
    justify-content: space-around;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
margin-top: 0;
margin-left: -40px;
}

.card ul li {
display: block;
text-align: center;
list-style-type: none;
}

.card ul li i {
color: white;
font-size: 25px;
}

.card ul li a {
font-family: 'Poppins', sans-serif;
color: white;
text-decoration: none;
font-weight: 600;
}

.card .img-div {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
margin-top: 40px;
}


.bg-two {
/* background-image: url("./images/dive.png");
background-size: 520px;
background-position: top; */
clip-path: polygon(0 0, 0 70%, 100% 50%, 100% 0);
width: 100%;
height: 380px;
background-color: black;
text-align: center;
}

.text {
text-align: right;
font-family: 'Poppins', sans-serif;
color: #151b3d;
padding: 0;
margin-top: -150px;
margin-bottom: 20px;
margin-right: 20px;
background: transparent;
}

.text h1 {
margin: 0px 0;
}

.loc{
font-weight: 500;
font-family: 'Poppins', sans-serif;
color: black;
font-size: 16px!important;
}
.text .title {
font-size: 40px;
color: #000;
font-weight: 500;
margin-top: 15px;
}

.text .ceo {
font-size: 20px;
color: #787575;
}

.text .slogan {
font-size: 14px;
font-style: italic;
font-family: 'Playfair Display', serif;
color: #202a5a;
font-weight: 700!important;
}

.personal {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
    -ms-flex-direction: column;
        flex-direction: column;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
width: 100%;
height: auto;
background-color: #ffffff;
}
.p{
margin-bottom: 0!important;
font-size: 17px!important;
}
.personal a {
font-family: 'Poppins', sans-serif;
color: #000;
text-decoration: none;
font-weight: 500;
text-align: left;
margin: 10px 0;
}

.personal .info {
text-align: left;
width: 100%;
}

.personal .info i {
background-color: #d6c05d;
padding: 25px;
color: black;
font-size: 25px!important;
margin: 5px 0;
}

.personal .info a {
padding: 0 10px;
font-size: 15px;
}

.save-btn {
background-color: #000;
color: #d6c05d;
padding: 20px;
border: 0;
font-size: 20px;
width: 100%;
margin-top: 20px;
cursor: pointer;
}
.des{
margin-left: 30px!important;
height: 120px;
}
.desi{
height: 90px!important;
display: flex;
align-items: center;
}
.logoname{
border-radius: 0!important;
width: 180px;
margin-top: -15px;
margin-left: -15px;
}
.slogi{
font-family:'Poppins', sans-serif;
font-style: italic;
color: #202a5a;
margin-left: -10px;
font-weight: 700;
}
.txt-center {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
}

.ml {
margin: 0 10px;
}
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;1,100&display=swap');
body{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  font-family: Poppins!important;
}

.card-t img{
  width: 100px;
  border-radius: 100%;
  height: 90px;
  margin-top:-50px;
  margin-bottom: 0;
}
.card-t{
  width: 310px;
  height: 780px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgba(170, 170, 170, 0.75);
  padding: 0px 20px;
  margin-top: -25px;
}

.header-t{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(117, 117, 117);
  color: white;
  width: 90%;
  height: 270px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
font-family: 'Poppins', sans-serif;
position: relative;
background-color: #a9caff;
}

.ceo-t{
  margin: 0;
  font-weight: 300;
}

.title-t{
  margin: 0;
}

.shape-t{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 7px;
  background: rgb(80, 80, 80);
  left: 7px;
  right: 7px;
  bottom: 7px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
}

.text-t{
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 30px;
  border-left: 5px solid #008cc9;
}

.span-t{
  font-weight: 600;
}

.work-t{
  margin-top: 0px;
  margin-bottom: 3px;
  font-size: 23px;
}
.slogan-t{
  margin: 0;
  font-size: 19px;
}

.div-t{
  margin-top: 20px;
  width: 200px;
  margin-left:-40px;
}

.info-t a{
  margin-left: 20px;
  text-decoration: none;
  color: #3b3b3b;
  font-size: 19px;
}
.info-t{
  display: flex;
  align-items: baseline;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.info-t i{
  color: #008cc9;
  font-size: 16px;
}


.shape-two-t{
  width: 70%;
  height: 300px;
  background-color: #008cc9;
}

.shape-three-t{
  width: 100%;
  height: auto;
  background: red;
}

.save-t{
  margin-top: 30px;
  font-size:18px;
  color: #ffffff;
  background-color:#008cc9;
  padding: 10px;
  width: 100%;
  border: 0;
  cursor: pointer;
  margin-bottom: 0;
}
.ml-t{
  margin-left: 10px;
}

.links{
  width: 100%;
  font-size: 25px;
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  align-items: center;
  margin-top: 30px;
}

.links i{
  padding: 10px;
  border-radius: 100%;
  background-color: #008cc9;
}
/* -------------------------------------------------------------------- */

.dollogo{
margin-top: 200px;
}

@media only screen and (max-width: 500px) { 

.dollogo{
  margin-top: 200px;
}
.dollogo img{
  width: 300px;
  margin-right: 30px;
}
}

.dive{
margin-top: -45px;
width: 200px;
}
.useri-halo{
  background-color: #1d9734 !important;
}
.jashar{
  color: #1d9734 ;
  font-weight: 500!important;
}
.ce-jashar{
  color: black!important;
  font-size: 14px!important;
}
.div-jash{
  border-bottom: 2px solid #737373!important;
 
}
.di{
  width: 320px!important;
}
.jashi{
  color:#1d9734 ;
}