@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&family=Ibarra+Real+Nova:wght@400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500&display=swap');
html{
  scroll-behavior: smooth;
}
.nfce{
  color: white;
  font-weight: 800;
}
.second-flag{
  margin-left: -30px;
}
#root{
  overflow-x: hidden;
}
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.tw-logo{
  height: 50px;
  margin-right: -30px;
}
.tw-bg{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tw-bg img{
  width: 100%;
  position: relative;
  margin-top: -30px;
  z-index: -999;
  height: 80vh;
}
a:hover{
  color: #0c1e50 !important;
}

/*------------ header -----------------*/

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 15vh;
  background: rgb(0, 0, 0);
}
.nav__brand {
  text-transform: uppercase;
}

.nav__brand > img{
  width: 200px;
}
.flag{
  width: 32px;
  border: 2px solid white;
  height: 30px;
  border-radius: 50%;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

.nav__link{
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-weight: 500;
  font-size: 14px!important;
}

@media screen and (max-width: 1168px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }
  .second-flag{
    margin-left: 0;
  }
  .nav__menu {
    display: none;
    position: absolute;
    top: 7vh;
    height: 50vh;
    width: 100vw;
    background: rgb(0, 0, 0);
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 30px;
    align-items: center;
    transform: translateX(100%);
  gap: 2rem;
    transition: 0.5s ease-in;
  }
}
/* Active Class */
.nav__active {
  display: inherit;
  transform: translateX(0%);
}

/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-9px, 8px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}

.tw-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 30px 0px;
  background-color: white;
}
#cfare{
  background-color: #000;
}
.tw-info-img img {
  width: 400px;
}
.tw-info-text {
  font-family: 'Lato', sans-serif;
  width: auto;
}
.two{
  background-color: #20409a;
}
.row{
  display: flex;
  flex-wrap: wrap;
}
.pak{
  margin-top: 20px;
}
.pak h4 a{
  padding: 30px;
}
.tw-info-text h1 {
  font-size: 60px;
  margin: 0;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.white{
  color: white;
}
.blue{
  color: #3367f5;
}
.tw-info-text p {
  line-height: 26px;
  font-size: 16px;
  
  margin-top: 20px;
}
.tw-info-text a {
  text-decoration: none;
  font-size: 14px;
  color: black;
  border-radius: 8px;
  background-color: #2b2b2b;
  padding: 15px 20px;
  letter-spacing: 0.5px;
  color: white;
}
.tw-info-text a svg {
  width: 13px;
  padding-left: 10px;
}
.tw-info-text .tw-link {
  margin-top: 40px;
}
.tw-uses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.tw-uses .tw-text {
  text-align: right;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}
.tw-box-text h1{
  font-size: 40px;
  padding: 0;
  margin: 0;
  color: white;
}
.tw-box-text p{
  font-family: 'Poppins', sans-serif;
  color: rgb(216, 216, 216);
  font-size: 16px;
  margin: 0;
}
.tw-box-img i{
  font-size: 70px;
}
.tw-middle-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  margin-top: 100px;
  text-align: center;
}
.tw-middle-txt h1{
  
  font-size: 40px;
}
.tw-box-one{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  background-color: #20409a;
  border: 5px solid white;
  padding: 40px 50px;
  color: white;
}
.tw-func{
  margin-top: 50px;
}
.tw-func-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tw-func-txt h1{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 60px;
  font-weight: 500;
}
.tw-func-txt p{
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}
.tw-func-imgs img
{
  width: 300px;
  margin: 20px;
}

.tw-func-img{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.border-img{
  border: 1px solid rgb(100, 100, 100);
}
.tw-func-services{
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-top: 50px;
  flex-wrap: wrap;
}
.tw-func-box{
  max-width:400px;
  text-align: center;
}
.tw-func-box h1{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 10px;
}
.tw-func-box p{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin: 0;
}
.tw-benefits-boxes{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
}
.tw-benefits-boxes img{
  width: 120px;
}
.tw-benefits-text{
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.tw-benefits-text h1{
  font-size: 40px;
}
.tw-benefits-boxes .tw-benefit-box{
  width: 300px;
  height: 200px;
  margin: 60px 30px;
  text-align: center;
}
.tw-benefit-box h1{
  font-size: 23px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  margin: 10px 0;
}
.tw-benefit-box p{
  font-family: 'Lato', sans-serif;
  color: #757575;
  font-size: 16px;
}
footer{
  margin-top: 80px;
  background: rgb(0, 0, 0);
  height: auto;
  padding-top: 40px;
  color: #fff;
}



.footer-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.footer-content h3{
  font-size: 2.1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 3rem;
}
.footer-content p{
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
  font-family: 'Poppins', sans-serif;
}

.socials{
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.example{
  width: 200px!important;
}

.socials li{
  margin: 0 10px;
  font-size: 20px;
}

.socials a{
  text-decoration: none;
  color: #fff;
}

.socials a i{
  transition: color .4s ease;
}


.footer-menu{
  margin-bottom: 20px;
}
.footer-menu ul{
  display: flex;
}
.footer-menu ul li{
padding-right: 10px;
display: block;
}

.footer-menu ul li a{
  color: #cfd2d6;
  border: 1.3px solid white;
  padding: 6px 15px;
  border-radius: 50px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
.footer-bottom{
  background: #000;
  padding: 20px;
  text-align: center;
  font-family: 'Lato', sans-serif;
}
.footer-bottom p{
  text-align: center;
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
}
.footer-bottom p a{
color:#ffffff;
font-size: 16px;
text-decoration: none;
}
.footer-bottom span{
  text-transform: uppercase;
  opacity: .4;
  font-weight: 200;
}

@media (max-width:759px) {
  .footer-menu ul{
    display: inline;
  }
  .footer-menu ul li{
    margin-bottom: 20px;
  }
  p{
      font-size: 15px!important;
  }
  .tw-middle-txt h1{
      font-size: 30px!important;
      margin-bottom: 0!important;
  }
  .work-w{
      font-size: 22px!important;
      color: #3c3b3b!important;
  }
  }
/*----- Card --------*/
.card-w {
  width: 320px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-top: 20px;
margin: 10px;
}

.card-w img {
  width: 250px;
  height: 90px;
  margin-top: -50px;
  margin-bottom: 0;
}
.header-w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #757575;
  color: white;
  width: 90%;
  height: 270px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
  font-family: 'Poppins', sans-serif;
  position: relative;
  background-color: #000;
}
.ceo-w {
  margin: 0;
  font-weight: 300;
}
.title-w {
  margin: 0;
  margin-top: 20px;
  font-weight: 500;
  font-family: "Poppins";
}
.shape-w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 7px;
  background: #fff;
  left: 7px;
  right: 7px;
  bottom: 7px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
}
.text-w {
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 30px;
  border-left: 5px solid #123949;
}
span {
  font-weight: 600;
}
.work-w {
  margin-top: 0px;
  margin-bottom: 3px;
  font-size: 22px!important;
  color: #3c3b3b!important;
}
.slogan-w {
  margin: 0;
  font-size: 17px;
}
.div-w {
  width: 330px;
  margin-left: 30px;
}
.info-w {
  display: flex;
  align-items: baseline;
  margin-top: 30px;
  font-family: 'Poppins', sans-serif;
}
.info-w a {
  margin-left: 15px;
  text-decoration: none;
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 500;
}
.info-w i {
  color: #363636;
  font-size: 30px;
}
.shape-two-w {
  width: 70%;
  height: 300px;
  background-color: #123949;
}
.shape-three-w {
  width: 100%;
  height: auto;
  background: red;
}
.save-w {
  margin-top: 50px;
  font-size: 20px;
  color: #ffffff;
  background-color: rgb(31, 30, 30);
  padding: 10px;
  width: 100%;
  border: 0;
  cursor: pointer;
  margin-bottom: 0;
}
.ml-w {
  margin-left: 10px;
}
.links-w {
  margin-top: 30px;
  width: 100%;
  font-size: 25px;
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  align-items: center;
}
.links-w i {
  padding: 10px;
  border-radius: 100%;
  background-color: #363636;
}


@media screen and (max-width:1540px){
  .tw-info-img img{
    width: 500px;
  }
}

  @media screen and (max-width:1146px){
    .tw-info-text{
      text-align: center;
    }
    .tw-info-img {
      margin-top: 60px;
    }
    .tw-uses{
      margin-top: 0px;
    }
    .tw-bg img{
      height: auto;
    }
  }

  
  @media screen and (max-width:854px){
    .tw-uses{
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .card{
      margin-top: 10px;
    }
  }
  
  
  @media screen and (max-width:705px){
    .br{
      display: none;
    }
    .tw-paragraph{
      margin: auto;
      width: 80%;
    }
    .tw-func-txt{
      text-align: center;
    }
    .tw-func-txt h1{
      font-size: 40px;
    }
    .tw-func-txt p{
      padding: 0 50px;
    }
  }

  
  @media screen and (max-width:665px){
    .tw-func-services{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .tw-func-box{
      margin-top: 20px;
    }
    .tw-info-img img{
      width: 300px;
    }
    .tw-info-text h1{
      font-size: 40px;
      margin-top: 20px;
    }
    .row{
      display: block;
    }
    .tw-text h1{
      font-size: 40px;
    }
    .tw-benefits-text h1{
      font-size: 40px;
    }
  }

    .imgslack1{
    width:400px;
  }


  
  @media screen and (max-width:540px){
    .tw-func-txt h1{
      font-size: 30px;
    }
    .tw-benefits-text h1{
      font-size: 30px;
    }
    
  .imgslack1{
    width:300px;
  }
  }

  
  @media screen and (max-width:423px){
    .tw-benefits-text h1{
      font-size: 30px;
      padding: 0 40px;
    }
    .tw-info-text h1{
      font-size: 30px;
    }
    .tw-text{
      font-size: 30px;
    }
    .tw-benefits-text{
      text-align: center;
    }
    footer{
      margin-top: 70px;
    }
  }

  /* -------------------------ADNAN--------------------- */
  .card-s {
    max-width: 370px;
    max-height:687px;
    background-color: rgb(255, 255, 255);
    margin: 15px;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.75);
    margin-top: 20px;
  }
  .la{
    width: 280px;
  }
  .rec-one-s {
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
  }
  .titu {
    font-size: 50px;
    margin-left: 60px;
    color: white;
    margin-top: 0 !important;
    padding-top: 10px;
    font-family: "PT Sans", sans-serif;
  }
  
  .left-rec-s {
    width: 350px;
    height: 300px;
    background-color: #152f79;
    clip-path: polygon(0 0, 14% 15%, 14% 100%, 0 85%);
  
    margin-top: -180px;
  }
  .left-rec-two-s {
    width: 350px;
    height: 300px;
    background-color: #152f79;
    clip-path: polygon(0 0, 14% 22%, 14% 100%, 0 85%);
  
    margin-top: -70px;
  }
  .left-rec-three-s {
    width: 350px;
    height: 180px;
    background-color: #152f79;
    clip-path: polygon(0 0, 14% 22%, 14% 100%, 0 85%);
  
    margin-top: -80px;
  }
  
  .rec-name-s {
    width: 290px;
    height: 80px;
    background-color: rgb(242 242 242);
    clip-path: polygon(0 0, 91% 0, 100% 100%, 0 100%);
    position: relative;
  
    top: -490px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 18px;
    text-align: center;
    font-family: "Poppins", sans-serif;
  }
  
  .rec-name-s h1 {
    padding-top: 5px;
    font-size: 22px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    margin-bottom: 0;
    color: #0c1e50;
    letter-spacing: 0.7px;
  }
  .working {
    color: orange;
    margin-left: 170px;
    font-family: monospace;
    font-weight: 700;
    font-size: 14px!important;
    margin-top: 40px;
  }
  .emriii {
    margin-left: -10px !important;
  }
  .rec-name-s p {
    padding-bottom: 10px;
    margin-top: 2px;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    color: #626262;
  }
  .exc-s {
    width: 30px;
    height: 85px;
    position: relative;
  
    top: -577px;
    left: 305px;
  }
  
  .triangle-s {
    width: 150px;
    height: 149px;
    background-color: rgb(242 242 242);
    position: relative;
    z-index: 999;
    top: -210px;
    left: 0px;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
  
  .bottom-s {
    width: 260px;
    height: 80px;
    background-color: #152f79;
    position: relative;
    z-index: 999;
    top: -290px;
    left: 90px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
  }
  .sss {
    text-align: center;
    color: white;
    margin-left: 60px;
  }
  .sss p{
    color: white;
  }
  .rus {
    margin-top: 10px;
  }
  .add {
    color: white;
    height: auto !important;
  }
  .links-s {
    width: 70px;
    background-color: rgb(242 242 242);
    position: relative;
  
    top: -700px;
    margin: 0 30px;
    padding: 10px 5px;
    border: 10px solid white;
  }
  
  .ul-s {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
  }
  
  .ul-s i {
    font-size: 20px;
    padding: 15px;
    border-radius: 100%;
    margin-left: 10px;
    background-color: white;
    color: #152f79;
  }
  
  .ul-s h1 {
    font-size: 15px;
    margin-left: 25px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0px !important;
  }
  
  .second-s {
    margin-top: 10px;
  }
  .blue{
    color: #3367f5;
  }

h1 .blue{
  color: #3367f5;
}

/* -------------------NAIM---------------------------------- */
.tw-uses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.tw-uses .tw-text {
  text-align: right;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}
.tw-box-text h1{
  font-size: 40px;
  padding: 0;
  margin: 0;
}
.tw-box-text p{
  font-family: 'Poppins', sans-serif;
  color: rgb(216, 216, 216);
  font-size: 16px;
  margin: 0;
}
.tw-box-img i{
  font-size: 70px;
}
.tw-middle-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  margin-top: 100px;
  text-align: center;
}
.tw-middle-txt h1{
  
  font-size: 40px;
}
.tw-box-one{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  background-color: #2b2b2b;
  padding: 40px 50px;
  color: white;
}
.tw-func{
  margin-top: 150px;
}
.tw-func-txt{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tw-func-txt h1{
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
}
.tw-func-txt p{
  margin-top: 3px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}
.tw-func-imgs img
{
  width: 300px;
  margin: 20px;
}

.tw-func-img{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.border-img{
  border: 1px solid rgb(100, 100, 100);
}
.tw-func-services{
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-top: 50px;
  flex-wrap: wrap;
}
.tw-func-box{
  max-width:400px;
  text-align: center;
}
.tw-func-box h1{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 10px;
}
.tw-func-box p{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin: 0;
}
.tw-benefits-boxes{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
}
.tw-benefits-boxes img{
  width: 120px;
}
.tw-benefits-text{
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.tw-benefits-text h1{
  font-size: 40px;
}
.tw-benefits-boxes .tw-benefit-box{
  width: 300px;
  height: 200px;
  margin: 60px 30px;
  text-align: center;
}
.tw-benefit-box h1{
  font-size: 23px;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  margin: 10px 0;
}
.tw-benefit-box p{
  font-family: 'Lato', sans-serif;
  color: #757575;
  font-size: 16px;
}
.card-w {
  width: 320px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 20px;
  border-radius: 20px;
  margin:10px;
}
.card-w img {
  width: 250px;
  height: 90px;
  margin-top: -50px;
  margin-bottom: 0;
}
.header-w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #757575;
  color: white;
  width: 90%;
  height: 270px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
  font-family: 'Poppins', sans-serif;
  position: relative;
  background-color: #000;
}
.ceo-w {
  margin: 0;
  font-weight: 300;
}
.title-w {
  margin: 0;
  margin-top: 20px;
  font-weight: 500;
}
.shape-w {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 7px;
  background: #fff;
  left: 7px;
  right: 7px;
  bottom: 7px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 75%, 53% 100%, 0 75%, 0% 75%);
}
.text-w {
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 30px;
  border-left: 5px solid #123949;
}
span {
  font-weight: 600;
}
.work-w {
  margin-top: 0px;
  margin-bottom: 3px;
  font-size: 20px!important;
  color: #3c3b3b!important;
}
.slogan-w {
  margin: 0;
  font-size: 17px;
}
.div-w {
  margin-top: 20px;
  width: 330px;
  margin-left: 30px;
}
.info-w {
  display: flex;
  align-items: baseline;
  margin-top: 40px;
  font-family: 'Poppins', sans-serif;
}
.info-w a {
  margin-left: 15px;
  text-decoration: none;
  color: #3b3b3b;
  font-size: 16px;
  font-weight: 500;
}
.info-w i {
  color: #363636;
  font-size: 30px;
}
.shape-two-w {
  width: 70%;
  height: 300px;
  background-color: #123949;
}
.shape-three-w {
  width: 100%;
  height: auto;
  background: red;
}
.save-w {
  margin-top: 50px;
  font-size: 20px;
  color: #ffffff;
  background-color: rgb(31, 30, 30);
  padding: 10px;
  width: 100%;
  border: 0;
  cursor: pointer;
  margin-bottom: 0;
}
.ml-w {
  margin-left: 10px;
}
.links-w {
  margin-top: 30px;
  width: 100%;
  font-size: 25px;
  display: flex;
  justify-content: space-evenly;
  color: #ffffff;
  align-items: center;
}
.links-w i {
  padding: 10px;
  border-radius: 100%;
  background-color: #123949;
}


@media screen and (max-width:1540px){
  .tw-info-img img{
    width: 500px;
  }
}

  @media screen and (max-width:1146px){
    .tw-info-text{
      text-align: center;
    }
    .tw-info-img {
      margin-top: 60px;
    }
    .tw-uses{
      margin-top: 0px;
    }
    .tw-bg img{
      height: auto;
    }
  }

  
  @media screen and (max-width:854px){
    .tw-uses{
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .card{
      margin-top: 20px;
    }
  }
  
  
  @media screen and (max-width:705px){
    .br{
      display: none;
    }
    .tw-paragraph{
      margin: auto;
      width: 80%;
    }
    .tw-func-txt{
      text-align: center;
    }
    .tw-func-txt h1{
      font-size: 40px;
    }
    .tw-func-txt p{
      padding: 0 50px;
    }
  }

  
  @media screen and (max-width:665px){
    .tw-func-services{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .tw-func-box{
      margin-top: 20px;
    }
    .tw-info-img img{
      width: 300px;
    }
    .tw-info-text h1{
      font-size: 40px;
    }
    .tw-text h1{
      font-size: 40px;
    }
    .tw-benefits-text h1{
      font-size: 40px;
    }
  }

    .imgslack1{
    width:400px;
  }


  
  @media screen and (max-width:540px){
    .tw-func-txt h1{
      font-size: 30px;
    }
    .tw-benefits-text h1{
      font-size: 30px;
    }
    
  .imgslack1{
    width:300px;
  }
  }

  
  @media screen and (max-width:423px){
    .tw-benefits-text h1{
      font-size: 30px;
      padding: 0 40px;
    }
    .tw-info-text h1{
      font-size: 30px;
    }
    .tw-text{
      font-size: 30px;
    }
    .tw-benefits-text{
      text-align: center;
    }
    footer{
      margin-top: 70px;
    }
  }
/* -------------------Fitim Radoniqi---------------------------------- */

.header-a{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 230px;
  font-family: 'Poppins', sans-serif;
  position: relative;
  text-align: center;
}
.alt img{
  width: 250px;
  height: 70px;
}
.fit{
  margin-top: 0!important;
}
.fita{
  font-size: 35px;
}
.top{
  color: #a12378;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.text-a{
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 10px;
}
.div-a{
  margin-top: -25px;
}
.as i{
  background: #939598;
  padding: 10px;
  color: white;
  font-size: 25px;
}
.as{
  margin-top: 10px;
  align-items: center;
}
.aw{
  background: #85246e;
  margin-top: 10px;
}
.as a {
  font-size: 16px;
}
.as img{
  width: 25px;
  height: 25px;
}
.fasi{
  background: #939598;
  padding-left: 10px;
  padding-top: 18px;
  padding-right: 10px;
}
.code img {
  height: 150px;
  width: 150px;
}
.qrc{
  margin-top: 80px;
  text-align: center;
}

a{
  cursor: pointer!important;
}
.div-a a:hover{
  color:#85246e!important
}



/* ---------------------------------------------------Armend Alidemaj----------------------- */
.alti img {
  height: 120px;
  width: 150px;
margin-top: 20px;
}
.div-b{
  margin-top: 0px;
}
.zahnlogo{
  margin-right: 20px;
}
.asi i{
  background: #e63312;
  padding: 10px;
  color: white;
  font-size: 25px;
}
.asi{
  align-items: center;
}
.asi a {
  font-size: 16px;
}
.awi{
  margin-top: 30px;
  background: black;
}

/* ----------------------besimmm-------------------------- */

.altb{
  margin-top: 30px;
}
.altb img{
  width: 300px;
  height: 80px;
}
.asb i{
  background: #a70000;
  padding: 10px;
  color: white;
  font-size: 25px;
}
.rrjete{
  display: flex;
}
.what .ss{
  width: 30px;
  height: 30px;
  margin-top: 30px;
}
.fg{
  margin-right: 50px;
}
.asb{
  align-items: center;
  margin-top: 15px;
}

.topi{
  color: #9b9b9b;
}
.awb{
  background-color: #a70000;
  margin-top: 10px;
}
.asb a{
  font-size: 16px;
}

.llogot{
  display: flex;
}

/* --------------------------------Astriti mobile shop */


.socia i{
  padding: 10px 15px;
    color: #fd0000;
    font-size: 25px;
    border-radius: 8px;
}
.how{
  margin-top: 30px;
  border-bottom: 2px solid #dfdfdf;
}
.astritii{
  background: url('./images/ren.png');
  background-size: cover;
  margin-top: 50px;
}
.nan{
  background: transparent;
  box-shadow: none!important;
}
.div-v{
  width: 80%;
}
.how a {
  font-size: 14px;
}
.alta img {
  margin-top: 20px;
}
.alta {
  text-align: center;
}
.how i{
  background-color: white;
  color: #fd0000;
  border-radius: 8px;
}
.mob{
  margin-top: 30px;
  background-color: #dfdfdf;
  color: #3b3b3b;
  width: 80%;
  border-radius: 20px;
}
.mobile{
  font-size: 20px!important;
  font-weight: 600;
}
/* --------------------------------example */
.personal .ex i{
  background-color: #000;
    padding: 15px;
    color: white;
    font-size: 25px!important;
    margin: 5px 0;
}
.alli{
  color: white;
}
.namest{
  font-size: 35px!important;
}
.ex a {
  font-weight: 500;
}
.ex .loc {
  font-weight: 500;
}
.ex{
  height: 95px;
}
.socialin{
  display: flex;
  justify-content: space-around;
}
.socialin img{
  height: 40px;
}
.juaj{
  margin-top: -20px;
}

/* -------------------------------dolphins */
.sociallink{
  display: flex;
    justify-content: space-around;
    padding-top: 10px;
    padding-left: 50px;
}
.sociallink i {
  background: #f2f2f2;
    padding: 15px;
    color: #152f79;
    border-radius: 100%;
}
.ul-s h1{
  line-height: 30px;
}
.socialet{
margin-top: -100px;
  display: flex;
  font-size: 20px;
}
.sasa{
  z-index: 999999;
  color: #d6c05d;
 font-size: 18px;
}

/* -----------------------dolphins */
.card-d{
  padding: 0!important;
  border-radius: 20px;
  width: 360px;
  border: 1px solid rgb(221, 221, 221);
  margin-top: 20px;
}
.card-d img{
margin-top: 0;
width: 100px;
height: auto!important;
border-radius: 50%;
}
.header-b{
  width: 100%;
  background-color: #193278;
  border-top-right-radius:20px;
  border-top-left-radius:20px;
}
.delfi{
  color: white;
  font-size: 14px;
}
.besimz{
  color: white;
  margin-top: 0;
  font-size: 25px;
}
.div-bes{
  width: 320px;
  border-bottom: 2px solid #e4e4e4;
  margin-bottom: 20px;
}
.dolpsoc{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.ico{
  width: 24px!important;
  border-radius: 0!important;
}
.socico{
  background: white;
    padding: 10px 10px 5px 10px;
    border-radius: 100%;
    margin-top: -7%;
    z-index: 999;
    box-shadow: 0px 0px 20px 0px rgb(170 170 170 / 75%);
}
.first{
  margin-top: 10px;
  margin-bottom: 10px;
}
.fund{
  border-bottom: 0;
  display: flex;
}
.inf{
  align-items: center;
  display: flex;
  line-height: 2;
}
.icons{
  width: 18px!important;
  margin-right: 5px;
  border-radius: 0!important;
}
.info-b p{
font-size: 16px;
font-weight: 500;
color: #193278;
}
.inf a {
  font-size: 15px!important;
}
.fund a {
  font-size: 14px!important;
}
.useri{
  background-color: #193278;
  width: 50px;
  border-radius: 100%;
  height: 50px;
  margin-top: -15%;
}
.userluc{
  background-color: #000;
  width: 50px;
  border-radius: 100%;
  height: 50px;
  margin-top: -15%;
}
.useri img {
  padding: 5px 12px;
  width: 25px!important;
}

/* ------------------------------denis */
.denislogo{
  width: 300px;
  border: none;
  margin-top: 40px;
}
.logo2{
  margin-top: 20px;
  margin-left: 20px;
}
.dnie{
  margin-top: -100px;
  width: 100%;
  margin-right: 30px;

}
.dnie .namest{
  color: white;
}

.deno{
  margin-right: 15px!important;
  margin-top: 0!important;
}
.dnie .ceo{
  color: #c3c3c3;
  margin-right: 22%!important;
}
.denisit{
  background-color: #031428;
}
.denisit a {
  color: white!important;
}
.denisit i {
  background-color: transparent!important;
  border: 1px solid white;
  border-radius: 3px;
}
.denisit button{
  border-top: 1px solid white;
}
.bg{
  clip-path: polygon(0 0, 0 70%, 100% 50%, 100% 0);
  -webkit-clip-path:polygon(0 0, 0 70%, 100% 50%, 100% 0);
    height: 300px;
    background-color: white;
    text-align: center;
}
/* .denisit .socialin img{
  height: 30px;
} */
.socialet img {
  height: 30px;
}
.socialet a {
  z-index: 999;
  margin-left: 20px;
}
.flexi{
  display: flex;
  justify-content: space-between;
}
.dgco{
  width: 150px;

}
.card-sa{
  border: none;
  width: 340px;
}

.alija{
  margin-top: 20px;
}
/* ---------------------elviongashi---------------------- */
.person{
  line-height: 5;
}
.iconperson{
  width:40px!important;
  margin-right: 15px;
  border-radius: 0!important;
}
.person a {
  font-size: 17px!important;
  font-weight: 500;
  color: black;
}
.funus{
  justify-content: space-between;
}
.useripers{
  margin-right: 20px;
  width: 60px;
  height: 60px;
}
.header-person{
  clip-path: circle(60.8% at 49% 6%);
}
.title-person{
  color: white;
  margin-top: -20px;
  font-size: 35px;
}
.useripers img{
 width: 35px!important;
}
.emajl{
  line-height: 2;
}
/* ---------------------------havaja-------------------------- */
.sochava{
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.havaja{
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e4e4e4;
  width: 320px!important;
  margin-left: -10px!important;
  margin-bottom: 30px;
  height: 65px;
}
.juaji{
  margin-top: -30px;
  border-radius: 100%;
}
.fff{
  margin-bottom: 0!important;
}
@-webkit-keyframes animate-figure3 {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  50% {
    -webkit-transform: translateY(-10px) translateX(-40px);
    transform: translateY(-10px) translateX(-40px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@keyframes animate-figure3 {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  50% {
    -webkit-transform: translateY(-10px) translateX(-40px);
    transform: translateY(-10px) translateX(-40px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@-webkit-keyframes animate-figure4 {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  50% {
    -webkit-transform: translateY(-10px) translateX(40px);
    transform: translateY(-10px) translateX(40px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@keyframes animate-figure4 {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  50% {
    -webkit-transform: translateY(-10px) translateX(40px);
    transform: translateY(-10px) translateX(40px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@-webkit-keyframes animate-figure1 {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  50% {
    -webkit-transform: translateY(-20px) translateX(10px);
    transform: translateY(-20px) translateX(10px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@keyframes animate-figure1 {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  50% {
    -webkit-transform: translateY(-20px) translateX(10px);
    transform: translateY(-20px) translateX(10px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@-webkit-keyframes animate-figure2 {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  50% {
    -webkit-transform: translateY(-20px) translateX(-10px);
    transform: translateY(-20px) translateX(-10px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
@keyframes animate-figure2 {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  50% {
    -webkit-transform: translateY(-20px) translateX(-10px);
    transform: translateY(-20px) translateX(-10px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
.dress1{
  animation: animate-figure3 infinite 5s;
  margin-top: 10px;
}
.dress2{
  animation: animate-figure4 infinite 5s;
  margin-top: 10px;
}
.dress3{
  animation: animate-figure1 infinite 5s;
  margin-top: -40px;
}

.dress4{
  animation: animate-figure2 infinite 5s;
  margin-top: -80px;
  margin-left: 200px;
}
.hava{
  clip-path: none;
  background-color: white;
}
.perH{
  text-align: center;
}

/* -------------------------------------------xheneta-------------- */
.header-girl{
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  clip-path: none;
  background:linear-gradient(266deg, rgb(9 46 78) 20%, rgb(13 210 255) 80%)!important;
}
.usegir{
  background:linear-gradient(266deg, rgb(9 46 78) 20%, rgb(13 210 255) 80%)!important;
}
.persone{
  line-height: 7;
}

/* ---------------------------butiku viona------------------ */
.butiku{
  border-radius: 100%;
  text-align: center;
}
.butiku p{
  color: white;
  font-weight: 700;
  font-size: 30px!important;
  padding: 10px;
}
.viona{
  border-radius: 100%;
}
/* ---------------------------liridoni constr--------------------------------- */


.b-liri{

  background-color: transparent;
  width: 100%;
  height: auto;
 clip-path: none;
}
.b-liri img {
  margin-top: 0;
  width: 230px;
  margin-right: 25px;
}
.div-liri{
  margin-left: 20px;
  width: 300px;
}
.liris{
  margin-bottom: 20px;
}
.useri-lir{
  background-color: #ab1f26;
}
.ceo-arch{
  color:#ab1f26;
  font-size: 18px!important;
  font-weight: 400;
}
.perArch{
  margin-top: 30px;
  margin-left: 20px;
  text-align: center;
}
.titles{
  font-size: 25px;
  font-family:Poppins;
  font-weight: 400;
}
.info-l p{
  color:#000;
}
.inf-l{
  line-height: 4;
}
.inf-l a {
  color: black;
}
.inf-l a:hover{
  color: #ab1f26!important;
}
.fundi{
  display: flex;
  justify-content: space-between;
}

.liri-soca{
  margin-top: 40px;
  padding-bottom: 10px;
}
.dolpsoci{
  display: flex;
  justify-content: center;
  width: 100%;
}
.dolpsoci a {
  box-shadow: none;
}
.inf-l .icons{
  width: 25px!important;
}
.icolit{
  width: 28px;
}

/* --------------------------------kuoni------------- */
.header-c{
  background:url('./images/kuoni.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  border-bottom: 2px solid #dce3e8;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.header-d{
  background:url('./images/luxora\ .png');
  background-size: cover;
  background-position: center;
  width: 100%;

  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.card-l{
  background-color:#d4e7d8 ;
}
.luxor{
  padding-top: 150px;
}
.candy{
  color: black;
}
.kuon{
  padding-top: 100px;
}
.perkuon{
  margin-top: 5px;
}
.copy{
  border-top: 1px solid #dce3e8;
}
.copyright{
  text-align: center;
  font-size: 10px!important;
}
.copyright a{
  font-size: 11px!important;
  color: #152f79;
}
.copyrighti{
  padding: 5px;
}
/* -------------------------------------------nila amini------------------------------ */

.inf-amin{
  line-height: 2.5;
}
.b-amin img{
  margin-right: 0;
  width: 200px;
  margin-top: -10px;
}
.useri-amin{
  background-color: #212121;
}
.nila{
  font-weight: 500;
  letter-spacing: 2px;
}
.amina{
  margin-top: 0;
}
.info-l{
  color: black;
  margin-bottom: 15px;

}
.div-lux{
  border-bottom: 2px solid #fff;
  line-height: 3;
}
.div-f{
  margin-top: 30px;
}
.info-l .inf img{
  width: 25px;
  margin-right: 5px;
  
}
.alo{
  
  margin-bottom: 5px;
}
.info-l a:hover{
  color: black!important;
}
.info-l a{
  color: black!important;
  margin-left: 5px;
}
