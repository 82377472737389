

.data8{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
      border: 1px solid rgb(221, 221, 221);
      margin-top: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 10px;
      height: auto;
      border-radius: 9px;
      display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
   
        
         
}
.arbeni{
  padding: 20px;
  width: 350px;

}
.title{
  color: #D11E3E;
}

.boxx{
  color: #D11E3E;
  display: flex;
  align-items: center;
  border-bottom: #d1d1d1  solid 2px;
  line-height: 2;
  padding: 20px 0;

}
.boxx svg{
  margin-right: 15px;
  font-size: 25px;
}

h6{
  text-align: center;
  margin-top: -25px;
 
}

h1{
  text-align: center;
}

.fa-brands {

  text-align: center;
  font-size: 30px;
  margin-right: 35px;
  margin-top: 25px;
  

}
.social-media{
  text-align: center;
 display: flex;
 justify-content: space-evenly;
 margin-top: 20px;
  
  
}

.downBtn{
  text-align: center;
  font-size: large;
  
}


#btn-five{
  color: #fff;
  background: #D11E3E;
  float: right;
  margin-top: -90px;
  margin-right: -5px;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  border: 2px solid;
}

.h5{
  font-size: 10px;
  text-align: center;
  color: #808080;
  letter-spacing: 1px;
  font-weight: 600;
  margin-top: 20px;
}

.faaa{
  font-size: 20px;
}
.tname{
  color: #C21F26;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
}

.p-ps{
 color: #959595;
  font-size: 14px !important;
  font-weight: 700;
  letter-spacing:1px;
}